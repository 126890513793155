import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { BetslipCurrency } from '../../../BetslipContainer/BetslipContent/elements';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import { getComponent } from 'Services/core__imports';
import {
  ReceiptGenraterName,
  ReceiptDiscription,
  ReceiptDiscriptionFandT,
  formatTimeStamp,
} from '../../../../../core__betslip-utils';
import {
  Ch6,
  C,
  CL,
  Ch5,
  MDC,
  Ch5E,
  Ch5F,
  MULD,
  BCLC,
  BCL,
  TCD,
  Ch6S,
  CDOWN,
  FbImg,
  I as I_LOCAL,
} from 'UI/apps/BetslipSAApp/ReceiptContainer/Container';
import { Ch5s } from 'CORE__UI/apps/BetslipSAApp/ReceiptContainer/core__container';
import { Translations } from '../../../../../core__betslip-SA-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getIcon } from 'Services/icons/core__icons';
import I_CORE from 'UI/globals/Icons';

const I = getComponent(I_LOCAL, I_CORE);

/**
Container handle middle section of Recept
takes bet info
@param bet {object}
@param oddsFormat {string}
@param iconTheme {string}
*/
export const Container = ({ bet, oddsFormat, iconTheme, showBetId }) => {
  const iconName = getIcon('PRICE_BOOST');
  const booster = (bet.betBoostId && true) || false;

  let { betPart } = bet;

  if (bet.betTypeName === GLOBAL_CONSTANTS.SINGLEBETSLIP) {
    betPart = betPart[0];
  }

  return (
    <React.Fragment>
      {bet.betTypeName === GLOBAL_CONSTANTS.SINGLEBETSLIP ? (
        <MDC>
          <C>
            <CL>
              <Ch5F>
                {bet?.bogEligible && (
                  <BCLC>
                    <BCL>{Translations.get('text.bog')}</BCL>
                  </BCLC>
                )}
                {bet.live && (
                  <BCLC>
                    <BCL>{Translations.get('text.live')}</BCL>
                  </BCLC>
                )}
                <TCD>{betPart['selection']}</TCD>
              </Ch5F>
              <Ch6 betResults>
                {ReceiptDiscription(
                  ([Constants.HORSES, Constants.DOGS].includes(
                    betPart['catRef']
                  ) &&
                    formatTimeStamp(betPart['scheduledStartOn'])) ||
                    betPart['name'],
                  betPart['selection'],
                  betPart['market']
                )}
              </Ch6>
              {showBetId && (
                <Ch6>
                  {Translations.get('text.bet.id')} {`- #` + bet['id']}
                </Ch6>
              )}
            </CL>
            {booster && bet?.originalPriceValue && (
              <CL>
                <Ch5F>
                  <TCD>
                    {oddsFormat === Constants.DECIMAL
                      ? parseDecimalPlaces(bet?.originalPriceValue)
                      : oddsFormat === Constants.AMERICAN
                      ? getOdds(bet?.originalPriceValue, true)
                      : oddsFormat === Constants.FRACTIONAL
                      ? bet?.originalFractionalPriceValue
                      : bet?.originalPriceValue}
                    &nbsp;&gt;&nbsp;
                  </TCD>
                </Ch5F>
              </CL>
            )}
            <CL padding={true}>
              <Ch5 boost={booster}>
                {bet.betBoostId && <I iconName={iconName} marginR={8} />}
                {betPart['bookType'] === Constants.SP
                  ? Constants.SP
                  : priceRecipt(betPart, oddsFormat, booster, bet)}
              </Ch5>
            </CL>
          </C>
          <CDOWN>
            <CL>
              <Ch6S>{Translations.get('text.wager')}</Ch6S>
              <Ch5>
                <React.Fragment>
                  <BetslipCurrency symbol={bet['currencyCode']} />
                  {bet.unitStake.toFixed(2)}
                  {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
                  {bet.creditWallet && (
                    <React.Fragment>
                      {
                        <FbImg
                          src={
                            (iconTheme === 'light' &&
                              Constants.STATICICONPATH +
                                'freebets/freebet-PPontario.svg') ||
                            Constants.STATICICONPATH + 'freebets/freebet.svg'
                          }
                          alt={'Free Bet'}
                        ></FbImg>
                      }
                      <Ch5s>{' ' + Translations.get('text.token')}</Ch5s>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Ch5>
            </CL>
            <CL>
              <Ch6>{Translations.get('text.potential.returns')}</Ch6>
              <Ch5E>
                {bet.maxPayout === 0 ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={bet['currencyCode']} />
                    {bet.maxPayout.toFixed(2)}
                  </React.Fragment>
                )}
              </Ch5E>
            </CL>
          </CDOWN>
        </MDC>
      ) : bet.betTypeName === GLOBAL_CONSTANTS.FORCAST ||
        bet.betTypeName === GLOBAL_CONSTANTS.TRICAST ? (
        <MDC>
          <C>
            <CL>
              <Ch5>
                {ReceiptGenraterName(bet['betModifier'], bet['betTypeName'])}
              </Ch5>
              <Ch6>{ReceiptDiscriptionFandT(betPart)}</Ch6>
              {showBetId && (
                <Ch6>
                  {Translations.get('text.bet.id')} {`- #` + bet['id']}
                </Ch6>
              )}
            </CL>
          </C>

          <CDOWN>
            <CL>
              <Ch6S>{Translations.get('text.wager')}</Ch6S>
              <Ch5>
                <React.Fragment>
                  <BetslipCurrency symbol={bet['currencyCode']} />
                  {bet.unitStake && bet.unitStake.toFixed(2)}
                  {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
                  {bet.betCount > 1 && <MULD>{'  X' + `${bet.betCount}`}</MULD>}
                  {bet.creditWallet && (
                    <React.Fragment>
                      {
                        <FbImg
                          src={
                            (iconTheme === 'light' &&
                              Constants.STATICICONPATH +
                                'freebets/freebet-PPontario.svg') ||
                            Constants.STATICICONPATH + 'freebets/freebet.svg'
                          }
                          alt={'Free Bet'}
                        ></FbImg>
                      }
                      <Ch5s>{' ' + Translations.get('text.token')}</Ch5s>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Ch5>
            </CL>
            <CL>
              <Ch6>{Translations.get('text.potential.returns')}</Ch6>
              <Ch5E>
                {bet.maxPayout === 0 ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={bet['currencyCode']} />
                    {bet.maxPayout.toFixed(2)}
                  </React.Fragment>
                )}
              </Ch5E>
            </CL>
          </CDOWN>
        </MDC>
      ) : (
        <MDC>
          {betPart.map((betP, key) => {
            return (
              <C key={key}>
                <CL>
                  <Ch5>{betP['selection']}</Ch5>
                  <Ch6 betResults>
                    {ReceiptDiscription(
                      ([Constants.HORSES, Constants.DOGS].includes(
                        betP['catRef']
                      ) &&
                        formatTimeStamp(betP['scheduledStartOn'])) ||
                        betP['name'],
                      betP['selection'],
                      betP['market']
                    )}
                  </Ch6>
                </CL>
                {bet.betTypeName !== GLOBAL_CONSTANTS.BET_BUILDER && (
                  <CL padding={true}>
                    <Ch5>
                      {betPart['bookType'] === Constants.SP
                        ? Constants.SP
                        : priceRecipt(betP, oddsFormat, booster, bet)}
                    </Ch5>
                  </CL>
                )}
              </C>
            );
          })}
          {bet.teaserPoint && (
            <C>
              <CL>
                <Ch5>
                  {'+' +
                    bet.teaserPoint +
                    ' ' +
                    Translations.get('text.points')}
                </Ch5>
              </CL>
            </C>
          )}
          {showBetId && (
            <C>
              <Ch6>
                {Translations.get('text.bet.id')} {`- #` + bet['id']}
              </Ch6>
            </C>
          )}
          <CDOWN>
            <CL>
              <Ch6S>{Translations.get('text.wager')}</Ch6S>
              <Ch5>
                <React.Fragment>
                  <BetslipCurrency symbol={bet['currencyCode']} />
                  {bet.unitStake && bet.unitStake.toFixed(2)}
                  {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
                  {bet.betCount > 1 && <MULD>{'  X' + `${bet.betCount}`}</MULD>}

                  {bet.creditWallet && (
                    <React.Fragment>
                      {
                        <FbImg
                          src={
                            (iconTheme === 'light' &&
                              Constants.STATICICONPATH +
                                'freebets/freebet-PPontario.svg') ||
                            Constants.STATICICONPATH + 'freebets/freebet.svg'
                          }
                          alt={'Free Bet'}
                        ></FbImg>
                      }
                      <Ch5s>{' ' + Translations.get('text.token')}</Ch5s>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Ch5>
            </CL>
            <CL>
              <Ch6>{Translations.get('text.pr')}</Ch6>
              <Ch5E>
                {bet.maxPayout === 0 ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={bet['currencyCode']} />
                    {bet.maxPayout.toFixed(2)}
                  </React.Fragment>
                )}
              </Ch5E>
            </CL>
          </CDOWN>
        </MDC>
      )}
    </React.Fragment>
  );
};
Container.propTypes = {
  bet: PropTypes.object,
  oddsFormat: PropTypes.string,
  iconTheme: PropTypes.string,
  showBetId: PropTypes.bool,
};
export const priceRecipt = (betPart, oddsFormat) => {
  if (oddsFormat === Constants.DECIMAL) {
    return parseDecimalPlaces(betPart['price']);
  } else if (oddsFormat === Constants.AMERICAN) {
    return getOdds(betPart['price'], true);
  } else {
    return betPart['fractional'];
  }
};
