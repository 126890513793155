import styled, { css } from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';

export const BSSMD = styled.div`
  width: 40%;
  border: 1px solid ${FONT.lightMute};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 4px;
  input {
    border-radius: 4px;
  }
`;

export const BI = styled.input`
  width: 80%;
  padding: 12px 8px;
  font-size: 12px;
  border: none;
`;

export const PPD = styled.p`
  padding-top: 16px;
  width: 40%;
`;

export const BetslipContentBetBoxPot = styled.span`
  color: ${GREYS.silver};
  font-size: 12px;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      color: ${FONT.dark};
    `};
`;
