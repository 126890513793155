import React from 'react';
import PropTypes from 'prop-types';
import {
  STAKECONTAINER as STAKECONTAINER_CORE,
  MAXBETUPDATE,
} from 'CORE__UI/apps/BetslipUSApp/BetslipContainer/core__stakeNotification';
import { STAKECONTAINER as STAKECONTAINER_LOCAL } from 'UI/apps/BetslipSAApp/BetslipContainer/StakeNotification';
import { Translations } from '../../../../../core__betslip-SA-app';
import { BetslipCurrency } from './core__currency';
import { Constants } from '../../../../../core__betslip-constants';
import { getComponent } from 'Services/core__imports';

const STAKECONTAINER = getComponent(STAKECONTAINER_LOCAL, STAKECONTAINER_CORE);

/** 
genrateDescription:
split by (|) and print the description

@param {bool} isActive when there is any error the isActive is true
@param {object} userStake user stake object.
*/

export const StakeNotification = ({
  isActive,
  userStake,
  currencySymbol,
  textMsg,
  setErrorObj,
  handleFocusOut,
  betMf,
  price,
  bet,
  hasStake,
}) => {
  const stake =
    userStake !== '' && Math.floor(parseFloat(userStake) * 100) / 100;
  return (
    stake !== '' &&
    userStake !== undefined && (
      <section>
        <STAKECONTAINER active={isActive} hasStake={hasStake} textMsg={textMsg}>
          {Translations.get(`text.${textMsg}.stake`)}
          &nbsp;
          <BetslipCurrency symbol={currencySymbol} />
          {stake.toFixed(2)}
          &nbsp;
          {textMsg && (textMsg === Constants.MAX || textMsg === Constants.MIN) && (
            <MAXBETUPDATE
              onClick={() => {
                setErrorObj({
                  isError: false,
                  message: '',
                });
                bet.betTypeRef !== Constants.BBB
                  ? handleFocusOut(
                      stake.toFixed(2).toString(),
                      betMf,
                      price,
                      bet.bbFlag,
                      false,
                      textMsg === Constants.MAX
                    )
                  : handleFocusOut(
                      stake.toFixed(2).toString(),
                      { betTypeRef: Constants.BBB, retn: price },
                      price,
                      false,
                      false,
                      textMsg === Constants.MAX
                    );
              }}
            >
              {Translations.get('text.update')}
            </MAXBETUPDATE>
          )}
        </STAKECONTAINER>
      </section>
    )
  );
};
StakeNotification.propTypes = {
  isActive: PropTypes.bool,
  userStake: PropTypes.number,
  currencySymbol: PropTypes.string,
  textMsg: PropTypes.string,
  setErrorObj: PropTypes.func,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  betMf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bet: PropTypes.object,
  handleFocusOut: PropTypes.func,
  hasStake: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
