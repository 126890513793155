import {
  BCD,
  BetslipContentBetBoxPot,
  RTNS,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__potReturn';
import styled from 'styled-components';

export const BCD_EX = styled(BCD)`
  justify-content: flex-end;
`;
export { BCD_EX as BCD, BetslipContentBetBoxPot, RTNS };
