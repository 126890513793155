import {
  SBSD,
  SBSU,
  SBSL,
  SBSLH6,
  SBSLH6B,
  SBSNPB,
  SBSPB,
  SpinnerObj,
  BSB,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__bottomsection';
import styled, { css } from 'styled-components';
import { BK_COLORS, FONT, BACKGROUND, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const SBSPB_EX = styled(SBSPB)`
  background: ${BK_COLORS.red};
  border-color: ${BK_COLORS.red};
  color: ${FONT.white.standard};
  border-radius: 99px;
  ${({ active }) =>
    !active &&
    css`
      background-color: ${BRAND.secondaryD};
      color: ${FONT.white.disabled};
      cursor: not-allowed;
    `};
`;

const SBSNPB_EX = styled(SBSNPB)`
  background: ${BK_COLORS.red};
  border-color: ${BK_COLORS.red};
  color: ${FONT.white.standard};
  border-radius: 99px;
`;

const SBSD_EX = styled(SBSD)`
  border-color: transparent;
  background-color: ${BACKGROUND.background};
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 8px 16px 12px 16px;
  }
`;

const SBSLH6_UI = styled(SBSLH6)`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
    margin: 2px 0px;
  }
`;

const SBSLH6B_EX = styled(SBSLH6B)`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
  }
`;

export {
  SBSD_EX as SBSD,
  SBSU,
  SBSL,
  SBSLH6_UI as SBSLH6,
  SBSLH6B_EX as SBSLH6B,
  SBSNPB_EX as SBSNPB,
  SBSPB_EX as SBSPB,
  SpinnerObj,
  BSB,
};
