import { StyledSelect } from 'CORE__UI/lines/dropdowns/OddsMovementsSelect/core__oddMovementsSelectStyles';
import styled from 'styled-components';
import { BK_COLORS, FONT, GREYS } from 'UI/globals/colours';

const StyledSelectJN = styled(StyledSelect)`
  width: auto;
  flex: 1;
  border: none;
`;

const styles1JN = {
  control: provided => ({
    ...provided,
    boxShadow: 'none',
    justifyContent: 'left',
    minHeight: 'auto',
    border: 'none',
    width: 'auto',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: BK_COLORS.aliceBlue,
    boxShadow: `0px 4px 10px ${BK_COLORS.aliceBlue10}`,
    color: GREYS.black,
    padding: 0,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  singleValue: provided => ({
    ...provided,
    color: FONT.black.mid,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px 12px',
    lineHeight: '20px',
    background: 'transparent',
    color: state.isSelected ? BK_COLORS.blue : FONT.black.standard,
    fontWeight: state.isSelected ? 'bold' : 400,

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${GREYS.white}`,
    },
    '&:hover': {
      color: BK_COLORS.blue,
      fontWeight: 'bold',
    },
    '&:active': {
      background: 'transparent',
    },
  }),
};

export { StyledSelectJN as StyledSelect, styles1JN as styles1 };
