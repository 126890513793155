import { emptyBetslipTranslationKeys } from 'CORE__UI/globals/EmptyBetslip/core__empty-betslip.translations';
export const translationString = [
  'text.betslip',
  'text.mybet',
  'text.aaom',
  'text.aaoc',
  'text.awopi',
  'text.clear',
  'text.single',
  'text.stake',
  'text.ocf',
  'text.to',
  'text.accept',
  'text.decline',
  'text.oba',
  'text.yhqf',
  'text.ttext',
  'text.read.more',
  'text.singles',
  'text.no.available',
  'text.review.bet',
  'text.tfs',
  'text.ts',
  'text.fee',
  'text.tax',
  'text.trn',
  'text.remove.suspended',
  'text.odd.change.review',
  'text.accept.odd.changes',
  'text.login.place.bet',
  'text.place.bet',
  'text.deposit.place.bet',
  'text.deposit',
  'text.gross.returns',
  'text.na',
  'text.tbc',
  'text.trg',
  'text.pr',
  'text.odd.change.review',
  'text.live',
  'text.bog',
  'text.use.bonus.balance',
  'text.unboost',
  'text.boost',
  'text.any',
  'text.st',
  'text.nd',
  'text.rd',
  'text.th',
  'text.ew.terms',
  'text.your.free.bets',
  'text.not.selected',
  'text.free.bet',
  'text.create.a.bet',
  'text.pt.rtn',
  'text.suspended',
  'text.odd.change.from',
  'text.retain.selections.on.the.betslip',
  'text.close.recipt',
  'text.remaining.balance',
  'text.betslip.receipt',
  'text.total.stake',
  'text.total.potential.returns',
  'text.token',
  'text.potential.returns',
  'text.place.bet',
  'text.login',
  'text.and',
  'text.total.return',
  'text.insufficient.credit',
  'text.tap.card',
  'text.clear.betslip',
  'text.multiple',
  'text.system',
  'text.acca',
  'text.confirm',
  'text.cancel',
  'text.close.receipt',
  'text.market.change',
  'text.server.error',
  'text.standard',
  'text.round.robin',
  'text.teaser',
  'text.wager',
  'text.spread',
  'text.spread.betting',
  'text.est.rtn',
  'text.leg.parlay',
  'text.leg.teaser',
  'text.pick',
  'text.point.teaser',
  'text.team',
  'text.points',
  'text.pick.round.robin',
  'text.multiple',
  'text.my.bets',
  'help',
  'bet.type.scorecast',
  'text.bet.id',
  'text.amount',
  'text.acca.bonus',
  'text.add',
  'text.bonus',
  'text.bonus.info',
  'text.bonus.amount',
  'text.bonus.percentage',
  'text.gross.returns',
  'text.potential.returns.net',
  'text.of.withholding.tax',
  'text.notification.horse.racetax',
  'text.potential.return',
  'text.multi',
  'text.control.off',
  'text.all.odds',
  'text.only.higher',
  'text.accept.odds.changes',
  'text.off',
  'text.chat.with.broker',
  'text.msg.broker.help',
  'text.min.stake',
  'text.max.stake',
  'text.review.stake',
  'text.update',
  'bet.type.single',
  'bet.type.double',
  'bet.type.treble',
  'bet.type.fourfold',
  'bet.type.fivefold',
  'bet.type.sixfold',
  'bet.type.sevenfold',
  'bet.type.eightfold',
  'bet.type.ninefold',
  'bet.type.10-fold',
  'bet.type.11-fold',
  'bet.type.12-fold',
  'bet.type.13-fold',
  'bet.type.14-fold',
  'bet.type.15-fold',
  'bet.type.16-fold',
  'bet.type.17-fold',
  'bet.type.18-fold',
  'bet.type.19-fold',
  'bet.type.20-fold',
  'bet.type.21-fold',
  'bet.type.22-fold',
  'bet.type.23-fold',
  'bet.type.24-fold',
  'bet.type.25-fold',
  'bet.type.26-fold',
  'bet.type.27-fold',
  'bet.type.28-fold',
  'bet.type.29-fold',
  'bet.type.30-fold',
  'bet.type.31-fold',
  'bet.type.32-fold',
  'bet.type.33-fold',
  'bet.type.34-fold',
  'bet.type.35-fold',
  'bet.type.36-fold',
  'bet.type.37-fold',
  'bet.type.38-fold',
  'bet.type.39-fold',
  'bet.type.40-fold',
  'bet.type.41-fold',
  'bet.type.42-fold',
  'bet.type.43-fold',
  'bet.type.44-fold',
  'bet.type.yankee',
  'bet.type.super.yankee',
  'bet.type.lucky.15',
  'bet.type.lucky.31',
  'bet.type.heinz',
  'bet.type.lucky.63',
  'bet.type.super.heinz',
  'bet.type.goliath',
  'bet.type.trixie',
  'bet.type.patent',
  'bet.type.straight.forecast',
  'bet.type.reverse.forecast',
  'bet.type.forecast',
  'bet.type.combination.forecast',
  'bet.type.combination.tricast',
  'bet.type.straight.tricast',
  'bet.type.tricast',
  'bet.type.2.leg.parlay',
  'bet.type.3.leg.parlay',
  'bet.type.4.leg.parlay',
  'bet.type.5.leg.parlay',
  'bet.type.6.leg.parlay',
  'bet.type.7.leg.parlay',
  'bet.type.8.leg.parlay',
  'bet.type.9.leg.parlay',
  'bet.type.10.leg.parlay',
  'bet.type.11.leg.parlay',
  'bet.type.12.leg.parlay',
  'bet.type.13.leg.parlay',
  'bet.type.14.leg.parlay',
  'bet.type.15.leg.parlay',
  'bet.type.16.leg.parlay',
  'bet.type.17.leg.parlay',
  'bet.type.18.leg.parlay',
  'bet.type.19.leg.parlay',
  'bet.type.20.leg.parlay',
  'text.pick.round.robin.count',
  'text.createbet',
  'text.insufficient.odds.boosts',
  'text.bonus.bet',
  ...emptyBetslipTranslationKeys,
];
