import React, { useRef, useState } from 'react';
import { ToolTip } from 'UI/tooltip/Tooltip';
import PropTypes from 'prop-types';
import { ToolTipHtml } from './elements';
import { getComponent } from 'Services/core__imports';
import {
  BRB,
  BRBT,
  BRBBC,
  BRS,
  I as I_LOCAL,
} from 'UI/apps/BetslipSAApp/BetslipContainer/RemainingBoosts';
import { getIcon } from 'Services/icons/core__icons';
import I_CORE from 'UI/globals/Icons';
import { Translations } from '../../../../core__betslip-SA-app';

const I = getComponent(I_LOCAL, I_CORE);

/**
 * BetslipRemainingBoosts
 @param {object} UserAuthData
 @param {string} boosterinfoextended
 */
export const BetslipRemainingBoosts = ({
  UserAuthData,
  boosterinfoextended,
}) => {
  const iconName = getIcon('PRICE_BOOST');
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef();
  const infoIcon = getIcon('INFO');
  return (
    <BRB onMouseOut={() => setIsOpen(false)}>
      <BRBT ref={boxRef} onMouseOver={() => setIsOpen(true)}>
        <span>{Translations.get('text.oba')}</span>
        <I iconName={infoIcon} marginR={8} info={true} active={true} />
      </BRBT>
      <BRBBC>
        <BRS>
          <I iconName={iconName} marginR={8} />
          {UserAuthData?.raw?.availableBetBoostTokens}
        </BRS>
      </BRBBC>

      <ToolTip
        boxRef={boxRef}
        properties={{ placement: 'bottom' }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <ToolTipHtml
          removeReadMore={true}
          setIsOpen={setIsOpen}
          tokens={UserAuthData?.raw?.availableBetBoostTokens}
          boosterinfoextended={boosterinfoextended}
        />
      </ToolTip>
    </BRB>
  );
};

BetslipRemainingBoosts.propTypes = {
  UserAuthData: PropTypes.object,
  boosterinfoextended: PropTypes.string,
};
