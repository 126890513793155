import {
  BL,
  BCS,
  BSSEL,
  OI,
  BLS,
  BH,
  BCSW,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__price';
import styled, { css } from 'styled-components';
import { BK_COLORS, FUNCTIONAL } from 'UI/globals/colours';

const BH_EX = styled(BH)`
  color: ${FUNCTIONAL.error};
`;

const BCS_EX = styled(BCS)`
  padding: 8px 6px;
  border-radius: 99px;
  display: inline-flex;
  align-items: center;

  i {
    margin: 0;
  }

  ${({ bbFlag }) =>
    bbFlag &&
    css`
      background: ${BK_COLORS.yellow};
    `};

  color: ${props => props.suspended && BK_COLORS.red};
`;

const BL_UI = styled(BL)`
  ${({ systemBet }) =>
    systemBet &&
    css`
      display: none;
    `};
`;

export { BL_UI as BL, BCS_EX as BCS, BSSEL, OI, BLS, BH_EX as BH, BCSW };
