import {
  SMM,
  BS,
  MDI,
  FDMD,
  CSD,
  blockSize,
  BSM,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__betslipContainer';
import styled, { css } from 'styled-components';

const SMM_EX = styled(SMM)`
  order: 4;
`;

const CSD_EX = styled(CSD)`
  order: 3;
  height: auto;
  ${({ showTop }) =>
    showTop &&
    css`
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      flex-direction: row-reverse;
      order: initial;
      flex: initial;
    `};
`;

export { SMM_EX as SMM, BS, MDI, FDMD, CSD_EX as CSD, blockSize, BSM };
