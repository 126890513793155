import React from 'react';
import PropTypes from 'prop-types';
import { BetslipTabs } from './elements';
import { Translations } from '../../../../core__betslip-SA-app';

/*
Tab component for show tabs after header
and accept 2 props 
handleActTab (function)
actTab (active element)
 */

/**
 * @param {multipleTranslation}: custom translation for `multiple` tab
 */

export const Tabs = props => {
  let tabs = [];

  if (props.isAccaBet) {
    tabs = [
      { actTab: 'single', display: Translations.get('text.single') },
      { actTab: 'acca', display: Translations.get('text.acca') },
      { actTab: 'multiple', display: Translations.get('text.multiple') },
    ];
  } else if (props.isCTBet) {
    tabs = [
      {
        actTab: 'single',
        display: Translations.get('text.standard'),
        shouldDisplay: true,
      },
      {
        actTab: 'multiple',
        display: Translations.get('text.round.robin'),
        shouldDisplay: props.activeRR,
      },
      {
        actTab: 'teaser',
        display: Translations.get('text.teaser'),
        shouldDisplay: props.activeTT,
      },
    ];
  } else {
    tabs = [
      {
        actTab: 'single',
        display: Translations.get('text.single'),
        shouldDisplay: props.shouldDisplay.includes('single'),
      },
      {
        actTab: 'multiple',
        display: Translations.get(props.multipleTranslation),
        shouldDisplay: props.shouldDisplay.includes('multiple'),
      },
      {
        actTab: 'system',
        display: Translations.get('text.system'),
        shouldDisplay: props.shouldDisplay.includes('system'),
      },
    ];
  }

  return <BetslipTabs tabs={tabs} props={props} />;
};

Tabs.propTypes = {
  actTab: PropTypes.string,
  handleActTab: PropTypes.func,
  region: PropTypes.string,
  betTemplate: PropTypes.array,
  isAccaBet: PropTypes.bool,
  isCTBet: PropTypes.bool,
  activeRR: PropTypes.bool,
  activeTT: PropTypes.bool,
  shouldDisplay: PropTypes.arrayOf(
    PropTypes.oneOf(['single', 'multiple', 'system'])
  ),
  multipleTranslation: PropTypes.string,
};

Tabs.defaultProps = {
  isAccaBet: false,
  isCTBet: false,
  activeRR: true,
  activeTT: true,
  multipleTranslation: 'text.multiple',
};
