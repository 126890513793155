import styled, { css } from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const TopContainer = styled.div`
  position: relative;
`;
export const SummaryTopSectionButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  margin: 0px auto;
  width: 100%;
  padding: 16px 0px;
  user-select: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${GREYS.doveL};

  ${({ hideClearBetslip }) =>
    hideClearBetslip &&
    css`
      display: none;
    `}
`;

export const M = styled.div`
  font-family: ${theme.fontFamily};
  background-color: ${GREYS.silverD};
  color: ${GREYS.white};
  font-size: 12px;
  padding: 8px 16px;
  line-height: 20px;
  user-select: none;

  ${({ iconShow }) =>
    iconShow &&
    css`
      padding: 0px;
      display: flex;
      line-height: 0px;
      justify-content: center;
      align-items: baseline;
      background-color: ${GREYS.black};
    `}

  ${({ ds }) =>
    ds &&
    css`
      background-color: ${GREYS.black};
      padding-left: 4px;
      line-height: 0px;
    `}
`;
