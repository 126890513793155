import {
  C,
  CL,
  Ch5,
  D,
  DBTN,
  DS,
  DChekBx,
} from 'CORE__UI/apps/BetslipSAApp/ReceiptContainer/core__bottomsection';
import styled, { css } from 'styled-components';
import { FONT, BK_COLORS, BRAND } from 'UI/globals/colours';

const DBTN_UI = styled(DBTN)`
  background-color: ${BRAND.secondary};
  border: 1px solid ${BRAND.secondary};
  color: ${FONT.light};
  border-radius: 20px;
`;

const D_EX = styled(D)`
  input {
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
    width: 16px;
    border-radius: 4px;
  }
  input:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border: 1px solid ${BRAND.tertiaryD};
    border-radius: 4px;
    background-color: ${BRAND.tertiary};
  }
  input:checked:before {
    background-color: ${BK_COLORS.blue};
  }
  input:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 8px;
  }
  label {
    margin-left: 8px;
  }
`;

const CL_UI = styled(CL)`
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `};
`;

export { C, CL_UI as CL, Ch5, D_EX as D, DBTN_UI as DBTN, DS, DChekBx };
