import {
  Radio,
  L,
  RC,
  SurroundingButton,
} from 'CORE__UI/forms/InputRadio/core__inputRadioStyles';
import styled from 'styled-components';
import { BK_COLORS } from 'UI/globals/colours';

const Radio_EX = styled(Radio)`
  + .r-l {
    &:before {
      /* Make sure the radio stays at the right ratio */
      min-width: 20px;
    }
  }
  &:checked {
    + .r-l {
      &:before {
        border-color: ${BK_COLORS.red};
        background-color: ${BK_COLORS.red};
        /* Make sure the radio stays at the right ratio */
        min-width: 20px;
      }
    }
  }
`;

export { Radio_EX as Radio, L, RC, SurroundingButton };
