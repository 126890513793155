import styled, { css } from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';

export const SMM = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: ${BREAKPOINT_L}) {
    ${({ openBS }) =>
      !openBS &&
      css`
        height: 0;
      `}
    bottom: 0;
    ${({ adjustBottomHeight }) =>
      adjustBottomHeight &&
      css`
        bottom: 40px;
      `}
  }
`;
export const BS = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${GREYS.white};
  height: 100%;
  @media (max-width: ${BREAKPOINT_L}) {
    display: flex;
    ${({ openBS }) =>
      !openBS &&
      css`
        height: auto;
        flex: 0;
        display: block;
      `}
  }
`;

export const FDMD = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
  ${({ showFB }) =>
    !showFB &&
    css`
      visibility: hidden;
    `}
`;
export const MDI = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CSD = styled.div`
  overflow-y: auto;
  flex: 1;
  @media (max-width: ${BREAKPOINT_L}) {
    ${({ openBS }) =>
      !openBS &&
      css`
        flex: 0;
        overflow: hidden;
        height: 0;
      `}
  }
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

export const BSM = styled.div`
  @media (max-width: ${BREAKPOINT_L}) {
    ${({ openBS }) =>
      !openBS &&
      css`
        height: 0px;
        position: relative;
        overflow: hidden;
      `}
  }
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
