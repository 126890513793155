import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BSSMD,
  BI,
  BetslipContentBetBoxPot,
} from 'UI/apps/BetslipSAApp/BetslipContainer/Stake';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import Pubsub from 'Services/pubsub/core__pubsub';
import { Translations } from '../../../../../core__betslip-SA-app';
import { Constants } from '../../../../../core__betslip-constants';

/**
 @param {string} userStakeBet,
 @param {func} setUserStake,
 @param {object} betMf,
 @param {object} price,
 @param {object} bet,
 */
export const BetslipUserStake = ({
  userStakeBet,
  setUserStake,
  betMf,
  price,
  bet,
  osKeyboard,
  isMaxBetError,
  handleFocusOut,
}) => {
  const id = 'stk_btn_' + bet.id;

  //if onscreen keyboard is attached listen to value change and function accordingly
  useEffect(() => {
    let keyboardInputListener;
    if (osKeyboard) {
      keyboardInputListener = Pubsub.listen(
        PubsubEvents.KEYBOARD_INPUT,
        data => {
          if (data.id === id) {
            setUserStake(data.text, betMf, price, bet.bbFlag);
          }
        }
      );
    }

    return () => {
      keyboardInputListener?.unsubscribe();
    };
  }, [betMf]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BSSMD
      accaBonus={bet?.betBonus}
      highlighterror={
        isMaxBetError || (bet?.isValid !== undefined && !bet.isValid)
      }
      hasStake={userStakeBet}
    >
      <BI
        id={id}
        inputMode="decimal"
        pattern="^\d+(?:\.\d{1,2})?$"
        placeholder={Translations.get('text.wager')}
        className="betbox-userStake"
        value={userStakeBet}
        onChange={e =>
          handleFocusOut(
            e.target.value,
            betMf,
            price,
            bet?.priceChangeNotification,
            bet.bbFlag
          )
        }
        onBlur={e =>
          bet.betTypeRef !== Constants.BBB
            ? handleFocusOut(
                e.target.value,
                betMf,
                price,
                bet?.priceChangeNotification,
                bet.bbFlag
              )
            : handleFocusOut(
                e.target.value,
                { betTypeRef: Constants.BBB, retn: price },
                price,
                false
              )
        }
        onFocus={e => {
          if (osKeyboard) {
            Pubsub.emit(PubsubEvents.KEYBOARD_TARGET, {
              id: e.target.id,
              type: 'NUMBER',
            });
          }
          handleFocusOut(e.target.value, betMf, price, bet.bbFlag);
        }}
      />
      {betMf && betMf.betCount && betMf.betCount > 1 && (
        <BetslipContentBetBoxPot>
          {` x` + betMf.betCount}
        </BetslipContentBetBoxPot>
      )}
    </BSSMD>
  );
};

BetslipUserStake.propTypes = {
  userStakeBet: PropTypes.string,
  setUserStake: PropTypes.func,
  bet: PropTypes.object,
  catRef: PropTypes.string,
  betMf: PropTypes.object,
  price: PropTypes.object,
  osKeyboard: PropTypes.bool,
  isMaxBetError: PropTypes.bool,
  handleFocusOut: PropTypes.func,
};
