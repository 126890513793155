import React, { useEffect, useState } from 'react';
import { Constants } from '../../../../core__betslip-constants';
import {
  getDataofModifier,
  getDataOfPrice,
  stakeLimit,
  getBetCountByTypeRef,
  marketRefCount,
} from '../../../../core__betslip-utils';
import { BetslipContentBetBox } from '../BetslipContent';
import { SocketMessage } from './elements';
import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import { PubSub, PubsubEvents } from 'Services/core__services';
import { maxStakeLimit } from '../../../../core__betslip-store';

/**
   BetBoxComponent:
   Return JSX for beting stuff
   @param {*} betStuff (accept whole object of bet , key )
   ** for future if we need then we Increases the params 
   */

// for CAB we are not geting limit values
const getLimits = limit =>
  (limit &&
    limit.reduce(
      (acc, { type, value }) => ({ ...acc, [type]: Number(value) }),
      {}
    )) ||
  {};
const getMinStake = bet => {
  const limits = getLimits(bet.limit || null);
  return limits.SINGLE_MIN_STAKE || limits.MULTIPLE_MIN_STAKE || 0;
};
export const BetBoxLayoutHorseDog = betStuff => {
  const {
    bet,
    handleDelete,
    actTab,
    callSetUserBetInLocalStorage,
    currencySymbol,
    balance,
    handleWsAcceptDecline,
    oddsFormat,
    isLogin,
    wallet,
    setShowFB,
    isAccaBet,
    setErrorObj,
    isError,
    betTemplateTeaser,
    countAccaBet,
    hideNextBetBonus,
    appConfig,
    errorObj,
    postApiCall,
    selectionId,
    betTemplate,
  } = betStuff;
  const [errorData, setErrorData] = useState(undefined);
  const [betErrorId, setBetErrorId] = useState([]);
  const [userStakeBet, setUserStakeBet] = useState(bet?.userStake || '');
  const [potReturn, setpotReturn] = useState(
    bet['returnsText'] ? bet['returnsText'] : 0.0
  );
  const [betModifier, setBetModifier] = useState(
    getDataofModifier(bet.betModifier, bet.betModifierFlag)
  );
  const [price, setPrice] = useState(getDataOfPrice(bet.price, bet.priceId));
  const [bbFlag, setBbFlag] = useState(bet.bbFlag);
  const [bonus, setBonus] = useState(
    bet['bonusFunds'] ? bet['bonusFunds'] : false
  );

  const [priceFractional, setPriceFractional] = useState(
    bet?.priceFractional || bet.betModifierFractional
  );
  const [userfreeBet, setUserFreeBet] = useState(
    (bet?.hasFreebet && bet) || {}
  );

  // for validatin bb bet
  const eventId = bet.eventId;

  /* 
here we remove Restriction category fobet.catRef &&
            actTab === Constants.SINGLE &&
            [Constants.HORSES, Constants.DOGS].includes(bet.catRef) &&
            bet.betModifier.length > 1 && (rm wallet
  */
  const newWallet = [];
  if (wallet && wallet.length > 0) {
    for (const fbW of wallet) {
      let catRefRes, subCatRefRes;
      const betTypeRef = fbW?.['betTypeRestriction'];
      const minSelection = fbW?.['minSelectionsRestriction'];
      const eventId = fbW?.['eventIdRestriction'];
      const marketTypeRef = fbW?.['marketTypeRestriction']?.includes('1x2')
        ? ['1x2']
        : fbW?.['marketTypeRestriction']
        ? fbW['marketTypeRestriction'].split('/')
        : undefined;
      const countByTypeRef = getBetCountByTypeRef(bet);
      const countByMarketRef = marketRefCount(betTemplate, marketTypeRef);
      const betNumCount =
        bet.betTypeRef === Constants.BBB ? bet.sentence.length : countByTypeRef;
      if (
        fbW?.['catRefRestriction'] &&
        fbW['catRefRestriction'].indexOf('/') > -1
      ) {
        const catSubcatArr = fbW['catRefRestriction'].split('/');
        catRefRes = catSubcatArr[0];
        subCatRefRes = catSubcatArr[1];
      }
      if (
        (!fbW['catRefRestriction'] ||
          fbW['catRefRestriction'] === bet.catRef) &&
        (!betTypeRef || betTypeRef === bet.betTypeRef) &&
        (!minSelection ||
          (bet.betTypeRef === Constants.BBB
            ? minSelection <= betNumCount
            : minSelection <= betNumCount && bet.betModifierBetCount === 1)) &&
        (!eventId || eventId === bet.eventId) &&
        (!marketTypeRef ||
          (bet.betTypeRef === 'SGL'
            ? marketTypeRef?.some(marketRef => marketRef === bet.marketTypeRef)
            : minSelection
            ? minSelection <= countByMarketRef
            : countByTypeRef <= countByMarketRef))
      ) {
        newWallet.push(fbW);
      } else if (
        catRefRes &&
        subCatRefRes &&
        catRefRes === bet.catRef &&
        subCatRefRes === bet.subcatRef &&
        (!betTypeRef || betTypeRef === bet.betTypeRef) &&
        (!minSelection ||
          (bet.betTypeRef === Constants.BBB
            ? minSelection <= betNumCount
            : minSelection <= betNumCount && bet.betModifierBetCount === 1)) &&
        (!eventId || eventId === bet.eventId) &&
        (!marketTypeRef ||
          (bet.betTypeRef === 'SGL'
            ? marketTypeRef?.some(marketRef => marketRef === bet.marketTypeRef)
            : minSelection
            ? minSelection <= countByMarketRef
            : countByTypeRef <= countByMarketRef))
      ) {
        newWallet.push(fbW);
      }
    }
  }

  useEffect(() => {
    setBetModifier(getDataofModifier(bet.betModifier, bet.betModifierFlag));
  }, [bet.betModifier]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
      Handle user Bet Stake
    */
  const handleBetStake = (
    bet,
    betMf,
    id,
    castPosition,
    callSetUserBetInLocalStorage
  ) => {
    var regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    try {
      if (bet && !bet.match(regex)) {
        if (bet < 0) {
          setUserStakeBet('');
          bet = 0;
        } else if (isNaN(bet / 1)) {
          bet = parseFloat(bet);
          if (isNaN(bet)) {
            bet = '';
          }
          setUserStakeBet(bet);
        } else if (!isNaN(bet)) {
          const rX = new RegExp(/\d+\.\d{2}/);
          bet = parseFloat(bet.toString().match(rX)[0]);
          setUserStakeBet(bet);
        }
      }
    } catch (error) {
      bet = userStakeBet;
    }

    let retAmt = Constants.DEFAULT_AMT;

    const tempBet = bet;

    if (betMf && betMf.retn !== Constants.N_A) {
      retAmt = tempBet * betMf.retn;
    } else if (bet && betMf.retn === Constants.N_A) {
      retAmt = Constants.N_A;
    }
    if (retAmt !== Constants.N_A)
      retAmt = parseDecimalPlacesPotReturn(parseFloat(retAmt));

    const Obj = new Array();
    const raw = {};
    raw['id'] = id;
    raw['userStake'] = bet;
    raw['totalStake'] = betMf.betCount * bet;
    raw['_returns'] = isNaN(retAmt) ? Constants.N_A : retAmt;
    raw['returnsText'] = isNaN(retAmt) ? Constants.N_A : retAmt;
    raw['_returnsFormatted'] = isNaN(retAmt) ? Constants.N_A : retAmt;
    raw['lastModified'] = JSON.parse(JSON.stringify(new Date()));
    raw['priceDecimal'] = betMf.retn;
    raw['priceFractional'] = betMf.fretn;
    raw['bbFlag'] = betMf.bbFlag;
    raw['betModifierBetCount'] = betMf.betCount
      ? betMf.betCount
      : betModifier.betCount;
    raw['betModifierType'] = betMf.type ? betMf.type : betModifier.type;
    raw['betModifierFlag'] = betMf.chkVal ? betMf.chkVal : false;
    raw['priceBookId'] = betMf.priceBookId && betMf.priceBookId;
    raw['priceId'] = betMf.priceId && betMf.priceId;
    raw['priceBookType'] = betMf.priceBookType && betMf.priceBookType;
    raw['castPosition'] = (castPosition && castPosition) || '';
    raw['eventId'] = eventId;
    raw['isValid'] = betMf.isValid;
    Obj[id] = raw;

    // if error component on view port
    if (isError) {
      setErrorObj({
        isError: false,
        message: '',
      });
    }

    callSetUserBetInLocalStorage(raw);
    setpotReturn(
      retAmt !== Constants.N_A ? parseDecimalPlacesPotReturn(retAmt) : retAmt
    );
    if (bet && appConfig && appConfig.hideerror) {
      setErrorObj({
        isError: false,
        message: '',
      });
    }
  };
  /*
      set User data in Stake 
    */
  const setUserStake = (
    userBet,
    betMf,
    price,
    isInavlid,
    priceChangeNotification
  ) => {
    if (typeof price == 'undefined') {
      if (bet.spFlag) {
        price = bet.price[1];
      } else {
        price = bet.price[0];
      }
    }
    setUserStakeBet(userBet);
    const valueRtn = {};
    if (bbFlag) {
      valueRtn['retn'] = betMf.boostedReturns;
    } else {
      if (
        betMf?.type === Constants.EACHWAY &&
        price.bookType !== Constants.SP
      ) {
        // for EW but with out SP
        valueRtn['retn'] = betMf['returns'];
      } else if (betMf?.multiple) {
        valueRtn['retn'] = betMf?.returns;
      } else {
        // for normal bets include SP
        valueRtn['retn'] = price?.decimal || betMf?.returns || Constants.N_A;
      }
    }
    if (bbFlag) {
      valueRtn['fretn'] = betMf.boostedFractionalReturns;
    } else {
      valueRtn['fretn'] = price?.fractional || '';
    }
    valueRtn['betCount'] = betMf?.betCount || 1;
    valueRtn['bbFlag'] = bbFlag;
    valueRtn['chkVal'] = (betMf?.type === Constants.EACHWAY && true) || false;
    valueRtn['type'] = betMf?.type;
    valueRtn['priceBookType'] = price.bookType;
    valueRtn['priceBookId'] = price.bookId;
    valueRtn['priceId'] = price.id;
    valueRtn['spFlag'] = price.bookType === Constants.SP && true;

    valueRtn['isValid'] = isInavlid;
    valueRtn['priceChangeNotification'] = priceChangeNotification;

    handleBetStake(
      userBet,
      valueRtn,
      bet.id,
      bet.castPosition,
      callSetUserBetInLocalStorage
    );
  };

  const validateMinStake = stake => {
    const min = Number(
      stakeLimit(
        bet?.limit,
        (bet.multiple && Constants.MULTIPLEMINSTAKE) || Constants.SINGLEMINSTAKE
      )
    );
    const isStakeBelowMin = stake < min;
    return isStakeBelowMin;
  };

  const validateStake = (userBet, uncheckMinStake) => {
    if (!userBet || uncheckMinStake)
      return { isValid: true, errorData: undefined };
    const stake = Number(userBet);
    if (validateMinStake(stake))
      return {
        isValid: false,
        errorData: { type: 'min', value: getMinStake(bet) },
      };
    return { isValid: true, errorData: undefined };
  };

  const handleFocusOut = async (
    userBet,
    betMf,
    price,
    priceChangeNotification,
    bbFlag,
    uncheckMinStake
  ) => {
    const getSelectionId =
      bet.betTypeRef === Constants.SGL
        ? selectionId[0]
        : selectionId.toString();

    const findSelection =
      getSelectionId &&
      betErrorId.find(selection => selection.selectionID === getSelectionId);

    const checkMinStake =
      uncheckMinStake || (findSelection && findSelection.maxStake === 0);

    const { isValid, errorData: newErrorData } = validateStake(
      userBet,
      checkMinStake
    );
    setErrorData(newErrorData);
    setUserStake(userBet, betMf, price, isValid, priceChangeNotification);

    if (isValid) {
      PubSub.emit(PubsubEvents.DISABLE_LOADER.BET, true);
      if (findSelection) {
        const isMaxStake =
          parseFloat(findSelection.maxStake) < parseFloat(userBet || 0);
        if (isMaxStake) {
          setErrorData({ type: Constants.MAX, value: findSelection.maxStake });
          setUserStake(userBet, betMf, price, false, priceChangeNotification);
        }
      } else {
        if (!findSelection && !appConfig?.disablemaxstakeendpoint) {
          const betTemplate = await maxStakeLimit(
            bet.betTypeRef,
            selectionId,
            bbFlag
          );
          if (betTemplate) {
            betErrorId.push(betTemplate);
            setBetErrorId(betErrorId);
          }
        }
      }
    }
  };

  /* handle EW Data */
  const handleEwData = (chkVal, betModifier, price) => {
    let modifierObj = {};
    if (chkVal === true) {
      // if freebet selected this it diselect and hide freebet button
      bet.hasFreebet = false;
      bet.freebetCredit = '';
      bet.freebetRestrictions = '';
      bet.freebetCreditFormatted = '';
      bet._returns = '';
      bet._returnsFormatted = '';
      bet.returnsText = '';
      setUserFreeBet(bet);
      setpotReturn('');
      modifierObj = betModifier[1];
    } else {
      modifierObj = betModifier[0];
    }
    modifierObj['chkVal'] = chkVal;
    setBetModifier(modifierObj);
    //    if user is already add some amount in input box
    if (userStakeBet) {
      setUserStake(userStakeBet, modifierObj, price);
    } else {
      //    if user add amount and check on checkbox
      bet.betModifierBetCount = modifierObj.betCount;
      bet.betModifierFlag = chkVal;
      bet.betModifierType = modifierObj.type;
      callSetUserBetInLocalStorage(bet);
    }
  };

  /* 
    handle DropDown Change ep to value
     */
  const handleDropDownChange = pid => {
    const pr = getDataOfPrice(bet.price, pid);
    setPrice(pr);
    //    if user is already add some amount in input box
    if (userStakeBet) {
      setUserStake(userStakeBet, betModifier, pr);
    } else if (bet?.hasFreebet) {
      let retAmt = '';
      if (pr.bookType === Constants.SP) {
        retAmt = Constants.N_A;
        bet['spFlag'] = true;
        bet['totalStake'] = 0;
        bet['_returns'] = retAmt;
        bet['returnsText'] = retAmt;
        bet['_returnsFormatted'] = retAmt;
        bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
        bet['bbFlag'] = false;
        bet['hasFreebet'] = true;

        setUserFreeBet(bet);
        setpotReturn(
          retAmt !== Constants.N_A
            ? parseDecimalPlacesPotReturn(retAmt)
            : retAmt
        );
        callSetUserBetInLocalStorage(bet);
      } else {
        if (!bet.priceDecimal && bet?.price) {
          let price = {};
          if (bet.spFlag) {
            price = bet.price[1];
          } else {
            price = bet.price[0];
          }
          bet.priceDecimal = price.decimal;
        }
        if (
          bet.betModifierBetCount &&
          (bet.priceDecimal || bet.betModifierReturns)
        ) {
          const tempBet = bet.freebetCredit ? bet.freebetCredit : 0;
          if (bet.multiple) {
            retAmt = tempBet * bet.betModifierReturns;
          } else {
            retAmt = tempBet * bet.priceDecimal;
          }
        }
        retAmt = retAmt && parseDecimalPlacesPotReturn(parseFloat(retAmt));
        bet['spFlag'] = false;
        bet['totalStake'] = 0;
        bet['_returns'] = retAmt;
        bet['returnsText'] = retAmt;
        bet['_returnsFormatted'] = retAmt;
        bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
        bet['bbFlag'] = false;
        bet['hasFreebet'] = true;

        setUserFreeBet(bet);
        setpotReturn(retAmt !== Constants.N_A ? retAmt : retAmt);
        callSetUserBetInLocalStorage(bet);
      }
    } else {
      //    if user did not add amount and directly change Dropdown
      bet.spFlag = pr.bookType === Constants.SP && true;
      bet.priceBookId = pr.bookId;
      bet.priceBookType = pr.bookType;
      bet.priceCurrent = pr.current;
      bet.priceDecimal = pr?.decimal || '';
      bet.priceFractional = pr?.fractional || '';
      bet.priceId = pr.id;
      callSetUserBetInLocalStorage(bet);
    }
  };

  /* 
    Handle Bet Booster data 
    */
  const handleBooster = values => {
    const checkErrorOnSelection = betErrorId?.find(
      selection => selection.selectionID === bet.selectionId
    );
    const isBetValid = checkErrorOnSelection && errorData;
    setPriceFractional(values.fretn);
    setBbFlag(values.bbFlag);
    handleBetStake(
      userStakeBet,
      {
        retn: values.retn,
        fretn: values.fretn,
        bbFlag: values.bbFlag,
        betCount: betModifier.betCount || 1,
        type: betModifier.type,
        priceBookType: price.bookType,
        priceBookId: price.bookId,
        priceId: price.id,
        chkVal: bet?.betModifierFlag || false,
        isValid: !isBetValid,
      },
      bet.selectionId ? bet.selectionId : bet.id,
      bet.castPosition,
      callSetUserBetInLocalStorage
    );
  };

  /* 
    Handle FreeBets
  */
  const handleFreeBet = values => {
    let retAmt = '';

    if (!bet.betModifierReturns && bet?.betModifier) {
      const betModifier = bet.betModifier[0];
      bet.betModifierReturns = betModifier.betCount;
    }

    if (!bet.priceDecimal) {
      let price = {};
      if (bet.spFlag) {
        price = (bet.multiple && bet.betModifier[1]) || bet.price[1];
      } else {
        price = (bet.multiple && bet.betModifier[0]) || bet.price[0];
      }
      bet.priceDecimal = price.decimal;
    }

    if (
      bet.betModifierBetCount &&
      (bet.priceDecimal || bet.betModifierReturns)
    ) {
      if (bet.spFlag) {
        retAmt = Constants.N_A;
      } else {
        const tempBet = values.credit ? values.credit : 0;
        const modifier = bet.betModifier[0];
        const modifierReturn =
          (modifier?.returns && modifier?.returns) || Constants.N_A;
        if (bet.multiple) {
          if (modifierReturn !== Constants.N_A) {
            retAmt = tempBet * bet.betModifierReturns;
            retAmt = (modifierReturn - betModifier.betCount) * tempBet;
          } else {
            retAmt = Constants.N_A;
          }
        } else {
          retAmt = (bet.priceDecimal - betModifier.betCount) * tempBet;
        }
      }
    }
    retAmt =
      retAmt !== Constants.N_A ? parseDecimalPlacesPotReturn(retAmt) : retAmt;
    bet['userStake'] = '';
    bet['totalStake'] = 0;
    bet['_returns'] = retAmt;
    bet['returnsText'] = retAmt;
    bet['_returnsFormatted'] = retAmt;
    bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
    bet['bbFlag'] = false;
    bet['hasFreebet'] = Object.keys(values).length > 0;
    bet['selectedFreebetId'] = values.id && values.id;
    bet['freebetCredit'] = values.credit && values.credit;
    bet['freebetCreditFormatted'] = values.credit && values.credit;
    bet['freebetRestrictions'] =
      values.catRefRestriction && values.catRefRestriction;
    setUserFreeBet(bet);
    setpotReturn(retAmt);
    callSetUserBetInLocalStorage(bet);
  };

  const changeNewPrice = bet => {
    bet.updateNewPrice = false;
    callSetUserBetInLocalStorage(bet);
  };

  const handleBonus = (data, bet) => {
    setBonus(data);
    bet['bonusFunds'] = data;
    callSetUserBetInLocalStorage(bet);
  };

  useEffect(() => {
    if (bet?.priceChangeNotification || bet?.updateNewPrice) {
      setPrice(getDataOfPrice(bet.price, bet.priceId));
      setPriceFractional(bet?.priceFractional);

      let retAmt = 0;
      let price = {};
      if (bet.spFlag) {
        price = bet.price[1];
      } else {
        price = bet.price[0];
      }
      bet.priceDecimal = price.decimal;
      if (
        bet.betModifierBetCount &&
        (bet.priceDecimal || bet.betModifierReturns)
      ) {
        const tempBet = bet.userStake ? bet.userStake : 0;
        if (bet.multiple) {
          retAmt = tempBet * bet.betModifierReturns;
        } else {
          retAmt = tempBet * bet.priceDecimal;
        }
      }
      setpotReturn(retAmt);

      if (bet?.updateNewPrice) {
        changeNewPrice(bet);
      }
    } else {
      setUserFreeBet((bet?.hasFreebet && bet) || {});
      setBbFlag(bet.bbFlag);
    }
  }, [userStakeBet, bet, price]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setpotReturn(reCalcPotRtn(bet));
    setUserStakeBet(bet?.userStake);
  }, [bet]);
  return (
    <React.Fragment key={bet.id}>
      {bet?.priceChangeNotification && bet.active && (
        <SocketMessage
          bet={bet}
          handleWsAcceptDecline={handleWsAcceptDecline}
          oddsFormat={oddsFormat}
        />
      )}
      <BetslipContentBetBox
        bet={bet}
        handleDelete={handleDelete}
        actTab={actTab}
        setUserStake={setUserStake}
        userStakeBet={userStakeBet}
        potReturn={
          potReturn === Constants.N_A ? potReturn : parseFloat(potReturn)
        }
        currencySymbol={currencySymbol}
        betMf={betModifier}
        handleEwData={handleEwData}
        handleDropDownChange={handleDropDownChange}
        bonus={bonus}
        balance={balance}
        bbFlag={bbFlag}
        price={price}
        oddsFormat={oddsFormat}
        handleBooster={handleBooster}
        isLogin={isLogin}
        priceFractional={priceFractional}
        handleFreeBet={handleFreeBet}
        userfreeBet={userfreeBet}
        newWallet={newWallet}
        setShowFB={setShowFB}
        isAccaBet={isAccaBet ? isAccaBet : false}
        betTemplateTeaser={betTemplateTeaser}
        countAccaBet={countAccaBet}
        handleBonus={handleBonus}
        hideNextBetBonus={hideNextBetBonus}
        appConfig={appConfig}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        handleFocusOut={handleFocusOut}
        postApiCall={postApiCall}
        errorData={errorData}
      />
    </React.Fragment>
  );
};

const reCalcPotRtn = bet => {
  let retAmt = 0;
  const tempBet = bet.userStake ? bet.userStake : 0;
  // for EW
  let { betModifier, price } = bet;
  betModifier =
    (bet.betModifierType === Constants.EACHWAY && betModifier[1]) ||
    betModifier[0];
  // for SP
  price = (bet.spFlag && price?.[1]) || price?.[0];
  if (bet.category === Constants.CAST) {
    // for multiple cast type bet
    retAmt = Constants.N_A;
  } else if (!bet.betModifierFlag) {
    // for ew false type bet
    bet.priceDecimal = (price?.decimal && price.decimal) || '';

    if (bet.spFlag || bet.priceBookType === Constants.SP) {
      retAmt = Constants.N_A;
    } else {
      if (bet.bbFlag) {
        retAmt = tempBet * betModifier.boostedReturns;
      } else if (bet.hasFreebet) {
        if (bet.multiple) {
          retAmt = parseFloat(bet.freebetCredit) * betModifier.returns;
        } else {
          retAmt = parseFloat(bet.freebetCredit) * bet.priceDecimal;
        }
      } else {
        if (bet.multiple) {
          retAmt = parseFloat(tempBet) * betModifier.returns;
        } else {
          retAmt = tempBet * bet.priceDecimal;
        }
      }
    }
  } else if (bet.betModifierFlag) {
    // for single ew true type bet
    if (bet.spFlag || bet.priceBookType === Constants.SP) {
      retAmt = Constants.N_A;
    } else {
      if (bet.bbFlag) {
        retAmt = parseFloat(tempBet) * betModifier.boostedReturns;
      } else {
        retAmt = parseFloat(tempBet) * betModifier.returns;
      }
    }
  }

  return retAmt;
};
