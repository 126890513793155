import {
  MSSD,
  M2,
  MSB,
  M,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__error';
import styled, { css } from 'styled-components';
import { FONT } from 'UI/globals/colours';

const MSSD_UI = styled(MSSD)`
  ${({ isMaxBetExceed }) =>
    isMaxBetExceed &&
    css`
      background-color: ${FONT.primary};
      justify-content: center;
    `}
`;

const M_UI = styled(M)`
  ${({ isMaxBetExceed }) =>
    isMaxBetExceed &&
    css`
      background-color: inherit;
      color: ${FONT.light};
    `}
`;

const M2_UI = styled(M2)`
  ${({ isMaxBetExceed }) =>
    isMaxBetExceed &&
    css`
      background-color: inherit;
      color: ${FONT.light};
    `}
`;

const MSB_UI = styled(MSB)`
  ${({ isMaxBetExceed }) =>
    isMaxBetExceed &&
    css`
      color: ${FONT.light};
    `}
`;
export { MSSD_UI as MSSD, M2_UI as M2, MSB_UI as MSB, M_UI as M };
