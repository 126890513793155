import React from 'react';
import PropTypes from 'prop-types';
import { B } from 'UI/apps/BetslipSAApp/ReceiptContainer/Header';
import {
  H as H_CORE,
  AB,
  ABC,
  BCS as BCS_CORE,
  PC as PC_CORE,
} from 'CORE__UI/apps/BetslipSAApp/ReceiptContainer/core__header';
import { Constants } from '../../../../../core__betslip-constants';
import { Translations } from '../../../../../core__betslip-SA-app';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import project from '../../../../../../../../project';
import {
  BCS as BCS_LOCAL,
  PC as PC_LOCAL,
  H as H_LOCAL,
} from 'UI/apps/BetslipUSApp/ReceiptContainer/Header';
import { getComponent } from 'Services/core__imports';

const BCS = getComponent(BCS_LOCAL, BCS_CORE);
const PC = getComponent(PC_LOCAL, PC_CORE);
const H = getComponent(H_LOCAL, H_CORE);

/**
 this for genrate header tab section
@param {string} hedingName 
*/
export const ReceiptHeader = ({
  hedingName,
  betBonusPercent,
  oddsFormat,
  bet,
  appConfig,
}) => {
  const betPart = hedingName === 'Single' ? bet[0] : bet;
  return (
    <>
      <H>
        <B>{hedingName}</B>
        {!appConfig.hidereceiptstake && bet.betCount === 1 && (
          <PC>
            <BCS>
              {(oddsFormat === Constants.DECIMAL && (
                <BCS>{parseDecimalPlaces(betPart?.indicativeDecimalPrice)}</BCS>
              )) ||
                (oddsFormat === Constants.AMERICAN && (
                  <BCS>{getOdds(betPart?.indicativeDecimalPrice, true)}</BCS>
                )) || <BCS>{betPart?.indicativeFractionalPrice}</BCS>}
            </BCS>
          </PC>
        )}
        {project.JpTimeFormat && betBonusPercent && (
          <ABC>
            <AB>
              +{betBonusPercent}% {Translations.get('text.acca.bonus')}
            </AB>
          </ABC>
        )}
      </H>
    </>
  );
};
ReceiptHeader.propTypes = {
  bet: PropTypes.object,
  oddsFormat: PropTypes.string,
  hedingName: PropTypes.string,
  betBonusPercent: PropTypes.number,
  appConfig: PropTypes.object,
};
