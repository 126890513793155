import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import {
  StyledSelect,
  styles1,
} from 'UI/lines/dropdowns/OddMovementsSelectStyle';

/**
 *  @param {array} options dropdown options
 *  @param {string} defaultValue default value
 *  @param {string}  defaultLabel default label
 *  @param {func} onChange handle CHange
 *  @param {boolean} menuIsOpen boolean to open and close menu
 *  @param {string} menuPlacement alingment of menu
 */

const OddMovementsDropDown = ({ ...props }) => {
  const {
    options,
    defaultValue,
    defaultLabel,
    onChange,
    menuIsOpen,
    menuPlacement,
    theme,
    changeDDIndicator,
  } = props;

  return (
    <StyledSelect
      theme={theme}
      isSearchable={false}
      styles={styles1}
      defaultValue={{ value: defaultValue, label: defaultLabel }}
      options={options}
      onChange={onChange && onChange}
      menuPlacement={(menuPlacement && menuPlacement) || `top`}
      menuIsOpen={menuIsOpen}
      changeDDIndicator={changeDDIndicator}
    />
  );
};
OddMovementsDropDown.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  menuPlacement: PropTypes.string,
  theme: PropTypes.object,
  changeDDIndicator: PropTypes.bool,
};

export default withTheme(OddMovementsDropDown);
