import { BL, BH } from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__name';
import styled, { css } from 'styled-components';
import { GREYS, BK_COLORS } from 'UI/globals/colours';

const BH_EX = styled(BH)`
  ${({ active }) =>
    !active &&
    css`
      color: ${GREYS.black};
    `};
  ${({ bonus }) =>
    bonus &&
    css`
      margin-left: 4px;
      background: ${BK_COLORS.yellow};
      font-size: 12px;
      padding: 4px;
      text-align: center;
      border-radius: 4px;
      position: relative;
      top: -3px;
    `};
`;

const BL_UI = styled(BL)`
  display: flex;
`;
export { BL_UI as BL, BH_EX as BH };
