import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

export const SB = styled('span')`
  padding-bottom: 8px;
`;
export const BA = styled('div')`
  width: 28px;
  height: 12px;
  position: relative;
  overflow: hidden;
  margin-left: 50%;
  &:after {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    transform: rotate(45deg);
    top: 4px;
    left: 0px;
    border: 1px solid ${GREYS.dove};
    background-color: ${GREYS.white};
  }
`;

export const BIT = styled('div')`
  background-color: ${GREYS.white};
  color: ${FONT.dark};
  margin: 0 8px;
  padding: 16px;
  border-top: none;
  font-weight: 100;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${GREYS.dove};
`;
