import {
  BTU,
  BTL,
  BTA,
  BTSS,
  BTCB,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__tab';
import styled, { css } from 'styled-components';
import { BK_COLORS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const BTU_EX = styled(BTU)`
  padding: 14px 0;
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
  order: 1;
  ${({ topSection }) =>
    topSection &&
    css`
      flex: 0.95;
    `};
  @media (max-width: ${BREAKPOINT_S}) {
    height: 40px;
  }
`;
const BTL_EX = styled(BTL)`
  color: ${({ active }) => (active ? FONT.black.standard : FONT.black.mid)};
`;

const BTA_EX = styled(BTA)`
  line-height: 20px;
`;

export { BTU_EX as BTU, BTL_EX as BTL, BTA_EX as BTA, BTSS, BTCB };
