import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { BetslipCurrency } from '../../../BetslipContainer/BetslipContent/elements';
import { genTotalStakesAndTotalPotReturn } from '../../../../../core__betslip-utils';
import {
  AS,
  AD,
  Ah3,
  Apdate,
  C3,
  C31,
  CL,
  Ch6,
  Ch5,
  ADIV,
} from 'UI/apps/BetslipSAApp/ReceiptContainer/TopSection';
import { Translations } from '../../../../../core__betslip-SA-app';
import project from '../../../../../../../../project';

/**
 * Receipt Top section
 * @param {object} betSlipRecipt
 * @param {func} handleReceptCloseButton
 *  */
export const ReceiptTopSection = ({
  betSlipRecipt,
  handleReceptCloseButton,
  betBonusPercent,
  betBonusAmount,
}) => {
  const [tStake, setTStake] = useState(0);
  const [date, setDate] = useState(0);
  const [pRtn, setpRtn] = useState('');

  const bet = betSlipRecipt?.bet?.length > 0 && betSlipRecipt.bet[0];

  useEffect(() => {
    const result = genTotalStakesAndTotalPotReturn(betSlipRecipt['bet']);
    setTStake(result.totalStake);
    setDate(result.placeOn);
    setpRtn(result.potStake);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ADIV>
      <AD>
        <Ah3>{Translations.get('text.betslip.receipt')}</Ah3>
        <AS onClick={handleReceptCloseButton}>&times;</AS>
      </AD>
      <C3>
        <li>
          <Apdate>{date}</Apdate>
        </li>
      </C3>
      <C31>
        <CL>
          <Ch6>{Translations.get('text.total.stake')}</Ch6>
        </CL>
        <CL>
          <Ch5>
            <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
            {tStake && parseFloat(tStake).toFixed(2)}
            {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
          </Ch5>
        </CL>
      </C31>
      {project.JpTimeFormat && betBonusPercent && betBonusAmount && (
        <C31>
          <CL>
            <Ch6>
              {betBonusPercent}% {Translations.get('text.acca.bonus')}{' '}
              {Translations.get('text.amount')}
            </Ch6>
          </CL>
          <CL>
            <Ch5>
              <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
              {betBonusAmount}
            </Ch5>
          </CL>
        </C31>
      )}
      <C31>
        <CL>
          <Ch6>{Translations.get('text.total.potential.returns')}</Ch6>
        </CL>
        <CL>
          <Ch5>
            {pRtn === Constants.N_A ? (
              pRtn
            ) : (
              <React.Fragment>
                <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
                {parseFloat(pRtn).toFixed(2)}
              </React.Fragment>
            )}
          </Ch5>
        </CL>
      </C31>
    </ADIV>
  );
};
ReceiptTopSection.propTypes = {
  betSlipRecipt: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  betBonusPercent: PropTypes.number,
  betBonusAmount: PropTypes.number,
};
