import React from 'react';
import PropTypes from 'prop-types';
import {
  TBC as TBC_CORE,
  TBB as TBB_CORE,
  TBD as TBD_CORE,
  TBS as TBS_CORE,
  TBT as TBT_CORE,
  TBTC as TBTC_CORE,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__taxBottomSection';
import {
  TBC as TBC_LOCAL,
  TBB as TBB_LOCAL,
  TBD as TBD_LOCAL,
  TBS as TBS_LOCAL,
  TBT as TBT_LOCAL,
  TBTC as TBTC_LOCAL,
} from 'UI/apps/BetslipSAApp/BetslipContainer/TaxBottomSection';
import { Constants } from '../../../../../core__betslip-constants';
import { Translations } from '../../../../../core__betslip-SA-app';

import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import { BetslipCurrency } from '../../BetslipContent/elements';

const TBC = TBC_LOCAL || TBC_CORE;
const TBB = TBB_LOCAL || TBB_CORE;
const TBD = TBD_LOCAL || TBD_CORE;
const TBS = TBS_LOCAL || TBS_CORE;
const TBT = TBT_LOCAL || TBT_CORE;
const TBTC = TBTC_LOCAL || TBTC_CORE;

const TaxBottomSection = ({
  tax,
  closeTaxSection,
  potentialReturns,
  fee,
  netReturn,
  currencySymbol,
}) => {
  const taxProperties = [
    {
      title: Translations.get('text.gross.returns'),
      renderingCondition:
        potentialReturns === Constants.N_A || potentialReturns === 0,
      zeroValue: Translations.get('text.na'),
      expense: potentialReturns,
    },
    {
      title: `${tax}% ${Translations.get('text.of.withholding.tax')}`,
      renderingCondition: fee === 0,
      zeroValue: Translations.get('text.tbc'),
      expense: fee,
    },
    {
      title: Translations.get('text.potential.returns.net'),
      renderingCondition: netReturn === 0,
      zeroValue: Translations.get('text.na'),
      expense: netReturn,
    },
  ];

  return (
    <TBC>
      <TBS>
        {taxProperties.map((entry, index) => {
          return (
            <TBTC key={index}>
              <TBT>{entry.title}</TBT>
              <TBT>
                {entry.renderingCondition && entry.zeroValue}
                {!entry.renderingCondition && (
                  <React.Fragment>
                    <BetslipCurrency symbol={currencySymbol} />{' '}
                    {parseDecimalPlacesPotReturn(entry.expense)}
                  </React.Fragment>
                )}
              </TBT>
            </TBTC>
          );
        })}

        <TBTC>
          <TBD>{Translations.get('text.notification.horse.racetax')}</TBD>
        </TBTC>

        <TBB onClick={closeTaxSection}>CLOSE</TBB>
      </TBS>
    </TBC>
  );
};

TaxBottomSection.propTypes = {
  tax: PropTypes.number,
  closeTaxSection: PropTypes.func,
  potentialReturns: PropTypes.number,
  fee: PropTypes.number,
  netReturn: PropTypes.number,
  currencySymbol: PropTypes.string,
};

export { TaxBottomSection };
