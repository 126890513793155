import { StyledLabel, LC } from 'CORE__UI/globals/Label/core__labelStyles';
import styled, { css } from 'styled-components';
import { BRAND, FONT, FUNCTIONAL } from './colours';

const StyledLabel_EX = styled(StyledLabel)`
  color: ${({ color = FONT.black.standard, isError }) =>
    isError ? FUNCTIONAL.error : color};
  border-color: ${BRAND.primary};
  display: flex;
  font-size: 12px;
  ${({ passwordInfo }) =>
    passwordInfo &&
    css`
      color: ${({ isError }) => (isError ? FUNCTIONAL.error : FONT.black.mid)};
    `}
`;

const LC_EX = styled(LC)`
  color: ${FUNCTIONAL.error};
`;

export { StyledLabel_EX as StyledLabel, LC_EX as LC };
