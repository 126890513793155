import React from 'react';
import PropTypes from 'prop-types';
import { BetBoxLayout, BetBoxLayoutHorseDog } from '../BetslipContent';
import { TabsGenrate } from '../BetslipTabs/elements';
import { CastOrderComponent } from './elements';
import { Constants } from '../../../../core__betslip-constants';

/**
Content:
main container of Content 

@param {*} betTemplate (whole object of bet)
@param {*} actTab (active tab from bet header) 
@param {*} handleDelete function of delete Bet
@param {*} callSetUserBetInLocalStorage function of set data in Ls
@param {*} UserAuthData object of data which come to session 
@param {*} region string of Region come through AppConfig via CMS
@param {*} handleWsAcceptDecline function which handle websocket data
 */

export const Content = ({
  betTemplate,
  actTab,
  handleDelete,
  callSetUserBetInLocalStorage,
  UserAuthData,
  handleWsAcceptDecline,
  oddsFormat,
  isLogin,
  isAccaBet,
  setShowFB,
  countAccaBet,
  hidenextbetbonus,
  setErrorObj,
  appConfig,
  errorObj,
  postApiCall,
  selectionId,
  isMaxBetExceed,
}) => {
  let isMultipleTab = false;
  const combTab = betTemplate.map(bet => bet.tab).filter(item => item);
  for (const bet of betTemplate) {
    // find multiple
    if (
      actTab === Constants.MULTIPLE &&
      bet['multiple'] &&
      bet['betModifierBetCount'] === 1 &&
      combTab.length === 0
    ) {
      isMultipleTab = true;
    }
  }
  return (
    <React.Fragment>
      {Array.isArray(betTemplate) &&
        betTemplate.map((bet, i) => {
          const betBoxInfo = {
            bet,
            i,
          };
          /**
           * for single bets when single tab is selected
           */
          if (!bet['multiple']) {
            return [Constants.HORSES, Constants.DOGS].includes(bet.catRef) ? (
              // Horse and Dog Layout
              <BetBoxLayoutHorseDog
                {...betBoxInfo}
                key={bet.id}
                handleDelete={handleDelete}
                actTab={actTab}
                callSetUserBetInLocalStorage={callSetUserBetInLocalStorage}
                wallet={UserAuthData ? UserAuthData.wallet : []}
                currencySymbol={UserAuthData?.currencyCode || null}
                balance={UserAuthData?.balance || null}
                handleWsAcceptDecline={handleWsAcceptDecline}
                oddsFormat={oddsFormat}
                isLogin={isLogin}
                setShowFB={setShowFB}
                countAccaBet={countAccaBet}
                setErrorObj={setErrorObj}
                appConfig={appConfig}
                errorObj={errorObj}
                postApiCall={postApiCall}
                selectionId={selectionId}
                isMaxBetExceed={isMaxBetExceed}
                betTemplate={betTemplate}
              />
            ) : (
              // remain all Layout
              <BetBoxLayout
                {...betBoxInfo}
                key={bet.id}
                handleDelete={handleDelete}
                actTab={actTab}
                callSetUserBetInLocalStorage={callSetUserBetInLocalStorage}
                wallet={UserAuthData ? UserAuthData.wallet : []}
                currencySymbol={UserAuthData?.currencyCode || null}
                balance={UserAuthData?.balance || null}
                handleWsAcceptDecline={handleWsAcceptDecline}
                oddsFormat={oddsFormat}
                isLogin={isLogin}
                setShowFB={setShowFB}
                countAccaBet={countAccaBet}
                setErrorObj={setErrorObj}
                appConfig={appConfig}
                errorObj={errorObj}
                postApiCall={postApiCall}
                selectionId={selectionId}
                isMaxBetExceed={isMaxBetExceed}
                betTemplate={betTemplate}
              />
            );
          } else if (
            actTab === Constants.MULTIPLE &&
            bet['multiple'] &&
            accaCond(bet, Constants.MULTIPLE, isAccaBet)
          ) {
            let obj = {};
            let act = '';
            if (isMultipleTab) {
              obj = {
                actTab: Constants.MULTIPLE,
                display: 'Multiple',
                delete: false,
              };
              act = Constants.MULTIPLE;
            }
            isMultipleTab = false;
            /**
             *Multiple Type of bet
             */
            if ([Constants.HORSES, Constants.DOGS].includes(bet.catRef)) {
              // Horse and Dog Layout show tab of forcast and tricast
              if (bet.type === 'castorder') {
                // Horse and Dog Layout show Up and Down Arrow of cast System,
                return (
                  <React.Fragment key={bet.id}>
                    <TabsGenrate
                      tabs={[obj]}
                      props={{
                        actTab: act,
                      }}
                    />
                    <CastOrderComponent
                      callSetUserBetInLocalStorage={
                        callSetUserBetInLocalStorage
                      }
                      betTemplate={betTemplate}
                      description={bet.description}
                      catref={bet.catref}
                    />
                  </React.Fragment>
                );
              } else {
                // Horse and Dog Layout Multiple System,
                if (bet.multiple && bet.betModifierBetCount === 1) {
                  return (
                    bet.betModifier && (
                      <BetBoxLayoutHorseDog
                        {...betBoxInfo}
                        key={bet.id}
                        handleDelete={handleDelete}
                        actTab={actTab}
                        callSetUserBetInLocalStorage={
                          callSetUserBetInLocalStorage
                        }
                        wallet={UserAuthData ? UserAuthData.wallet : []}
                        currencySymbol={UserAuthData?.currencyCode || null}
                        balance={UserAuthData?.balance || null}
                        handleWsAcceptDecline={handleWsAcceptDecline}
                        oddsFormat={oddsFormat}
                        isLogin={isLogin}
                        setShowFB={setShowFB}
                        countAccaBet={countAccaBet}
                        hideNextBetBonus={hidenextbetbonus}
                        setErrorObj={setErrorObj}
                        appConfig={appConfig}
                        errorObj={errorObj}
                        postApiCall={postApiCall}
                        selectionId={selectionId}
                        isMaxBetExceed={isMaxBetExceed}
                        betTemplate={betTemplate}
                      />
                    )
                  );
                } else if (
                  actTab === Constants.SYSTEM &&
                  bet['multiple'] &&
                  (bet.betModifierBetCount > 1 || bet.betModifierBetCount === 1)
                ) {
                  return (
                    <BetBoxLayoutHorseDog
                      {...betBoxInfo}
                      key={bet.id}
                      handleDelete={handleDelete}
                      actTab={actTab}
                      callSetUserBetInLocalStorage={
                        callSetUserBetInLocalStorage
                      }
                      wallet={UserAuthData.wallet ? UserAuthData.wallet : []}
                      currencySymbol={UserAuthData?.currencyCode || null}
                      balance={UserAuthData?.balance || null}
                      handleWsAcceptDecline={handleWsAcceptDecline}
                      oddsFormat={oddsFormat}
                      isLogin={isLogin}
                      setShowFB={setShowFB}
                      countAccaBet={countAccaBet}
                      hideNextBetBonus={hidenextbetbonus}
                      setErrorObj={setErrorObj}
                      appConfig={appConfig}
                      errorObj={errorObj}
                      postApiCall={postApiCall}
                      selectionId={selectionId}
                      isMaxBetExceed={isMaxBetExceed}
                      betTemplate={betTemplate}
                    />
                  );
                } else {
                  // error of default case
                  return null;
                }
              }
            } else {
              const { betCount } = bet?.betModifier?.[0];
              // remain all Multiple System
              if (bet.multiple && betCount === 1) {
                return (
                  <BetBoxLayout
                    {...betBoxInfo}
                    key={bet.id}
                    handleDelete={handleDelete}
                    actTab={actTab}
                    callSetUserBetInLocalStorage={callSetUserBetInLocalStorage}
                    wallet={UserAuthData.wallet ? UserAuthData.wallet : []}
                    currencySymbol={UserAuthData?.currencyCode || null}
                    balance={UserAuthData?.balance || null}
                    handleWsAcceptDecline={handleWsAcceptDecline}
                    oddsFormat={oddsFormat}
                    isLogin={isLogin}
                    setShowFB={setShowFB}
                    countAccaBet={countAccaBet}
                    hideNextBetBonus={hidenextbetbonus}
                    setErrorObj={setErrorObj}
                    appConfig={appConfig}
                    errorObj={errorObj}
                    postApiCall={postApiCall}
                    selectionId={selectionId}
                    isMaxBetExceed={isMaxBetExceed}
                    betTemplate={betTemplate}
                  />
                );
              }
            }
          } else if (
            actTab === Constants.SYSTEM &&
            bet['multiple'] &&
            (bet.betModifierBetCount > 1 || bet.betModifierBetCount === 1)
          ) {
            return (
              <BetBoxLayout
                {...betBoxInfo}
                key={bet.id}
                handleDelete={handleDelete}
                actTab={actTab}
                callSetUserBetInLocalStorage={callSetUserBetInLocalStorage}
                wallet={UserAuthData.wallet ? UserAuthData.wallet : []}
                currencySymbol={UserAuthData?.currencyCode || null}
                balance={UserAuthData?.balance || null}
                handleWsAcceptDecline={handleWsAcceptDecline}
                oddsFormat={oddsFormat}
                isLogin={isLogin}
                setShowFB={setShowFB}
                countAccaBet={countAccaBet}
                hideNextBetBonus={hidenextbetbonus}
                setErrorObj={setErrorObj}
                appConfig={appConfig}
                errorObj={errorObj}
                postApiCall={postApiCall}
                selectionId={selectionId}
                isMaxBetExceed={isMaxBetExceed}
                betTemplate={betTemplate}
              />
            );
          } else {
            // error of default case
            return null;
          }
        })}
    </React.Fragment>
  );
};

Content.propTypes = {
  betTemplate: PropTypes.array,
  hideNextBetBonus: PropTypes.array,
  hidenextbetbonus: PropTypes.string,
  actTab: PropTypes.string,
  handleDelete: PropTypes.func,
  callSetUserBetInLocalStorage: PropTypes.func,
  UserAuthData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  region: PropTypes.string,
  handleWsAcceptDecline: PropTypes.func,
  oddsFormat: PropTypes.string,
  isLogin: PropTypes.bool,
  isAccaBet: PropTypes.bool,
  setShowFB: PropTypes.func,
  countAccaBet: PropTypes.number,
  appConfig: PropTypes.object,
  setErrorObj: PropTypes.func,
  errorObj: PropTypes.object,
  postApiCall: PropTypes.bool,
  selectionId: PropTypes.array,
  isMaxBetExceed: PropTypes.bool,
};

export const ContentTab = Content;

export const accaCond = (bet, betType, isAccaBet) => {
  if (
    isAccaBet &&
    bet.betModifierBetCount === 1 &&
    betType === Constants.ACCA
  ) {
    return true;
  } else if (
    isAccaBet &&
    betType === Constants.MULTIPLE &&
    (bet.betModifierBetCount > 1 ||
      bet.type === 'castorder' ||
      bet.tab === 'multiple')
  ) {
    return true;
  } else if (!isAccaBet) {
    return true;
  } else {
    return false;
  }
};
