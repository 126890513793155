import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDataOfPrice } from '../../../../../core__betslip-utils';
import { Constants } from '../../../../../core__betslip-constants';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import {
  TLI,
  TDIV,
  WSLI2BTN,
  WSLI1BTN,
  WSUL,
  WSP,
  WSPOPUP,
} from 'UI/apps/BetslipSAApp/BetslipContainer/SocketMessage';
import { Translations } from '../../../../../core__betslip-SA-app';

/**
 * @param {Array} bet
 * @param {func} handleWsAcceptDecline,
 * @param {string} oddsFormat
 */

export const SocketMessage = ({ bet, handleWsAcceptDecline, oddsFormat }) => {
  const [ewprice, setEwprice] = useState([]);

  useEffect(() => {
    setEwprice(getDataOfPrice(bet.price));
  }, [bet]);
  return (
    <React.Fragment>
      <WSPOPUP>
        {oddsFormat === Constants.DECIMAL ? (
          <WSP>
            {Translations.get('text.odd.change.from') +
              ' ' +
              parseDecimalPlaces(bet.previousPriceDecimal) +
              ' ' +
              Translations.get('text.to') +
              ' ' +
              parseDecimalPlaces(ewprice['decimal'])}
          </WSP>
        ) : oddsFormat === Constants.AMERICAN ? (
          <WSP>
            {Translations.get('text.odd.change.from') +
              ' ' +
              getOdds(bet.previousPriceDecimal, true) +
              ' ' +
              Translations.get('text.to') +
              ' ' +
              getOdds(ewprice['decimal'], true)}
          </WSP>
        ) : (
          <WSP>
            {Translations.get('text.odd.change.from') +
              ' ' +
              bet.previousPriceFractional +
              ' ' +
              Translations.get('text.to') +
              ' ' +
              ewprice['fractional']}
          </WSP>
        )}

        <WSUL>
          <TLI>
            <WSLI1BTN
              onClick={() => {
                handleWsAcceptDecline('accept', bet);
              }}
            >
              {Translations.get('text.accept')}
            </WSLI1BTN>
          </TLI>
          <TLI>
            <WSLI2BTN
              onClick={() => {
                handleWsAcceptDecline('decline', bet);
              }}
            >
              {Translations.get('text.decline')}
            </WSLI2BTN>
          </TLI>
        </WSUL>
      </WSPOPUP>
      <TDIV></TDIV>
    </React.Fragment>
  );
};

SocketMessage.propTypes = {
  bet: PropTypes.object,
  handleWsAcceptDecline: PropTypes.func,
  oddsFormat: PropTypes.string,
};
