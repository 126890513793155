import Label from 'CORE__UI/globals/Label/core__label';
import styled, { css } from 'styled-components';
import { FUNCTIONAL } from './colours';

const Label_UI = styled(Label)`
  ${({ passwordError }) =>
    passwordError &&
    css`
      color: ${FUNCTIONAL.negative};
    `}
`;

export default Label_UI;
