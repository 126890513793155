export const mockTranslations = {
  'text.empty': 'Your betslip is empty',

  'text.site.features': 'Site Features',

  'text.live.chat': 'Live Chat',
  'text.live.chat.description': 'The fastest way to contact us.',
  'text.chat': 'Chat',

  'text.safer.gambling': 'Safer Gambling',
  'text.safer.gambling.description':
    'Making sure everyone gambles responsibly.',

  'text.football.bet.builder': 'Football Bet Builder',
  'text.football.bet.builder.description':
    'Now available on up to 12 selections. Create your own personalised bet on any football game. T&C apply.',

  'text.live.streaming.highlights': 'Live Streaming Highlights',
  'text.live.streaming.highlights.description':
    'A selection of the top Football matches you can watch over the next few days. Eligible customers only. Live Streaming rules apply.',
};

export const emptyBetslipTranslationKeys = Object.keys(mockTranslations);
