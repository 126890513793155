import {
  BSSMD,
  BI,
  PPD,
  BetslipContentBetBoxPot,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__stake';
import styled, { css } from 'styled-components';
import { BK_COLORS, FONT, FUNCTIONAL } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const BSSMD_EX = styled(BSSMD)`
  border: none;
  background-color: ${BK_COLORS.aliceBlue08};
  ${({ hasStake }) =>
    hasStake &&
    css`
      border: none;
    `}
  ${({ highlighterror, hasStake }) =>
    highlighterror &&
    hasStake &&
    css`
      border: 1px solid ${FUNCTIONAL.negative};
    `}
    @media (max-width: ${BREAKPOINT_S}) {
    height: 40px;
  }
`;

const BI_EX = styled(BI)`
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  &::placeholder {
    color: ${FONT.black.mid};
  }
`;

export { BSSMD_EX as BSSMD, BI_EX as BI, PPD, BetslipContentBetBoxPot };
