import styled, { css } from 'styled-components';
import { GREYS, FONT, BRAND, SHADOW, FUNCTIONAL } from 'UI/globals/colours';
import theme from 'UI/globals/theme';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';

export const BU = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 8px;
  ${({ multiple }) =>
    multiple &&
    css`
      padding-top: 8px;
    `}
`;

export const BD = styled.div`
display: flex;
user-select: none;
align-items: center;
margin: 8px;
h5 {
  font-family: ${theme.fontFamily}
  font-size: 12px;
  letter-spacing: 0.25px;
  color: ${FONT.dark};
  user-select: none;
}
`;

export const BCLC = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 8px;
`;

export const BCL = styled.span`
  background-color: ${BRAND.primaryD};
  color: ${GREYS.white};
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
  text-transform: uppercase;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BCSM = styled.div`
  display: flex;
  margin-bottom: 1em;
  ${({ accaBonus }) =>
    accaBonus &&
    css`
      position: relative;
      background: rgb(246 159 158 / 9%);
      right: 0;
      top: 0;
      padding: 0 4px;
      border: 1px solid ${FUNCTIONAL.negativeL};
      font-size: 10px;
      color: ${FUNCTIONAL.negativeL};
      border-radius: 4px;
      margin-right: 0;
      left: 2px;
    `}
`;

export const BSMD = styled.div`
  margin: 8px;
`;

export const BMD = styled.div`
  margin: 8px 12px 8px;
  border-bottom: 1px solid ${BRAND.tertiary};
  box-shadow: ${SHADOW};
  border-radius: 4px;
  @media (max-width: ${BREAKPOINT_M}) {
    // coz in mobile when url tab display bottom section hide
    :last-child {
      margin-bottom: 188px;
    }
  }
`;

export const FBPR = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  ${({ freebet }) => {
    return (
      freebet &&
      css`
        flex-direction: column-reverse;
      `
    );
  }}
`;

export const BetslipContentBetBoxPot = styled.span`
  color: ${GREYS.silver};
  font-size: 12px;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      color: ${FONT.dark};
    `};
`;

export const ENS = styled.span`
  color: ${GREYS.white};
`;
export const BBh5 = styled.span`
  color: ${FONT.dark};
  font-size: 12px;
`;
export const ERR = styled.div`
  padding-left: 8px;
`;

export const MATCH_RESULT = styled.span`
  font-weight: bold;
  margin-right: 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

export const TIME_SPAN = styled.span`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

export const LIVE_ROW = styled.p`
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding: 0px 16px;
`;
