import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BS,
  SMM,
  MDI,
  FDMD,
  CSD,
  BSM,
} from 'UI/apps/BetslipSAApp/BetslipContainer/BetslipContainer';
import { Header } from './BetslipHeader';
import { BetslipRemainingBoosts } from './BetslipRemainingBoosts';
import { Tabs } from './BetslipTabs';
import { ContentTab } from './BetslipContent';
import { MessageComponent } from './BetslipError';
import { Summary } from './BetslipSummary';
import { OddMovements } from './BetslipOddMovements';
import { OddChanges } from './BetslipOddMovements/core__oddChanges';
import { M } from 'UI/apps/BetslipSAApp/BetslipContainer/TopSection';
import { GREYS } from 'UI/globals/colours';
import { DeleteIcon } from '../../../core__betslip-utils';
import { Constants } from '../../../core__betslip-constants';

/**
 @param {object} errorObj
 @param {array} betTemplate
 @param {object} UserAuthData
 @param {object} setErrorObj
 @param {object} propertiesproperties
 */

export const BetslipContainer = ({
  errorObj,
  betTemplate,
  UserAuthData,
  setErrorObj,
  properties,
  countAccaBet,
  hiddenTabs,
  appConfig,
}) => {
  const clearBetslipIcon = (
    <M
      iconShow={true}
      showTop={appConfig.topdeletebetslip}
      onClick={() => {
        properties.handleDelete({ type: 'deleteall' });
      }}
    >
      <DeleteIcon color={GREYS.white} />
    </M>
  );

  const [oddsBoostExceeded, setOddsBoostExceeded] = useState(false);
  const [showFB, setShowFB] = useState(false);
  const adjustBottomHeight = properties?.bottomNavData?.loginLinks;
  properties['setShowFB'] = setShowFB;

  const betObj = betTemplate && betTemplate.find(item => !item.isValid);

  const checkMaxStakeExceeded = (err = {}) => {
    const errMsg = err?.message?.toLowerCase() || '';
    const checked = errMsg.includes(Constants.MAXSTAKE);
    return checked;
  };

  const handleBetBoostTracking = newValue => {
    setOddsBoostExceeded(newValue);
  };

  return (
    <BS openBS={properties.openBS}>
      <Header {...properties} />
      <BSM openBS={properties.openBS}>
        {betTemplate.length > 0 &&
          UserAuthData?.raw?.availableBetBoostTokens > 0 && (
            <BetslipRemainingBoosts {...properties} />
          )}
        {appConfig?.topdeletebetslip
          ? betTemplate.length > 0 && (
              <CSD showTop={true}>
                <Tabs hiddenTabs={hiddenTabs} {...properties} />
                {clearBetslipIcon}
              </CSD>
            )
          : betTemplate.length > 0 && (
              <Tabs hiddenTabs={hiddenTabs} {...properties} />
            )}

        {betTemplate.length > 0 && (
          <CSD openBS={properties.openBS} countAccaBet={countAccaBet}>
            <ContentTab
              {...properties}
              errorObj={errorObj}
              countAccaBet={countAccaBet}
              setErrorObj={setErrorObj}
              appConfig={appConfig}
            />

            {/* properties.customOddsComponent is added to use a custom component, default value is false */}
            {/* when customOddsComponent is not present, default is false, handled in core__betslipReact */}

            {/* when showOddMovements true for custodian */}
            {properties.showOddMovements &&
              properties.isLogin &&
              !properties.customOddsComponent && (
                <OddMovements
                  handleActTabNotificationPopup={
                    properties.handleActTabNotificationPopup
                  }
                  notificationParam={properties.notificationParam}
                  betTemplate={betTemplate}
                />
              )}
            {properties.showOddMovements && properties.customOddsComponent && (
              <OddChanges
                handleActTabNotificationPopup={
                  properties.handleActTabNotificationPopup
                }
                notificationParam={properties.notificationParam}
                betTemplate={betTemplate}
                changeDDIndicator={appConfig.changeddindicator}
              />
            )}
          </CSD>
        )}

        <SMM openBS={properties.openBS} adjustBottomHeight={adjustBottomHeight}>
          {(errorObj.isError === true && (
            <MessageComponent
              useChatWithBroker={appConfig.usechatwithbroker}
              isError={errorObj.isError}
              message={errorObj.message}
              betTemplate={betTemplate}
              setErrorObj={setErrorObj}
              isMaxBetExceed={checkMaxStakeExceeded(errorObj)}
              isValidStake={betObj?.isValid}
              oddsBoostExceeded={oddsBoostExceeded}
            />
          )) ||
            (betTemplate.length > 0 &&
              properties.suspended === false &&
              errorObj.isError !== true &&
              betObj && (
                <MessageComponent
                  useChatWithBroker={appConfig.usechatwithbroker}
                  betTemplate={betTemplate}
                  isError={false}
                  message=""
                  setErrorObj={setErrorObj}
                  isValidStake={betObj?.isValid}
                  oddsBoostExceeded={oddsBoostExceeded}
                />
              ))}
          {betTemplate.length > 0 && (
            <Summary
              appConfig={appConfig}
              {...properties}
              isMaxBetError={checkMaxStakeExceeded(errorObj)}
              {...properties}
              handleBetBoostTracking={handleBetBoostTracking}
            />
          )}
        </SMM>
      </BSM>

      <FDMD showFB={showFB}>
        <MDI id="freebetpopup"></MDI>
      </FDMD>
    </BS>
  );
};
BetslipContainer.propTypes = {
  properties: PropTypes.object,
  betTemplate: PropTypes.array,
  UserAuthData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  errorObj: PropTypes.object,
  setErrorObj: PropTypes.func,
  countAccaBet: PropTypes.number,
  hiddenTabs: PropTypes.array,
  appConfig: PropTypes.object,
};
