import React from 'react';
import PropTypes from 'prop-types';
import PubSub from 'Services/pubsub/core__pubsub';
import { Header } from './components/BetslipContainer/BetslipHeader';
import addCustomGTMEventToDataLayer, {
  gtmEventNames,
} from 'Services/gtm/core__addCustomGTMEventToDataLayer';
import {
  dataFromHashParams,
  dataFromQueryParams,
  getDataOfPrice,
  checkSuspendedBet,
  genrateCastingForUS,
  getSelectionIds,
  mergeDataWithLocalStorage,
  placingBetUser,
  removeUserDependentItems,
  validateFreeBet,
  wsSocketDataManipulation,
  findMarket,
  suspendSelectedMarket,
  mergeBuilderDataInTemplate,
  handleSortCreateBet,
  genrateSlips,
  upcomingFavouritesAmount,
  updateAmountComeToUrl,
  retainCleanupBet,
  checkLocalStorageTeaser,
  checkActiveTab,
  catchTeaserData,
  getSelectionIdsWithActiveStatus,
  notificationValues,
  validateBetBooster,
  previousPrice,
  CalCount,
} from '../core__betslip-utils';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { connect } from 'react-redux';
import {
  removeFromLocalStorage,
  setterGetterFromLocalstorage,
  setUserBetInlocalStorage,
  removeFromLocalStorageDeleteSuspended,
} from 'Services/localstorage/core__localstorage';
import ProductRestriction from 'Services/productRestriction/core__product-restriction';
import {
  userBetInfo,
  poststoreData,
  validateSelectionPrice,
} from '../core__betslip-store';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { Constants } from '../core__betslip-constants';
import { ReceiptContainer } from './components/ReceiptContainer';
import { BetslipContainer } from './components/BetslipContainer';
import { BS, B, HideOnMobile } from 'UI/apps/BetslipUSApp/BetslipReact';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { FSBTheme } from 'Services/core__fsb-theme';
import { Translations } from '../core__betslip-SA-app';
import accountRedirectUrls from 'Services/global/core__accountRedirectUrls';
import EmptyBetslip from 'UI/globals/EmptyBetslip';

/**
Data via Props and Store 
@param apiRes Object which represent Api returning some of response
@param appConfig Object which represent congfig data which come to CMS
@param betTemplate Array object which represent data of bets which come via API
@param cleanBets Function which handle to update store value when BetSlip deleted 
@param fetchData Function which handle a GET requests 
@param placedBet Object which handle data of placing Bets , which is through API
@param postData Function which handle a POST requests 
@param poststoreData Function which store posting Bet data
@param url Object which store urls of apis
@param useBet Object which store useBet data
@param userBetInformation Function which handle user Bets info.
*/
let awaitingPreviousBet = false;
let betTemplateUpdate = false;

export class BetslipReact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionId: [],
      oldBuilderData: [],
      builderParams: [],
      builderData: [],
      actTab: Constants.SINGLE,
      betTemplate: [],
      useBet: {},
      isLogin: false,
      betSlipRecipt: {},
      errorObj: {
        isError: false,
        message: '',
      },
      oddsFormat: props?.oddsFormat || Constants.FRACTIONAL,
      notificationParam: props?.appConfig?.notificationParam || Constants.BLOCK,
      amtObj: [],
      postApiCall: false,
      render: false,
      openBS: false,
      theme: props.appConfig?.theme,
      betTemplateTeaser: {},
      bottomNavData: {},
      betslipExpand: false,
    };
    if (props?.appConfig?.virtuals) {
      Constants.LSKEY_US = 'betslip_virtual_us';
    }
  }

  componentDidMount() {
    this.setState({ render: true });
    // handle Place bet function if user directly come via login popup
    this.callBetSlipListener = PubSub.listen(
      PubsubEvents.CallBetSlip,
      wsData => {
        if (wsData && wsData['place'] === true && !awaitingPreviousBet) {
          awaitingPreviousBet = true;
          this.handlePlaceBet();
          setTimeout(() => {
            awaitingPreviousBet = false;
          }, 2000);
        }
      }
    );
    // handle where data comes from events apps & market apps
    this.betslipUpdateListener = PubSub.listen(
      PubsubEvents.BetslipUpdate,
      wsData => {
        if (!ProductRestriction?.getIsCurrentProductRestricted(true)) {
          const { betSlipRecipt } = this.state;
          if (wsData?.upcomingFavAmt && wsData?.selectionId) {
            this.setState({ upcomingFavAmt: wsData?.upcomingFavAmt });
          }

          if (Object.keys(betSlipRecipt).length > 0) {
            // if betslip recipt open and user click on selection button .
            // first remove selection from Betslip then add new
            this.handleReceptCloseButton(false);
            this.props.cleanBets(
              { type: 'STORE_DATA', data: [] },
              { type: 'POST_STORE_DATA', data: [] }
            );
            // remove existing selectionId
            this.setState(
              {
                betTemplate: [],
                selectionId: [],
              },
              () => {
                // call Bettemplate API
                this.callBetTemplateApi(wsData.selectionId);
              }
            );
          } else {
            // create Selection id Array
            const selectionId = this.genrateSelectionIdArr(wsData);
            // store selection id
            this.setState(
              {
                selectionId: selectionId,
              },
              () => {
                // default condition call Bettemplate API
                this.callBetTemplateApi(
                  this.state.selectionId.filter(item => item)
                );
              }
            );
          }
        }
      }
    );

    // handle page refresh initial load
    const localData = setterGetterFromLocalstorage({
      keyName: Constants.LSKEY_US,
      action: 'get',
    });

    // check any old record exist in localStorage
    if (localData && localData.length > 0) {
      let { bsid, bbid } = getSelectionIds(localData);
      if (window && window?.location?.hash) {
        // if any id in Url and data is in hash params
        const hashData = dataFromHashParams(window);
        if (hashData) {
          if (hashData?.amtObj && Array.isArray(hashData.amtObj)) {
            this.setState({ amtObj: hashData.amtObj });
          }
          bsid = bsid.concat(hashData.sid);
        }
      } else if (
        window &&
        window?.location?.search &&
        dataFromQueryParams(window)
      ) {
        // if any id in Url and data is in Query params
        const queryString = dataFromQueryParams(window);
        if (queryString) {
          if (queryString?.amtObj && Array.isArray(queryString.amtObj)) {
            this.setState({ amtObj: queryString.amtObj });
          }
          bsid = bsid.concat(queryString.sid);
        }
      }
      // on page reload and || with LS selection ids
      this.setState(
        {
          selectionId: [...bsid],
        },
        () => {
          if (Array.isArray(bsid) && bsid.length > 0)
            this.callBetTemplateApi(bsid);
        }
      );
      if (bbid.length > 0) {
        this.betbulderApiCall(bbid);
      }
    } else if (
      localData.length === 0 &&
      window &&
      window?.location?.hash &&
      dataFromHashParams(window)
    ) {
      // if no old record and selection come to URL as Hash params ids
      const hashData = dataFromHashParams(window);
      if (hashData?.amtObj && Array.isArray(hashData.amtObj)) {
        this.setState({ amtObj: hashData.amtObj });
      }
      const selectionId = this.genrateSelectionIdArr({
        selectionId: hashData.sid,
      });
      this.setState(
        {
          selectionId,
        },
        () => {
          this.callBetTemplateApi(this.state.selectionId);
        }
      );
    } else if (
      localData.length === 0 &&
      window &&
      window?.location?.search &&
      dataFromQueryParams(window)
    ) {
      // if no old record and selection come to URL as Query params ids
      const queryString = dataFromQueryParams(window);
      if (queryString?.amtObj && Array.isArray(queryString.amtObj)) {
        this.setState({ amtObj: queryString.amtObj });
      }
      const selectionId = this.genrateSelectionIdArr({
        selectionId: queryString.sid,
      });
      this.setState(
        {
          selectionId,
        },
        () => {
          this.callBetTemplateApi(this.state.selectionId);
        }
      );
    }

    // when user login
    this.sessionCreatedListener = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      () => {
        const UserAuthData = FSBCustomerSession.getSession();
        this.userAuthStuff(UserAuthData);
      }
    );

    // when user relogin or reload page
    this.sessionUpdatedListener = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      () => {
        const UserAuthData = FSBCustomerSession.getSession();
        this.userAuthStuff(UserAuthData);
      }
    );

    this.bottomNavData = PubSub.listen(PubsubEvents.BOTTOM_NAV_DATA, data => {
      this.setState({ bottomNavData: data });
    });

    this.expandBetslip = PubSub.listen(PubsubEvents.BETSLIP_EXPAND, data => {
      this.setState({ betslipExpand: data });
    });

    // if user is already login
    const UserAuthData = FSBCustomerSession.getSession();
    this.userAuthStuff(UserAuthData);

    this.priceNotCurrent = PubSub.listen(
      PubsubEvents.PRICE_NOT_CURRENT,
      data => {
        // Recall template Api
        const { betTemplate, selectionId } = this.state;

        // add field for display popup and update in betTemplate Array
        betTemplate.forEach((bet, key) => {
          if (!bet.multiple) {
            bet.price.forEach(price => {
              if (price.id === data.priceId) {
                betTemplate[key]['priceChangeNotification'] = true;
                betTemplate[key]['updateNewPrice'] = false;
                betTemplate[key]['previousPriceDecimal'] = previousPrice(
                  price.decimal,
                  price.bookType
                );
                betTemplate[key]['previousPriceFractional'] = previousPrice(
                  price.fractional,
                  price.bookType
                );
              }
            });
          }
        });

        // set data in LS and recall api for current price
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_US,
          keyValue: betTemplate,
          action: 'set',
        });
        this.setState(
          {
            betTemplate: betTemplate,
          },
          () => {
            this.callBetTemplateApi(selectionId);
          }
        );
      }
    );

    // when WS data comes
    this.getMarketDataToWSListener = PubSub.listen(
      PubsubEvents.getMarketDataToWS,
      wsData => {
        if (wsData?.selections && wsData.selections.length > 0) {
          let isTrue = false;
          const updateSelection = wsData.selections;
          // check data exist in WS and also check selection exist in that
          for (const itm of updateSelection) {
            const selectionId = this.state.selectionId;
            if (selectionId.includes(itm.id) && !isTrue) {
              isTrue = true;
            }
          }
          if (isTrue) {
            const data11 = wsSocketDataManipulation(
              wsData,
              this.state.betTemplate,
              this.state.notificationParam
            );
            // set old data in LS
            setterGetterFromLocalstorage({
              keyName: Constants.LSKEY_US,
              keyValue: data11,
              action: 'set',
            });
            // set updated data in store
            const { bsid } = getSelectionIdsWithActiveStatus(data11);
            if (bsid.length > 0) {
              // bet template api call
              this.callBetTemplateApi(bsid);
            }
          }
        } else if (
          wsData.selections.length === 0 &&
          wsData.state === 'CLOSED' &&
          findMarket(this.state.betTemplate, wsData)
        ) {
          const data11 = suspendSelectedMarket(wsData, this.state.betTemplate);
          const data = { betTemplate: data11 };
          this.props.cleanBets({ type: 'STORE_DATA', data: data });
        }
      }
    );

    //listen to pubsub for odds change
    this.oddsValueListener = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      if (wsData === Constants.DECIMAL) {
        this.setState({ oddsFormat: Constants.DECIMAL });
      } else if (wsData === Constants.AMERICAN) {
        this.setState({ oddsFormat: Constants.AMERICAN });
      } else {
        this.setState({ oddsFormat: Constants.FRACTIONAL });
      }
    });

    // handle when user logout
    this.sessionDestroyListener = PubSub.listen(
      PubsubEvents.SESSION_DESTROY,
      () => {
        // remove all userdependent elements from betslip object
        //  for removeing Receipt
        this.props.cleanBets({ type: 'POST_STORE_DATA', data: [] });

        const updateBetTemplate = removeUserDependentItems(
          this.state.betTemplate,
          Constants.LSKEY_US
        );

        this.setState({
          isLogin: false,
          betTemplate: updateBetTemplate,
          betSlipRecipt: {},
        });
      }
    );

    // bet builder
    this.addBetBuilderListener = PubSub.listen(
      PubsubEvents.addBetBuilder,
      data => {
        let eventId = '';
        let sentence = '';
        const params = [];
        if (Object.keys(data).length > 0) {
          eventId = data.eventId;
          for (const bs of data.sentence) {
            sentence += '&sentence=' + bs;
          }
          params.push(eventId + sentence);
          this.betbulderApiCall(params);
        }
      }
    );

    // check customer card
    this.cusCardListener = PubSub.listen(PubsubEvents.CUS_CARD, data => {
      let card = false;
      if (data && data.length > 0) {
        card = true;
        this.setState({
          card: card,
        });
      }
    });

    this.openBetslip = PubSub.listen(PubsubEvents.OPEN_BETSLIP, wsData => {
      this.handleOpenBS(wsData);
    });

    // handle odd moment refresh initial load
    const oddMoment = setterGetterFromLocalstorage({
      keyName: Constants.ODDMOMENT,
      action: 'get',
    });

    if (oddMoment && oddMoment.length > 0) {
      this.handleActTabNotificationPopup(oddMoment, false);
    }
  }

  componentWillUnmount() {
    //Unsubscribing from PubSubs
    this.callBetSlipListener?.unsubscribe();
    this.betslipUpdateListener?.unsubscribe();
    this.sessionCreatedListener?.unsubscribe();
    this.sessionUpdatedListener?.unsubscribe();
    this.getMarketDataToWSListener?.unsubscribe();
    this.oddsValueListener?.unsubscribe();
    this.sessionDestroyListener?.unsubscribe();
    this.addBetBuilderListener?.unsubscribe();
    this.cusCardListener?.unsubscribe();
    this.sessionCreatedPubsub?.unsubscribe();
    this.sessionUpdatedPubsub?.unsubscribe();
    this.priceNotCurrent?.unsubscribe();
    this.openBetslip.unsubscribe();
    this.bottomNavData.unsubscribe();
    this.betslipExpand.unsubscribe();
  }

  /**
  getDerivedStateFromProps lifecycle method whenever state set of props change this life cycle call
  @param nextProps Object which represent updated property of component  
  @param prevState Object which represent previous property of component
  function returns update value of State
*/
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.apiRes && Object.keys(nextProps.apiRes).length > 0) {
      if (nextProps.placedBet && Object.keys(nextProps.placedBet).length > 0) {
        /* 
        all logic of successfully/fail Bet place 
        */
        if (nextProps.placedBet.inError) {
          if (
            nextProps.placedBet.statusText === GLOBAL_CONSTANTS.UNAUTHORIZED ||
            nextProps.placedBet.statusText === 'AUTHENTICATION'
          ) {
            // if statusText  comes Unauthorized or status text come 'AUTHENTICATION'
            nextProps.cleanBets({ type: 'POST_STORE_DATA', data: [] });
            PubSub.emit(PubsubEvents.CallLoginPopUp, {
              hitting: true,
            });
            return prevState;
          } else {
            return {
              errorObj: {
                isError: true,
                message: nextProps.placedBet?.response?.status?.devMessage,
                betMsg: nextProps.placedBet?.response?.status?.messageArg || '',
              },
              betTemplate: prevState.betTemplate,
              isLogin: prevState.isLogin,
              postApiCall: false,
            };
          }
        } else {
          // here betSlip receipt work
          return {
            betTemplate: prevState.betTemplate,
            betSlipRecipt: nextProps.placedBet.customer,
            isLogin: prevState.isLogin,
            postApiCall: false,
            errorObj: {
              isError: false,
              message: '',
            },
          };
        }
      } else if (
        prevState.selectionId &&
        prevState.selectionId.length === 0 &&
        nextProps.betTemplate.length === 0 &&
        prevState.builderParams.length === 0 &&
        nextProps.builderData.length === 0
      ) {
        const betTemplate = checkSuspendedBet([], Constants.LSKEY_US);
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_US,
          keyValue: betTemplate,
          action: 'set',
        });
        return {
          betTemplate: betTemplate,
          selectionId: [],
          isLogin: prevState.isLogin,
          postApiCall: false,
          errorObj: {
            isError: false,
            message: '',
          },
        };
      } else if (
        nextProps.useBet &&
        Object.keys(nextProps.useBet).length !==
          Object.keys(prevState.useBet).length
      ) {
        if (
          Array.isArray(nextProps.placedBet) &&
          nextProps.placedBet.length === 0
        ) {
          //when user click on cross button of api error
          prevState.errorObj = {
            isError: false,
            message: '',
          };
        }
        return prevState;
      } else if (nextProps.betTemplate || nextProps.builderData) {
        const builderParams = new Set();
        let { betTemplate, builderData, isDelete } = nextProps;

        if (builderData && builderData.length > 0) {
          betTemplate = mergeBuilderDataInTemplate(betTemplate, builderData);
        }

        if (betTemplate.length > 0) {
          betTemplate = mergeDataWithLocalStorage(
            betTemplate,
            Constants.LSKEY_US
          );
        }
        if (!isDelete)
          betTemplate = checkSuspendedBet(betTemplate, Constants.LSKEY_US);

        if (Array.isArray(betTemplate)) {
          for (const item of betTemplate) {
            if (item['betTypeRef'] === Constants.BBB && item.selectionId) {
              builderParams.add(item.selectionId);
            }
            // if amount come in URL then it update in useramt
            if (prevState?.amtObj) {
              const { amtObj } = prevState;
              amtObj.forEach(element => {
                if (element[item['id']]) {
                  const priceData = getDataOfPrice(item.price);
                  updateAmountComeToUrl(item, priceData, element);
                }
              });
            }
            // if amount come form upcoming fav.
            if (prevState?.upcomingFavAmt && item.betModifierBetCount === 1) {
              const { upcomingFavAmt } = prevState;
              upcomingFavouritesAmount(item, upcomingFavAmt);
            }
          }
        }

        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_US,
          keyValue: betTemplate,
          action: 'set',
        });
        const betTemplateTeaser = checkLocalStorageTeaser(
          nextProps.betTemplateTeaser,
          Constants.BETSLIPUSTEASER
        );
        // check active tab
        const actTab = checkActiveTab(
          prevState.actTab === Constants.TEASER
            ? betTemplateTeaser
            : betTemplate,
          prevState.actTab,
          nextProps?.appConfig
        );
        return {
          ...prevState,
          betTemplate: betTemplate,
          builderParams: [...builderParams],
          builderData: builderData,
          isLogin: prevState.isLogin,
          upcomingFavAmt: '',
          errorObj: {
            isError: false,
            message: '',
          },
          betTemplateTeaser: betTemplateTeaser,
          actTab: actTab,
        };
      } else {
        const betTemplate = checkSuspendedBet([], Constants.LSKEY_US);
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_US,
          keyValue: betTemplate,
          action: 'set',
        });
        return {
          ...prevState,
          betTemplate: betTemplate,
          selectionId: [],
          errorObj: {
            isError: false,
            message: '',
          },
        };
      }
    } else {
      return prevState;
    }
  }

  /**
  componentDidUpdate lifecycle method which is call when state change
  @param prevProps Object which represent previous property of component
  logic of emit selectionids to event app and market app 
*/
  componentDidUpdate(prevProps) {
    if (this.props.betTemplate !== prevProps.betTemplate) {
      /* mannage selection id , extra params */
      const localData = setterGetterFromLocalstorage({
        keyName: Constants.LSKEY_US,
        action: 'get',
      });
      if (localData && localData.length > 0) {
        const { bsid } = getSelectionIds(localData);
        PubSub.emit(PubsubEvents.BET_COUNTS, CalCount(localData));
        PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, bsid);
      } else {
        PubSub.emit(PubsubEvents.BET_COUNTS, 0);
      }
      betTemplateUpdate = false;

      const multiplesTabEnabled = this.props.betTemplate.some(
        bet => bet.multiple && bet.betModifierBetCount === 1
      );

      if (multiplesTabEnabled && this.state.actTab !== Constants.MULTIPLE) {
        this.setState({ actTab: Constants.MULTIPLE });
      }

      const allSingleBet = this.props.betTemplate.every(
        bet => bet.betTypeRef === Constants.SGL
      );

      if (allSingleBet && this.state.actTab !== Constants.SINGLE) {
        this.setState({ actTab: Constants.SINGLE });
      }
    }
    if (this.props.builderData !== prevProps.builderData) {
      PubSub.emit(PubsubEvents.BUILDER_BET_UPDATE, {});
    }

    // ordering of bet on system  bets
    if (this.state.actTab === Constants.SYSTEM && !betTemplateUpdate) {
      // finding index of straight acca bet
      const findAcc = this.state.betTemplate.findIndex(
        bet => bet.multiple && bet.betModifierBetCount === 1
      );
      if (findAcc !== -1) {
        // removing and storing the straight acca bet from bet array
        const unshiftAcc = this.state.betTemplate.splice(findAcc, 1);
        // finding the last single bet
        const findSingleIndex = this.state.betTemplate.findLastIndex(
          bets => bets.betTypeRef === Constants.SGL
        );

        if (findSingleIndex !== -1) {
          betTemplateUpdate = true;
          // adding the straight acca bet into the bet array after the single bet as per the ordering
          this.state.betTemplate.splice(findSingleIndex + 1, 0, ...unshiftAcc);
          this.setState({
            betTemplate: this.state.betTemplate,
          });
        }
      }
    }
  }

  /** 
  handleing active Tabs 
  @param type string which contain [single,multiple,system]  
  */
  handleActTab = (type, shouldDisplay) => {
    if (shouldDisplay) {
      this.setState({ actTab: type });
    }
  };

  /**
    handleDelete for handling bet delete
    also it handle for Localstorage 
    @param data object contain    
      @param data['deleteBet'] boolen 
      @param data['selectionId'] number
   */
  handleDelete = data => {
    if (data?.type === GLOBAL_CONSTANTS.DELETESUSPENDED) {
      const updateBets = removeFromLocalStorageDeleteSuspended(
        'deletesuspended',
        Constants.LSKEY_US
      );
      const selectionId = new Set();
      if (Array.isArray(updateBets)) {
        for (const item of updateBets) {
          if (item.selectionId) selectionId.add(item.selectionId);
        }
      }

      const data = { betTemplate: updateBets };
      this.props.cleanBets({ type: 'STORE_DATA', data: data });
      this.setState(
        {
          selectionId: [...selectionId],
        },

        () => {
          if (
            Array.isArray(this.state.selectionId) &&
            this.state.selectionId.length > 0
          ) {
            // Recall template Api
            this.callBetTemplateApi(
              this.state.selectionId.filter(item => item)
            );
          }
        }
      );
    } else if (data?.deleteBet && data?.betTypeRef === Constants.BBB) {
      const { builderParams, betTemplate, builderData } = this.state;
      // remove data from builder param
      builderParams.splice(builderParams.indexOf(data['selectionId']), 1);
      betTemplate.map((bets, key) => {
        if (
          data['selectionId'] &&
          bets['selectionId'] === data['selectionId']
        ) {
          betTemplate.splice(key, 1);
        }
      });
      // remove data from builderData array
      builderData.map((bets, key) => {
        if (
          data['selectionId'] &&
          bets &&
          bets['selectionId'] === data['selectionId']
        ) {
          builderData.splice(key, 1);
        }
      });
      // set data in store
      const data2 = {};
      data2['betTemplate'] = betTemplate;
      data2['dataB'] = builderData;
      this.props.cleanBets({ type: 'STORE_CLEAR_BETSLIP', data: data2 });
      if (builderParams.length > 0) {
        // when builder bet is more then 1
        this.betbulderApiCall(builderParams);
      } else {
        // when builder bet is 0
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_US,
          keyValue: betTemplate,
          action: 'set',
        });
        setterGetterFromLocalstorage({
          keyName: Constants.BUILDERUSEDATA,
          keyValue: builderData,
          action: 'set',
        });

        PubSub.emit(PubsubEvents.BUILDER_BET_UPDATE, {});
      }
    } else if (data?.type === Constants.DELETEALL) {
      /*  handle for last recoed
      no api hit ,clear localstorage */
      setterGetterFromLocalstorage({
        keyName: Constants.LSKEY_US,
        action: 'set',
        keyValue: [],
      });
      setterGetterFromLocalstorage({
        keyName: Constants.BUILDERUSEDATA,
        action: 'set',
        keyValue: [],
      });
      setterGetterFromLocalstorage({
        keyName: Constants.BETSLIPUSTEASER,
        action: 'set',
        keyValue: [],
      });
      this.setState({
        selectionId: [],
      });
      this.props.cleanBets({ type: 'STORE_CLEAR_BETSLIP', data: [] });
      PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
      this.forceUpdate();
    } else {
      const { selectionId } = this.state;
      // remove data from selectionId
      selectionId.splice(selectionId.indexOf(data['selectionId']), 1);
      this.setState(
        {
          selectionId,
        },
        () => {
          const betTemplate = removeFromLocalStorage(data, Constants.LSKEY_US);
          // set data in State
          this.props.cleanBets({
            type: 'STORE_CLEAR_BETSLIP',
            data: { betTemplate: betTemplate },
          });
          if (Array.isArray(selectionId) && selectionId.length > 0)
            this.callBetTemplateApi(selectionId, true);
          else PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
        }
      );
    }
  };

  /**
   * Bet template API call
   * @param selectionId
   */
  callBetTemplateApi = (selectionId, isDelete = false) => {
    if (Array.isArray(selectionId) && selectionId.length > 0)
      this.props.fetchData(
        this.props.url['betTemplate'] + '?br=[' + selectionId + ']',
        null,
        this.props.appConfig,
        {},
        selectionId,
        isDelete
      );
  };

  /**
   * genrate Selection Id Array
   * @param wsData ws socket Object
   */
  genrateSelectionIdArr = wsData => {
    let { selectionId } = this.state;
    if (selectionId.includes(wsData?.selectionId)) {
      // when selection comes twice from event/market app
      selectionId.splice(selectionId.indexOf(wsData?.selectionId), 1);
      this.props.cleanBets({
        type: 'STORE_DATA',
        data: {
          betTemplate: removeFromLocalStorage(
            { selectionId: wsData?.selectionId },
            Constants.LSKEY_US
          ),
        },
      });
    } else {
      // when selection comes first time from event/market app
      selectionId = [...selectionId, wsData?.selectionId];

      // posting data in GTM when new selection added
      if (Array.isArray(selectionId) && selectionId)
        addCustomGTMEventToDataLayer({
          event: gtmEventNames.BET_ADD_NEW,
          data: {
            product: this.props.appConfig?.requestURL
              .split('/')
              .filter(v => v)[0],
            selectionId: selectionId,
            location: this.props.appConfig?.requestURL,
          },
        });
    }
    return selectionId;
  };
  /**
    callSetUserBetInLocalStorage for seting user bet data in localstorage 
    @param data object contain    
      @param data['userStake'] number  
      @param data['totalStake'] number
      @param data['_returns'] number
      @param data['returnsText'] number
      @param data['_returnsFormatted'] number
      @param data['lastModified'] date
      @param data['priceDecimal'] number
      @param data['priceFractional'] number
      @param data['bbFlag'] boolen
      @param data['hasFreebet'] boolen
      @param data['selectedFreebetId'] number
      @param data['freebetCredit'] number
      @param data['freebetCreditFormatted'] number
      @param data['freebetRestrictions'] number
   */
  callSetUserBetInLocalStorage = data => {
    if (data && data['hasFreebet']) {
      // validate free Bet
      validateFreeBet(data, Constants.LSKEY_US);
    }
    if (data && data['bbFlag']) {
      // validate bet boosters
      validateBetBooster(data, Constants.LSKEY_US);
    }
    const betSlipData = setUserBetInlocalStorage(data, Constants.LSKEY_US);
    this.setState(
      {
        betTemplate: betSlipData,
      },
      () => {
        this.props.userBetInformation(data);
      }
    );
  };

  /**
   * handlePlacebet
   * @param {}
   * Posting data to Place Bet api
   */
  handlePlaceBet = async (type = null) => {
    const { betTemplate, betTemplateTeaser } = this.state;
    if (type === 'login') {
      PubSub.emit(PubsubEvents.CallLoginPopUp, {
        hitting: true,
      });
      return;
    }

    const UserAuthData = FSBCustomerSession.getSession();

    if (type && type === GLOBAL_CONSTANTS.DEPOSIT) {
      window.location.replace(accountRedirectUrls.deposit);
    } else if (type && type === GLOBAL_CONSTANTS.LOWBALANCE) {
      const { betTemplate } = this.state;
      const calc = genrateSlips(betTemplate, this.state.tax);
      PubSub.emit(PubsubEvents.OPE_QD, {
        hitting: true,
        fundReq: calc['totalStake'] - UserAuthData.balance.balance,
      });
    } else if (
      this.props.appConfig.promptidcomply &&
      !UserAuthData?.kycApproved
    ) {
      // for PP idcomply
      PubSub.emit(PubsubEvents.OPEN_ID_COMPLY, UserAuthData);
    } else
      this.setState(
        {
          postApiCall: true, // start Loader and disable PlaceBet button
        },
        async () => {
          if (await this.validatePrices()) {
            // validate prices before calling place bet
            const betsArray = placingBetUser(betTemplate, betTemplateTeaser);
            const postObj = {
              br: JSON.stringify(betsArray),
              state: 'Pending',
            };
            this.props.postData(this.props.url['betPost'], postObj);
          }
        }
      );
  };

  /**
   * Validate Selection Prices
   * @param {}
   * fetch updated prices and validate is prices id
   */
  validatePrices = async () => {
    const { selectionId } = this.state;

    if (Array.isArray(selectionId) && selectionId.length === 0) return true; //for BBB

    // fetch update template data
    const { betTemplate: updateBetTemplate } = await validateSelectionPrice(
      this.props.url['betTemplate'] + '?br=[' + selectionId + ']',
      this.props.appConfig,
      selectionId
    );

    if (Object.keys(updateBetTemplate).length === 0) return true; // if market suspended

    //merge new data with existing data
    const updateTemplate = mergeDataWithLocalStorage(
      updateBetTemplate,
      Constants.LSKEY_US
    );
    // identify difference in Price
    const priceChangeBetsArr = updateTemplate.filter(
      bet => bet.priceChangeNotification
    );

    if (Array.isArray(priceChangeBetsArr) && priceChangeBetsArr.length > 0) {
      //update betTemplate and stop place bet
      this.setState(
        {
          betTemplate: updateTemplate,
          postApiCall: false, // disbale loader & accept odd button
        },
        () => {
          setterGetterFromLocalstorage({
            keyName: Constants.LSKEY_US,
            keyValue: updateTemplate,
            action: 'set',
          });
        }
      );

      return false;
    } else {
      // call placebet ,
      return true;
    }
  };
  /**
   * setErrorObj when error get from post api
   * @param data object content isError , message
   */
  setErrorObj = data => {
    this.setState({ errorObj: data }, () => {
      this.props.cleanBets({ type: 'POST_STORE_DATA', data: [] });
    });
  };

  /**
   * when user click on Close button on recept
   * @param {boolean} retainOtp
   */
  handleReceptCloseButton = retainOtp => {
    if (!retainOtp) {
      setterGetterFromLocalstorage({
        keyName: Constants.LSKEY_US,
        keyValue: [],
        action: 'set',
      });
      this.handleDelete({ type: 'deleteall' });
      PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
    } else {
      // if retain option is true then remove userbets
      const { betTemplate, builderData, betTemplateTeaser } = this.state;

      for (const bet of betTemplate) {
        retainCleanupBet(bet);
      }
      for (const bet of builderData) {
        retainCleanupBet(bet);
      }
      if (betTemplateTeaser.wager) {
        betTemplateTeaser.wager = '';
        betTemplateTeaser.value = '';
        betTemplateTeaser.label = '';
      }
      setterGetterFromLocalstorage({
        keyName: Constants.LSKEY_US,
        keyValue: betTemplate,
        action: 'set',
      });
      setterGetterFromLocalstorage({
        keyName: Constants.BUILDERUSEDATA,
        action: 'set',
        keyValue: [],
      });
      this.setState(
        {
          betTemplate: betTemplate,
        },
        () => {
          //on retain clear builder user stake

          setterGetterFromLocalstorage({
            keyName: Constants.BETSLIPUSTEASER,
            action: 'set',
            keyValue: [],
          });

          const data = {};
          data['betTemplate'] = betTemplate;
          data['builderData'] = builderData;
          data['betTemplateTeaser'] = betTemplateTeaser;
          this.props.cleanBets({ type: 'STORE_DATA', data: data });
        }
      );
    }
    this.props.cleanBets({ type: 'POST_STORE_DATA', data: [] });

    this.setState({
      useBet: {},
      betSlipRecipt: {},
      actTab: Constants.SINGLE,
    });
  };

  /**
   * handle WS accept and decline
   */
  handleWsAcceptDecline = (type, bet) => {
    if (type === GLOBAL_CONSTANTS.ACCEPT) {
      // update localstorage according to new prices which is come to Web socket
      if (bet) {
        // when user click accept all button from summary section
        let retAmt = '';
        let totalAmt = '';

        if (bet?.betModifierBetCount && bet.priceDecimal) {
          totalAmt = bet.betModifierBetCount * parseFloat(bet.userStake);

          if (bet.multiple) {
            retAmt = totalAmt * bet.priceDecimal;
          } else {
            const price = bet.price[0];
            bet.priceDecimal = price.decimal;
            retAmt = totalAmt * bet.priceDecimal;
          }
        }

        bet['priceChangeNotification'] = false;
        bet['updateNewPrice'] = true;
        bet['totalStake'] = totalAmt;
        bet['_returns'] = isNaN(retAmt) ? Constants.N_A : retAmt;
        bet['returnsText'] = isNaN(retAmt) ? Constants.N_A : retAmt;
        bet['_returnsFormatted'] = isNaN(retAmt) ? Constants.N_A : retAmt;
        bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
        this.callSetUserBetInLocalStorage(bet);
      } else {
        // when user click accept all button from summary section
        const { betTemplate } = this.state;
        for (const bet of betTemplate) {
          let retAmt = '';
          let totalAmt = '';

          if (bet?.betModifierBetCount) {
            totalAmt = bet.betModifierBetCount * parseFloat(bet.userStake);
            retAmt = totalAmt * bet.priceDecimal;
          }

          bet['priceChangeNotification'] = false;
          bet['totalStake'] = totalAmt;
          bet['updateNewPrice'] = true;
          bet['_returns'] = isNaN(retAmt) ? Constants.N_A : retAmt;
          bet['returnsText'] = isNaN(retAmt) ? Constants.N_A : retAmt;
          bet['_returnsFormatted'] = isNaN(retAmt) ? Constants.N_A : retAmt;
          bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
          this.callSetUserBetInLocalStorage(bet);
        }
      }

      let selectionId = new Array();
      selectionId = [...this.state.selectionId];
      selectionId = selectionId.filter(item => item);
      // call bettemplate API
      this.callBetTemplateApi(selectionId);
    } else if (type === GLOBAL_CONSTANTS.DECLINE) {
      this.handleDelete({ deleteBet: true, selectionId: bet.selectionId });
    }
  };

  /**
   *  handle User specific notification in BS
   * @param {string} type "default | none | lower"
   */

  handleActTabNotificationPopup = (type, apiCall = true) => {
    this.setState(
      {
        notificationParam: type,
      },
      () => {
        // set odd moment in LS
        setterGetterFromLocalstorage({
          keyName: Constants.ODDMOMENT,
          keyValue: type,
          action: 'set',
        });
        if (apiCall) {
          this.props.postUserOddPreferences(
            this.props.url['priceAcceptance'],
            type
          );
        }
      }
    );
  };
  /**
   * handle call of bet builder api async.
   * @param {array} params
   */
  betbulderApiCall = async params => {
    // if user is already login
    const UserAuthData = FSBCustomerSession.getSession();

    let text = '?eventId=';

    if (UserAuthData && UserAuthData?.['accessToken']) {
      text = '?access_token=' + UserAuthData['accessToken'] + '&eventId=';
    }

    for (let i = 0; i < params.length; i++) {
      setTimeout(async () => {
        await this.props.postDataAsync(
          this.props.url['betBuilder'] + text + params[i]
        );
      }, 500);
    }
  };
  /**
   * handle Toggle in Mobile
   * @param {bool} type
   */
  handleOpenBS = type => {
    this.setState(
      {
        openBS: type,
      },
      () => {
        if (type) {
          document.querySelector('.main-right').classList.remove('mr-inactive');
          document.querySelector('.main-right').classList.add('mr-active');
          document.querySelector('.betslip').style.height = '100%';
        } else {
          document.querySelector('.main-right').classList.remove('mr-active');
          document.querySelector('.betslip').style.height = '0px';
        }
        PubSub.emit(PubsubEvents.BETSLIP_EXPAND, type);
      }
    );
  };

  // call UserAuth
  userAuthStuff = UserAuthData => {
    if (UserAuthData && UserAuthData['accessToken']) {
      this.setState(
        {
          isLogin: true,
          userAuthData: UserAuthData,
        },
        () => {
          if (
            Array.isArray(this.state.selectionId) &&
            this.state.selectionId.length > 0
          )
            this.callBetTemplateApi(this.state.selectionId);

          this.handleActTabNotificationPopup(
            (UserAuthData?.['platformConfig']?.['value'] &&
              notificationValues(UserAuthData['platformConfig']['value'])) ||
              Constants.BLOCK,
            false
          );
        }
      );
    } else {
      this.handleActTabNotificationPopup(Constants.BLOCK, false);
    }
  };

  /**
   * handle teaser Data
   * @param data object
   */
  handleTeaserBets = teaseData => {
    this.setState({ betTemplateTeaser: teaseData }, () => {
      catchTeaserData(teaseData);
      this.props.cleanBets({
        type: 'STORE_DATA',
        betTemplateTeaser: teaseData,
        data: { betTemplate: this.state.betTemplate },
      });
    });
  };

  render() {
    if (!this.state.render) {
      return <React.Fragment />;
    }

    const { openBS } = this.state;
    const bottomNavLinks = this.state.isLogin
      ? this.state.bottomNavData?.loginLinks
      : this.state.bottomNavData?.logoutLinks;
    const isBetslipInBottomNav =
      bottomNavLinks?.findIndex(link => link === GLOBAL_CONSTANTS.BETSLIP) > 0;
    const adjustBottomHeight = bottomNavLinks && !isBetslipInBottomNav;

    if (
      Object.keys(this.props.apiRes).length > 0 &&
      Object.keys(this.state.betTemplate).length > 0
    ) {
      const betSlipRecipt =
        this.state.betSlipRecipt && this.state.betSlipRecipt;
      let suspended = false;
      let betTemplate = this.state.betTemplate && [...this.state.betTemplate];
      const errorObj = this.state.errorObj;
      if (betTemplate && betTemplate.length === 0) {
        betTemplate = checkSuspendedBet(betTemplate, Constants.LSKEY_US);
      }
      for (const bet of betTemplate) {
        if (bet.active === false) {
          suspended = true;
        }
      }
      let UserAuthData = FSBCustomerSession.getSession();
      this.sessionCreatedPubsub = PubSub.listen('session:created', () => {
        UserAuthData = FSBCustomerSession.getSession();
      });
      this.sessionUpdatedPubsub = PubSub.listen('session:updated', () => {
        UserAuthData = FSBCustomerSession.getSession();
      });
      /* HORSES and DOG bet casting for US layout */
      betTemplate = genrateCastingForUS(
        betTemplate,
        this.props?.appConfig?.displaymultiplesfirst || false
      );

      if (this.state.builderParams.length > 0) {
        betTemplate = handleSortCreateBet(betTemplate);
      }
      /* what properties you need to pass just put in Object */
      const properties = {
        betTemplate: betTemplate,
        handleActTab: this.handleActTab,
        actTab: this.state.actTab,
        handleDelete: this.handleDelete,
        callSetUserBetInLocalStorage: this.callSetUserBetInLocalStorage,
        suspended: suspended,
        handlePlaceBet: this.handlePlaceBet,
        isLogin: this.state.isLogin,
        UserAuthData: UserAuthData,
        region: this.props.appConfig.region,
        isError: errorObj.isError,
        tax: this.props.appConfig.tax,
        handleWsAcceptDecline: this.handleWsAcceptDecline,
        oddsFormat: this.state.oddsFormat,
        postApiCall: this.state.postApiCall,
        taxbycountry: this.props.appConfig.taxbycountry,
        boosterinfoextended: this.props?.appConfig?.boosterinfoextended,
        hidenextbetbonus: this.props?.appConfig?.hidenextbetbonus?.split(','),
        handleActTabNotificationPopup: this.handleActTabNotificationPopup,
        showOddMovements: this.props?.appConfig?.showoddmovements || false,
        card: this.state.card,
        notificationParam: this.state.notificationParam,
        openBS: openBS,
        handleOpenBS: this.handleOpenBS,
        betTemplateTeaser: this.state.betTemplateTeaser,
        handleTeaserBets: this.handleTeaserBets,
        customOddsComponent:
          this.props?.appConfig?.customoddscomponent || false,
        topdeletebetslip: this.props.appConfig?.topdeletebetslip,
        bottomNavData: this.state.bottomNavData,
        selectionId: this.state.selectionId,
      };

      if (betSlipRecipt && Object.keys(betSlipRecipt).length > 0) {
        return (
          <FSBTheme theme={this.state.theme}>
            <B
              openBS={openBS}
              adjustBottomHeight={adjustBottomHeight}
              betslipExpand={this.state.betslipExpand}
            >
              <ReceiptContainer
                betSlipRecipt={betSlipRecipt}
                handleReceptCloseButton={this.handleReceptCloseButton}
                oddsFormat={this.state.oddsFormat}
                appConfig={this.props.appConfig}
                bottomNavData={this.state.bottomNavData}
              />
            </B>
          </FSBTheme>
        );
      } else {
        return (
          <FSBTheme theme={this.state.theme}>
            <B
              openBS={openBS}
              adjustBottomHeight={adjustBottomHeight}
              betslipExpand={this.state.betslipExpand}
            >
              <BetslipContainer
                errorObj={errorObj}
                betTemplate={this.state.betTemplate}
                appConfig={this.props?.appConfig}
                properties={properties}
                UserAuthData={UserAuthData}
                setErrorObj={this.setErrorObj}
                countAccaBet={this.props?.countAccaBet}
                hiddenTabs={this.props?.appConfig?.hiddentabs?.split(',')}
                notificationParam={this.state.notificationParam}
              />
            </B>
          </FSBTheme>
        );
      }
    } else {
      return (
        <FSBTheme theme={this.state.theme}>
          <B
            openBS={openBS}
            adjustBottomHeight={adjustBottomHeight}
            betslipExpand={this.state.betslipExpand}
          >
            <BS>
              <Header
                betTemplate={[]}
                isLogin={this.state.isLogin}
                handleOpenBS={this.handleOpenBS}
                openBS={openBS}
              />
              <HideOnMobile openBS={openBS}>
                <EmptyBetslip Translations={Translations} />
              </HideOnMobile>
            </BS>
          </B>
        </FSBTheme>
      );
    }
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    userBetInformation: bet => {
      dispatch(userBetInfo(bet));
    },
    cleanBets: betObj => {
      dispatch(betObj);
    },
    poststoreData: data => {
      dispatch(poststoreData(data));
    },
  };
};

BetslipReact.propTypes = {
  apiRes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  placedBet: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  betTemplate: PropTypes.array,
  builderData: PropTypes.array,
  useBet: PropTypes.object,
  url: PropTypes.object,
  fetchData: PropTypes.func,
  postData: PropTypes.func,
  userBetInformation: PropTypes.func,
  cleanBets: PropTypes.func,
  oddsFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  appConfig: PropTypes.object,
  postDataAsync: PropTypes.func,
  betTemplateTeaser: PropTypes.object,
  postUserOddPreferences: PropTypes.func,
  countAccaBet: PropTypes.number,
  isDelete: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(BetslipReact);
