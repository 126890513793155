import React from 'react';
import { Receipt } from './BetslipReceipt';
import { BS } from 'UI/apps/BetslipSAApp/ReceiptContainer/ReceiptContainer';
import PropTypes from 'prop-types';

/**
 * container of Receipt
 * @param {object} betSlipRecipt recipt object come to api
 * @param {func} handleReceptCloseButton
 * @param {string} oddsFormat
 */
export const ReceiptContainer = ({
  betSlipRecipt,
  handleReceptCloseButton,
  oddsFormat,
  appConfig,
  bottomNavData,
}) => {
  return (
    <BS id="bet-box">
      <Receipt
        betSlipRecipt={betSlipRecipt}
        handleReceptCloseButton={handleReceptCloseButton}
        oddsFormat={oddsFormat}
        appConfig={appConfig}
        bottomNavData={bottomNavData}
      />
    </BS>
  );
};
ReceiptContainer.propTypes = {
  betSlipRecipt: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  oddsFormat: PropTypes.string,
  appConfig: PropTypes.object,
  bottomNavData: PropTypes.object,
};
