import {
  MSSDNF,
  M3,
  M,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__notification';
import { FONT } from 'UI/globals/colours';
import styled, { css } from 'styled-components';

const M3_UI = styled(M3)`
  ${({ showStakePopup }) =>
    showStakePopup &&
    css`
      background: ${FONT.primary};
      text-align: center;
      color: ${FONT.light};
    `};
  word-break: break-word;
`;

export { MSSDNF, M3_UI as M3, M };
