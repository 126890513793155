import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

export const BRB = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${GREYS.dove};
  padding: 8px 16px;
  font-weight: bold;
  border: 1px solid ${GREYS.dove};
  z-index: 10;
`;

export const BRBT = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const BRBBC = styled('div')`
  display: flex;
`;

export const BRS = styled('span')`
  display: flex;
`;
