import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const CBLB = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: ${GREYS.black};
  font-size: 24px;
  margin-left: 16px;
  font-family: ${theme.fontFamily}
  font-weight: 400;
  cursor: pointer;
  padding: 0px 0px 4px 0;
  font-weight: 100;
`;
