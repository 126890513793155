import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TopSection, BottomSection } from './elements';
import { Constants } from '../../../../core__betslip-constants';
import { genrateSlips } from '../../../../core__betslip-utils';
import { TaxBottomSection } from './elements/core__taxBottomSection';
import { TopContainer as TopContainer_LOCAL } from 'UI/apps/BetslipSAApp/BetslipContainer/TopSection';
import { TopContainer as TopContainer_CORE } from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__topSection';
import { getComponent } from 'Services/core__imports';

const TopContainer = getComponent(TopContainer_LOCAL, TopContainer_CORE);

/* that for generated summary calculation   */
/**
 @param {array} betTemplate
 @param {func} handleDelete
 @param {bool} suspended
 @param {func} handlePlaceBet
 @param {bool} isLogin
 @param {object} UserAuthData
 @param {string} region
 @param {bool} isError
 @param {number} tax
 @param {func} handleWsAcceptDecline
 @param {func} postApiCall
 @param {string} taxbycountry
 */

export const Summary = ({
  betTemplate,
  handleDelete,
  suspended,
  handlePlaceBet,
  isLogin,
  UserAuthData,
  region,
  isError,
  tax,
  handleWsAcceptDecline,
  postApiCall,
  taxbycountry,
  card,
  betTemplateTeaser,
  currencySymbol,
  appConfig,
  handleBetBoostTracking,
  bottomNavData,
}) => {
  const [freebetStake, setfreebetStake] = useState({
    freeStake: Constants.DEFAULT_AMT,
    hasFreebet: false,
  });
  const [totalStake, setTotalStake] = useState(Constants.DEFAULT_AMT);
  const [potentialReturns, setPotentialReturns] = useState(
    Constants.DEFAULT_AMT
  );
  const [fee, setFee] = useState(Constants.DEFAULT_AMT);
  const [netReturn, setnetReturn] = useState(Constants.DEFAULT_AMT);
  const [checkChanges, handleCheckChanges] = useState(false);
  const [SB, setSB] = useState(false);
  const [bonusStake, setBonusStake] = useState({});

  const [openTaxSection, setOpenTaxSection] = useState(false);

  //  check if tax is not avalable so find value to tabycountry
  if ((!tax || tax === undefined) && taxbycountry) {
    const countryTaxArr = taxbycountry.split('-');
    if (countryTaxArr.length > 0 && countryTaxArr[1]) {
      tax = countryTaxArr[1];
    }
  }

  const betCategoriesWithTax = Constants.TAXED_CATEGORIES;
  // Variable from appConfig, so only a specific brand can use it (Boolean value)
  const appConfigBetCategoryTax = appConfig?.hasbetcategorieswithtax;
  const hasBetCategoriesWithTax =
    appConfigBetCategoryTax &&
    betTemplate.some(bet => {
      if (bet.catRef === Constants.VIRTUAL) {
        // when virtual is selected then considering the sub category ref
        return betCategoriesWithTax.includes(bet.subcatRef);
      }
      return betCategoriesWithTax.includes(bet.catRef);
    });

  useEffect(() => {
    const calc = genrateSlips(betTemplate, tax, betTemplateTeaser);
    setTotalStake(calc['totalStake']);
    setPotentialReturns(calc['potentialReturns']);
    setfreebetStake(calc['freeStakeData']);
    setFee(calc['fee']);
    setnetReturn(calc['netReturn']);
    setSB(calc['bonusFund']);

    setBonusStake(calc?.betBonusData);

    // check odds change
    let nf = false;
    for (const bet of betTemplate) {
      if (bet.priceChangeNotification) {
        nf = true;
      }
    }
    handleCheckChanges(nf);
  }, [betTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <TopContainer>
        {hasBetCategoriesWithTax && openTaxSection && (
          <TaxBottomSection
            tax={tax}
            netReturn={netReturn}
            fee={fee}
            currencySymbol={currencySymbol}
            potentialReturns={potentialReturns}
            closeTaxSection={() => setOpenTaxSection(!openTaxSection)}
          />
        )}
        <TopSection
          handleDelete={handleDelete}
          suspended={suspended}
          region={region}
          checkChanges={checkChanges}
          appConfig={appConfig}
        />
      </TopContainer>
      <BottomSection
        currencySymbol={UserAuthData?.currencyCode || null}
        handleDelete={handleDelete}
        potentialReturns={potentialReturns}
        totalStake={totalStake}
        suspended={suspended}
        handlePlaceBet={handlePlaceBet}
        isLogin={isLogin}
        freebetStake={freebetStake}
        isError={isError}
        netReturn={netReturn}
        fee={fee}
        tax={tax}
        checkChanges={checkChanges}
        handleWsAcceptDecline={handleWsAcceptDecline}
        postApiCall={postApiCall}
        balance={UserAuthData?.balance?.balance || 0}
        userbalance={UserAuthData?.balance || null}
        card={card}
        SB={SB}
        betTemplate={betTemplate}
        bonusStake={bonusStake}
        openTaxSection={() => setOpenTaxSection(!openTaxSection)}
        showTaxComponentIcon={hasBetCategoriesWithTax}
        isOddsAcceptance={appConfig?.isoddsacceptance}
        withdrawBalance={UserAuthData?.balance?.withdrawableBalance}
        betBoostsAvailable={UserAuthData?.raw?.availableBetBoostTokens}
        handleBetBoostTracking={handleBetBoostTracking}
        bottomNavData={bottomNavData}
      />
    </React.Fragment>
  );
};

Summary.propTypes = {
  betTemplate: PropTypes.array,
  handleDelete: PropTypes.func,
  handlePlaceBet: PropTypes.func,
  suspended: PropTypes.bool,
  isLogin: PropTypes.bool,
  UserAuthData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  region: PropTypes.string,
  isError: PropTypes.bool,
  tax: PropTypes.number,
  handleWsAcceptDecline: PropTypes.func,
  postApiCall: PropTypes.bool,
  taxbycountry: PropTypes.string,
  card: PropTypes.bool,
  betTemplateTeaser: PropTypes.object,
  currencySymbol: PropTypes.string,
  appConfig: PropTypes.object,
  handleBetBoostTracking: PropTypes.func,
  bottomNavData: PropTypes.object,
};
