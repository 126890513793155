import React from 'react';
import { MSSDNF, M3 } from 'UI/apps/BetslipSAApp/BetslipContainer/Notification';
import { Translations } from '../../../../../core__betslip-SA-app';
import PropTypes from 'prop-types';

export const NotificationComponent = ({
  showStakePopup,
  oddsBoostExceeded,
}) => {
  return (
    <React.Fragment>
      <MSSDNF>
        {oddsBoostExceeded ? (
          <M3 showStakePopup={showStakePopup}>
            {Translations.get('text.insufficient.odds.boosts')}
          </M3>
        ) : (
          <M3 showStakePopup={showStakePopup}>
            {' '}
            {showStakePopup
              ? Translations.get('text.review.stake')
              : Translations.get('text.odd.change.review')}
          </M3>
        )}
      </MSSDNF>
    </React.Fragment>
  );
};

NotificationComponent.propTypes = {
  showStakePopup: PropTypes.bool,
  oddsBoostExceeded: PropTypes.bool,
};
