import React from 'react';
import PropTypes from 'prop-types';
import { BBS as BBS_LOCAL } from 'UI/apps/BetslipSAApp/BetslipContainer/Text';
import { BBS as BBS_CORE } from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__text';

const BBS = BBS_LOCAL ? BBS_LOCAL : BBS_CORE;

/** 
genrate builder strings:
split by (/) and print the description

@param {*} text (this is coming via api bets.description and data is | seprated)
@param {bool} active 
*/

export const Text = ({ text, active }) => {
  const TextArr = text && text.split('/');

  return (
    <ul>
      {TextArr.map((txt, key) => {
        return (
          <li key={key}>
            <BBS active={active}>{txt}</BBS>
          </li>
        );
      })}
    </ul>
  );
};

Text.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
};
