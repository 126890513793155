import {
  SummaryTopSectionButton,
  M,
  TopContainer,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__topSection';
import styled, { css } from 'styled-components';
import { BK_COLORS, FONT, GREYS } from 'UI/globals/colours';

const SummaryTopSectionButton_EX = styled(SummaryTopSectionButton)`
  background: ${GREYS.white};
  border-color: ${BK_COLORS.aliceBlue10};
  ${({ hideClearBetslip }) =>
    hideClearBetslip &&
    css`
      display: none;
    `};
`;

const M_EX = styled(M)`
  background: transparent;
  color: ${FONT.black.standard};
  align-items: center;
  path {
    fill: ${FONT.black.standard};
  }
  ${({ odds }) =>
    odds &&
    css`
      background: ${BK_COLORS.blue};
      color: ${FONT.white.standard};
      line-height: 18px;
    `};
  ${({ showTop }) =>
    showTop &&
    css`
      cursor: pointer;
    `};
`;

export {
  TopContainer,
  SummaryTopSectionButton_EX as SummaryTopSectionButton,
  M_EX as M,
};
