import styled from 'styled-components';

export const OddsChangeWrapper = styled.div`
  padding: 10px 16px;
`;

export const OddsChangeTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
`;

export const OddsChangeContainer = styled.div`
  display: flex;
  align-items: center;
`;
