import React from 'react';
import PropTypes from 'prop-types';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import {
  Link,
  LinkHeader,
  LinkDescription,
  Button,
} from 'UI/globals/EmptyBetslip.styles';

const Feature = ({ iconName, title, description, href, buttonLabel }) => (
  <Link href={href} target="_blank" rel="noreferrer">
    <div>
      <LinkHeader>
        <I iconName={iconName} marginR={10} size={20} /> {title}
      </LinkHeader>
      <LinkDescription>{description}</LinkDescription>
    </div>
    {buttonLabel ? (
      <Button label={buttonLabel} rank="secondary" />
    ) : (
      <I iconName={getIcon('OPEN')} size={16} />
    )}
  </Link>
);

Feature.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  buttonLabel: PropTypes.string,
};

export default Feature;
