import {
  B,
  H,
  PC,
} from 'CORE__UI/apps/BetslipSAApp/ReceiptContainer/core__header';
import styled from 'styled-components';
import { BRAND } from 'UI/globals/colours';

const B_UI = styled(B)`
  border-top: none;
  margin-top: 12px;
`;

const H_UI = styled(H)`
  border-top: 1px solid ${BRAND.tertiary};
  margin-top: 8px;
`;
const PC_UI = styled(PC)`
  border-top: none;
  margin-top: 8px;
`;

export { B_UI as B, H_UI as H, PC_UI as PC };
