import {
  BSI,
  BSM,
  BAMY,
  BA,
  BS,
  BHLF,
  BHL,
  BHU,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__head';
import styled from 'styled-components';
import { BK_COLORS, FONT, BACKGROUND } from 'UI/globals/colours';
import { BREAKPOINT_L, BREAKPOINT_S } from 'UI/globals/breakpoints';

const BHU_EX = styled(BHU)`
  background: ${BACKGROUND.background};
  border-color: ${BK_COLORS.aliceBlue10};
  padding: 12px 16px;
  align-items: center;
  @media (max-width: ${BREAKPOINT_L}) {
    background: ${BK_COLORS.blue};
    & li:last-of-type span:first-child {
      color: ${FONT.light};
    }
    & li:last-of-type span:last-child {
      color: ${BK_COLORS.yellow};
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    height: 40px;
  }
`;

const BA_EX = styled(BA)`
  color: ${FONT.black.standard};
  border-bottom: none;
  padding-bottom: 0;
  line-height: 20px;
  @media (max-width: ${BREAKPOINT_L}) {
    color: ${FONT.light};
  }
`;

const BS_EX = styled(BS)`
  top: 2px;
  right: -20px;
  width: 16px;
  height: 16px;
  background: ${BK_COLORS.red};
  color: ${FONT.white.label};
  padding: 0 1px; // two numbers appear, give them some space inline
  font-variant-numeric: proportional-nums;
`;

const BAMY_EX = styled(BAMY)`
  padding-bottom: 0;
`;

const BSM_EX = styled(BSM)`
  width: 16px;
  height: 16px;
  background: ${BK_COLORS.red};
  color: ${FONT.white.label};
  font-variant-numeric: proportional-nums;

  @media (max-width: ${BREAKPOINT_L}) {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-variant-numeric: proportional-nums;
  }
`;

const BHLF_EX = styled(BHLF)`
  @media (max-width: ${BREAKPOINT_L}) {
    &:last-child {
      display: inline-flex;
    }
  }
`;

const BSI_EX = styled(BSI)`
  border-color: ${FONT.light};
`;

export {
  BSI_EX as BSI,
  BSM_EX as BSM,
  BAMY_EX as BAMY,
  BA_EX as BA,
  BS_EX as BS,
  BHLF_EX as BHLF,
  BHL,
  BHU_EX as BHU,
};
