import {
  BU,
  BD,
  BCLC,
  BCL,
  BCSM,
  BSMD,
  BMD,
  FBPR,
  BetslipContentBetBoxPot,
  ENS,
  ERR,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__betBox';
import styled, { css } from 'styled-components';
import { BK_COLORS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const padding = css`
  padding: 0 16px;
`;

const BMD_EX = styled(BMD)`
  margin: 0;
  box-shadow: none;
  border-color: ${BK_COLORS.aliceBlue10};
`;

const BSMD_EX = styled(BSMD)`
  padding: 0 16px;
  margin: 0;
  font-size: 12px;
  text-align: center;
  ${({ showBonusText }) =>
    showBonusText &&
    css`
      padding: 8px 16px;
      background-color: ${BK_COLORS.yellow};
      color: ${FONT.black.label};
    `}
`;

const BD_EX = styled(BD)`
  ${padding};
  margin: 8px 0;
`;

const BU_EX = styled(BU)`
  ${padding};
  margin: 6px 0 10px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 4px;
  }
`;

const BCL_EX = styled(BCL)`
  background: ${BK_COLORS.red};
  color: ${FONT.white.label};
  font-weight: bold;
`;

const BCLC_EX = styled(BCLC)`
  margin-bottom: 8px;
`;

const ENS_UI = styled(ENS)`
  color: ${FONT.dark};
`;

const BCSM_UI = styled(BCSM)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin-bottom: 8px;
  }
`;

export {
  BU_EX as BU,
  BD_EX as BD,
  BCLC_EX as BCLC,
  BCL_EX as BCL,
  BCSM_UI as BCSM,
  BSMD_EX as BSMD,
  BMD_EX as BMD,
  FBPR,
  BetslipContentBetBoxPot,
  ENS_UI as ENS,
  ERR,
};
