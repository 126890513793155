import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

export const TBC = styled.div`
  padding: 16px;
  position: absolute;
  top: -172px;
  width: 100%;
  z-index: 4;
`;

export const TBS = styled.div`
  background: ${GREYS.black};
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

export const TBTC = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${GREYS.white};
`;

export const TBT = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${GREYS.white};
  margin-bottom: 15px;
`;

export const TBD = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 15px;
`;

export const TBB = styled.button`
  height: 32px;
  border: 1px solid ${GREYS.white};
  background: transparent;
  color: ${GREYS.white};
  border-radius: 4px;
  cursor: pointer;
`;
