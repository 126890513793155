import { Tabs } from './core__tabs';
import React from 'react';

const Tabs_Copy = props => (
  <Tabs
    {...props}
    shouldDisplay={['single', 'multiple', 'system']}
    multipleTranslation={'text.multi'}
  />
);

export { Tabs_Copy as Tabs };
