import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ReceiptTopSection,
  ReceiptHeader,
  Container,
  BottomSection,
} from './elements';
import {
  getBetNameForPickRoundRobinByTypeName,
  makeArrayOfRecept,
} from '../../../../core__betslip-utils';
import { Translations } from '../../../../core__betslip-SA-app';

import { SC } from 'UI/apps/BetslipSAApp/ReceiptContainer/Receipt';
/**
  Receipt main page of Bet Receipt 
  @param betSlipRecipt {object} betSlipRecipt
  @param handleReceptCloseButton {function} for closing bet receipt section

*/
export const Receipt = ({
  betSlipRecipt,
  handleReceptCloseButton,
  oddsFormat,
  appConfig,
  bottomNavData,
}) => {
  const { bet } = betSlipRecipt;
  const { potentialBetBonusPercentage, potentialBetBonusAmount } =
    bet.length && bet[bet.length - 1];
  const [ArrRecept, handleArrRecept] = useState([]);

  useEffect(() => {
    const ArrReceptTemp = makeArrayOfRecept(bet);
    handleArrRecept(ArrReceptTemp);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      {/* Receipt Top section */}
      <SC>
        <ReceiptTopSection
          betSlipRecipt={betSlipRecipt}
          handleReceptCloseButton={handleReceptCloseButton}
          betBonusPercent={potentialBetBonusPercentage}
          betBonusAmount={potentialBetBonusAmount}
        />

        {/* For single type bets */}
        {ArrRecept && ArrRecept['single'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={'Single'}
              appConfig={appConfig}
            />

            {ArrRecept['single'].map(bet => (
              <Container
                bet={bet}
                key={'Single_' + bet.id}
                oddsFormat={oddsFormat}
              />
            ))}
          </React.Fragment>
        )}

        {/* For multiple and all type bets */}
        {ArrRecept && ArrRecept['multiple'] && (
          <React.Fragment>
            {ArrRecept['multiple'].map(bet => {
              const roundRobinBetName =
                bet?.betCount > 1 &&
                getBetNameForPickRoundRobinByTypeName(
                  bet,
                  Translations.get('text.pick.round.robin.count')
                );
              return (
                <React.Fragment key={bet.betTypeName + bet.id}>
                  <ReceiptHeader
                    bet={bet}
                    oddsFormat={oddsFormat}
                    hedingName={roundRobinBetName || bet.betTypeName}
                    betBonusPercent={bet?.potentialBetBonusPercentage}
                    appConfig={appConfig}
                  />
                  <Container
                    bet={bet}
                    key={bet.betTypeName}
                    oddsFormat={oddsFormat}
                    betBonusPercent={bet?.potentialBetBonusPercentage}
                  />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}

        {/* For forcast type bets */}
        {ArrRecept && ArrRecept['forcast'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={'Forcast'}
              appConfig={appConfig}
            />

            {ArrRecept['forcast'].map(bet => (
              <Container
                bet={bet}
                key={'Forcast' + bet.id}
                oddsFormat={oddsFormat}
              />
            ))}
          </React.Fragment>
        )}

        {/* For tricast type bets */}
        {ArrRecept && ArrRecept['tricast'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={'Tricast'}
              appConfig={appConfig}
            />

            {ArrRecept['tricast'].map(bet => (
              <Container
                bet={bet}
                key={'Tricast' + bet.id}
                oddsFormat={oddsFormat}
              />
            ))}
          </React.Fragment>
        )}

        {/* For forcasttricast type bets */}
        {ArrRecept && ArrRecept['forcasttricast'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={'Forcast/Tricast'}
              appConfig={appConfig}
            />

            {ArrRecept['forcasttricast'].map(bet => (
              <Container
                bet={bet}
                key={'Forcasttricast' + bet.id}
                oddsFormat={oddsFormat}
              />
            ))}
          </React.Fragment>
        )}
      </SC>
      {/* Recept bottom section */}
      <BottomSection
        betSlipRecipt={betSlipRecipt}
        handleReceptCloseButton={handleReceptCloseButton}
        bottomNavData={bottomNavData}
      />
    </React.Fragment>
  );
};

Receipt.propTypes = {
  betSlipRecipt: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  oddsFormat: PropTypes.string,
  appConfig: PropTypes.object,
  bottomNavData: PropTypes.object,
};
