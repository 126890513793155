import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../core__betslip-constants';
import {
  BetslipContentBooster,
  CrossButton,
  BetslipFreeBet,
  BetSlipBonus,
  Description,
  PotReturn,
  BetslipEW,
  OddName,
  Price,
  UserFreeBetStake,
  BetslipUserStake,
  Text,
  TeaserPrice,
} from './elements';
import {
  BU,
  BD,
  BCLC,
  BCL,
  BCSM,
  BSMD,
  BMD,
  FBPR,
  ENS,
  ERR,
  LIVE_ROW as LIVE_ROW_LOCAL,
} from 'UI/apps/BetslipSAApp/BetslipContainer/BetBox';
import { BH } from 'UI/apps/BetslipSAApp/BetslipContainer/Price';
import { Translations } from '../../../../core__betslip-SA-app';
import * as MarketConstants from '../../../../core__events-markets'; // Copied from EventsApp
import { getTranslation } from '../../../../core__betslip-utils';
import {
  MATCH_RESULT,
  TIME_SPAN,
  LIVE_ROW as LIVE_ROW_CORE,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__betBox';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { useInterval } from 'Services/hooks/core__interval';
import { getComponent } from 'Services/core__imports';
import { StakeNotification } from './elements/core__StakeNotification';

const LIVE_ROW = getComponent(LIVE_ROW_LOCAL, LIVE_ROW_CORE);

/**
 *@param {*} bet object data of Bet,
  @param {*} handleDelete function of delete Bet,
  @param {*} actTab string of active tab,
  @param {*} priceFractional string of fractional amount,
  @param {*} setUserStake function of handle user stake data 
  @param {*} userStakeBet string of user stake data,
  @param {*} potReturn number of calculated Pot. Return ,
  @param {*} currencySymbol string of currency code which come to customer api,
  @param {*} handleBooster function of handling booster, 
  @param {*} bbFlag boolen of booster flag of user ,
  @param {*} handleFreeBet function of handleing Free bet
  @param {*} userfreeBet object of freebet Data of user
  @param {*} wallet object data of wallet which come to customer api
  @param {*} handleBonus function of handling Bonus data,
  @param {*} bonus Boolen of bonus 
  @param {*} balance object of balance data which come to customer api
  @param {*} showArrow boolean to show an extra BSMD after the nextBetBonus [defaultProp is true]
 * 
*/

export const BetslipContentBetBox = ({
  bet,
  handleDelete,
  actTab,
  priceFractional,
  setUserStake,
  userStakeBet,
  potReturn,
  currencySymbol,
  handleBooster,
  bbFlag,
  handleFreeBet,
  userfreeBet,
  newWallet,
  handleBonus,
  bonus,
  balance,
  betMf,
  handleEwData,
  handleDropDownChange,
  price,
  oddsFormat,
  isLogin,
  setShowFB,
  isAccaBet,
  betTemplateTeaser,
  countAccaBet,
  showArrow,
  hideNextBetBonus,
  appConfig,
  handleFocusOut,
  errorObj = {},
  setErrorObj,
  errorData,
  postApiCall,
}) => {
  let hide2li = true;
  if (bet.active === false) {
    hide2li = false;
  } else if (actTab === Constants.MULTIPLE && bet.betTypeRef === 'SGL') {
    hide2li = false;
  } else if (actTab === Constants.ACCA && bet.betTypeRef === 'SGL') {
    hide2li = false;
  } else if (actTab === Constants.TEASER) {
    hide2li = false;
  } else if (actTab === Constants.SYSTEM && bet.betTypeRef === Constants.SGL) {
    hide2li = false;
  } else {
    hide2li = true;
  }

  const extraText =
    MarketConstants[bet?.catRef]?.american_market?.[bet?.marketTypeRef];

  const [soccerData, setSoccerData] = useState({});
  const [iceHockeyData, setIceHockeyData] = useState({});
  const [basketballData, setBasketballData] = useState({});
  const [gameData, setGameData] = useState({});
  const [inPlayTime, setInPlayTime] = useState(null);
  const [timerActive, setTimerActive] = useState(false);
  const [eventSocketListener, setEventSocketListener] = useState(null);

  useEffect(() => {
    subscribeEventSocket();
    listenEventSocket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      eventSocketListener && eventSocketListener.unsubscribe();
    };
  }, [eventSocketListener]);

  const subscribeEventSocket = () => {
    const subscriptionObject = {
      categoryRef: bet.catRef,
      providerRef: bet.providerRef || bet.eventProviderRef,
      sourceKey: bet.sourceKey,
    };
    PubSub.emit(PubsubEvents.SOCKET_EVENT_SUBSCRIBER, subscriptionObject);
  };

  const listenEventSocket = () => {
    const listener = PubSub.listen(PubsubEvents.SOCKET_EVENT_LISTEN, wsData => {
      if (wsData.sourceKey === bet.sourceKey) {
        if (wsData.catRef === Constants.SOCCER_REF) {
          setSoccerData(wsData);
        }
        if (wsData.catRef === Constants.BASKETBALL_REF) {
          setBasketballData(wsData);
        }
        if (wsData.catRef === Constants.ICE_HOCKEY_REF) {
          setIceHockeyData(wsData);
        }

        if (bet.live) {
          //for some data there's minutes instead of minute
          if (wsData?.minutes) {
            wsData.minute = wsData?.minutes;
          }
          if (wsData?.seconds) {
            wsData.second = wsData?.seconds;
          }
          if (wsData?.minute !== undefined && wsData?.second !== undefined) {
            setTimerActive(true);

            if (
              wsData.catRef === Constants.SOCCER_REF &&
              wsData.extratime &&
              wsData?.minute > 90
            ) {
              setInPlayTime(
                parseInt(wsData?.minute - 90) * 60 + parseInt(wsData.second)
              );
            } else {
              setInPlayTime(
                parseInt(wsData.minute) * 60 + parseInt(wsData.second)
              );
            }
          }
        }

        //set game data for period and state
        const updatedGameData = {};
        if (wsData.period) {
          updatedGameData['period'] = wsData.period;
        }
        if (wsData.state) {
          updatedGameData['state'] = wsData.state;
        }

        setGameData(updatedGameData);
      }
    });

    setEventSocketListener(listener);
  };

  useInterval(() => {
    if (
      inPlayTime !== null &&
      gameData.period !== GLOBAL_CONSTANTS.BREAK_TIME &&
      gameData.state !== GLOBAL_CONSTANTS.COMPLETED
    ) {
      if (bet.catRef === Constants.BASKETBALL_REF) {
        inPlayTime > 0 && setInPlayTime(inPlayTime - 1);
      } else {
        setInPlayTime(inPlayTime + 1);
      }
    }
  }, 1000);

  const minStake = bet?.limit?.find(item => {
    if (
      item.type === Constants.SINGLEMINSTAKE ||
      item.type === Constants.MULTIPLEMINSTAKE
    ) {
      return (item.value = parseFloat(item.value).toFixed(2));
    }
  });

  const checkMaxStakeExceeded = (err, returnMaxLimit) => {
    const errMsg = err?.message?.toLowerCase() || '';
    const checked = errMsg.includes(Constants.MAXSTAKE);
    if (returnMaxLimit && checked) {
      const regExpToMatchFloat = '[+-]?([0-9]*[.])?[0-9]+';
      const matches = errMsg.match(regExpToMatchFloat);
      return (matches?.length && matches[0]) || null;
    }
    return checked;
  };

  const checkMinStakeExceeded = (err, returnMaxLimit) => {
    const errMsg = err?.message?.toLowerCase() || '';
    const checked = errMsg.includes(Constants.MINSTAKE);
    if (returnMaxLimit && checked) {
      const regExpToMatchFloat = '[+-]?([0-9]*[.])?[0-9]+';
      const matches = errMsg.match(regExpToMatchFloat);
      return (matches?.length && matches[0]) || null;
    }
    return checked;
  };

  const date =
    timerActive &&
    ('0' + Math.floor(inPlayTime / 60)).slice(-2) +
      ':' +
      ('0' + Math.floor(inPlayTime % 60)).slice(-2);

  // get betBuilder when Multiple tab is active
  // Betbuilder not display in multi tab
  const isMultiTabBetBuilder =
    actTab === Constants.MULTIPLE && bet.betTypeRef === Constants.BBB;

  return (
    !isMultiTabBetBuilder && (
      <BMD key={bet.id} id="bet-box" betBonus={bet?.betBonus}>
        {((actTab === Constants.SINGLE &&
          bet?.nextBetBonus?.betTypeMinSelections === 2) ||
          actTab === Constants.MULTIPLE) &&
          bet?.nextBetBonus &&
          !bet?.hideBetBonus &&
          !hideNextBetBonus?.includes(bet.betTypeRef) && (
            <BSMD showBonusText={true}>
              {
                getTranslation(
                  bet,
                  Translations.get('text.bonus.info'),
                  countAccaBet
                )?.string
              }
              {showArrow && <BSMD arrow={true}></BSMD>}
            </BSMD>
          )}
        <BU multiple={bet.multiple}>
          {/* display odd name */}
          <OddName bet={bet} betMf={betMf} countAccaBet={countAccaBet} />
          {/* display price of odd */}
          {(actTab === Constants.TEASER && (
            <React.Fragment>
              <TeaserPrice
                name={bet.name}
                active={bet.active}
                label={betTemplateTeaser.label}
              />

              <CrossButton
                handleDelete={handleDelete}
                selectionId={bet.selectionId}
                betTypeRef={bet.betTypeRef}
                postApiCall={postApiCall}
              />
            </React.Fragment>
          )) ||
            (isAccaBet && bet.betModifier && (
              <React.Fragment>
                <Price
                  handleDropDownChange={handleDropDownChange}
                  pF={priceFractional}
                  oddsFormat={oddsFormat}
                  {...bet}
                  actTab={actTab}
                />
                {bet?.betBonus && (
                  <BCSM accaBonus={bet?.betBonus}>
                    +{bet.betBonus?.percentage}%{' '}
                    {Translations.get('text.acca.bonus')}
                  </BCSM>
                )}
                {bet.betTypeRef === Constants.SGL && (
                  <CrossButton
                    handleDelete={handleDelete}
                    selectionId={bet.selectionId}
                    betTypeRef={bet.betTypeRef}
                    postApiCall={postApiCall}
                  />
                )}
              </React.Fragment>
            )) ||
            (appConfig?.showmultibetinfo && bet.betModifier && (
              <Price
                handleDropDownChange={handleDropDownChange}
                pF={priceFractional}
                oddsFormat={oddsFormat}
                {...bet}
                actTab={actTab}
              />
            ))}

          {/* display cross button  */}
          {appConfig?.showmultibetinfo &&
            (bet.betTypeRef === Constants.SGL ||
              bet.betTypeRef === Constants.BBB) && (
              <CrossButton
                handleDelete={handleDelete}
                selectionId={bet.selectionId}
                betTypeRef={bet.betTypeRef}
                postApiCall={postApiCall}
              />
            )}
        </BU>

        {/* when data come to bet builder */}
        {bet.betTypeRef === Constants.BBB && (
          <BD>
            <ENS>{bet.eventName}</ENS>
          </BD>
        )}

        {/* UL 2 for Betslip */}
        {(bet?.newMarketSequence && !isNaN(bet.newMarketSequence) && (
          <ERR>
            <BH>{Translations.get('text.market.change')}</BH>
          </ERR>
        )) || (
          <BD>
            {bet?.bogAvailable && (
              /* Display BOG Tab */
              <BCLC>
                <BCL>{Translations.get('text.bog')}</BCL>
              </BCLC>
            )}

            {/* Display Discription */}
            {bet.description && bet.betTypeRef === Constants.SGL && (
              <Description
                extraText={!bet.description.startsWith(extraText) && extraText}
                desc={bet.description}
                active={bet.active}
                catRef={bet.catRef}
                schedule={bet.scheduledStart}
              />
            )}

            {bet.text && (
              <Text text={bet.text} active={bet.active} catRef={bet.catRef} />
            )}
          </BD>
        )}
        {isAccaBet && bet.betTypeRef === Constants.SGL && (
          <BD>
            {bet.description && (
              <Description
                extraText={!bet.description.startsWith(extraText) && extraText}
                desc={bet.description}
                active={bet.active}
                catRef={bet.catRef}
                actTab={actTab}
              />
            )}
          </BD>
        )}

        <LIVE_ROW>
          {bet.live && (
            /* Display Live Tab */
            <BCLC>
              <BCL>{Translations.get('text.live')}</BCL>
            </BCLC>
          )}
          {date && (
            <React.Fragment>
              {soccerData && soccerData?.game && (
                <MATCH_RESULT>
                  {soccerData.game.home}-{soccerData.game.away}
                </MATCH_RESULT>
              )}

              {basketballData && basketballData?.game && (
                <MATCH_RESULT>
                  {basketballData.game.home}-{basketballData.game.away}
                </MATCH_RESULT>
              )}

              {iceHockeyData && iceHockeyData?.game && (
                <MATCH_RESULT>
                  {iceHockeyData.game.home}-{iceHockeyData.game.away}
                </MATCH_RESULT>
              )}

              <TIME_SPAN>{date}</TIME_SPAN>
            </React.Fragment>
          )}
        </LIVE_ROW>

        {hide2li && bet.active !== false && (
          <BSMD accaBonus={bet?.betBonus}>
            <BCSM>
              {/* next conditon if user select free bet then it show first one other wise it show second one */}
              {isLogin && userfreeBet && userfreeBet.hasFreebet ? (
                <UserFreeBetStake
                  currencySymbol={currencySymbol}
                  freebetCredit={userfreeBet.freebetCredit}
                />
              ) : (
                // display user stake box
                <BetslipUserStake
                  userStakeBet={userStakeBet}
                  setUserStake={setUserStake}
                  betMf={betMf}
                  price={price}
                  bet={bet}
                  isMaxBetError={
                    (errorData && errorData?.type === Constants.MAX) ||
                    (errorObj.isError && checkMaxStakeExceeded(errorObj))
                  }
                  isMinBetError={
                    errorObj.isError && checkMinStakeExceeded(errorObj)
                  }
                  handleFocusOut={handleFocusOut}
                  selectionId={bet.selectionId}
                />
              )}
              <FBPR
                freebet={
                  bet.allowCreditWallet === false ||
                  bbFlag === true ||
                  bonus === true ||
                  newWallet.length === 0 ||
                  betMf.betCount !== 1
                }
              >
                {/** 
              handle Free Bet stuff or Wallet stuff
              @allowCreditWallet this flag from template api depend freebet show or not 
              @bbFlag this flag for booster if user already select booster then free bet not show
              @bonus this flag for BetSlip bonus if user already selected bonus then free bet not show
              @newWallet this array where all data of wallet store 
              @betCount betModifier content BetCount which is 1 
              */}
                {isLogin &&
                  bet.allowCreditWallet &&
                  bbFlag === false &&
                  bonus === false &&
                  newWallet.length > 0 &&
                  (betMf.betCount === 1 ||
                    newWallet.some(
                      wallet =>
                        wallet.minSelectionsRestriction <= betMf.betCount
                    )) && (
                    <BetslipFreeBet
                      betId={bet.id}
                      wallet={newWallet}
                      freeBetData={userfreeBet ? userfreeBet : bet}
                      currencySymbol={currencySymbol}
                      handleFreeBet={handleFreeBet}
                      betCat={bet.catRef}
                      setShowFB={setShowFB}
                      betSubCat={bet.subcatRef}
                    />
                  )}

                {/* Handle the value of potential return  */}

                <PotReturn
                  potReturn={potReturn}
                  currencySymbol={currencySymbol}
                  bet={bet}
                />
              </FBPR>
            </BCSM>
            {/** Handle low stake problems */}
            {((bet.isValid === false && minStake?.value) ||
              (errorData && errorData.type === Constants.MAX)) && (
              <StakeNotification
                setErrorObj={setErrorObj}
                isActive={errorObj.isError ? false : bet.isValid}
                currencySymbol={currencySymbol}
                userStake={errorData?.value}
                textMsg={errorData?.type}
                handleFocusOut={handleFocusOut}
                betMf={betMf}
                price={price}
                bet={bet}
                hasStake={userStakeBet}
                errorData={errorData}
              />
            )}
            {/* EW Terms  checkbox only for horse and dog races */}
            {!appConfig?.hideew &&
              bet.catRef &&
              bet.betTypeRef !== Constants.BBB &&
              bet.betModifier?.length > 1 &&
              bet.betModifier?.[1]?.ewFraction !== 1 &&
              bet.betModifier?.[1]?.ewPlaces !== 1 && (
                <BetslipEW
                  bet={bet}
                  handleEwData={handleEwData}
                  price={price}
                  actTab={actTab}
                />
              )}

            {/**
*handle use Bonus stuff 
@allowBonusFunds this flag from template api depend Bonus show or not 
@bbFlag this flag for booster if user already select booster then use bonus not show
@betCount betModifier content BetCount which is 1 
@balance its comes to api if balance
@priceBookType if price booking type is SP then bonus not show
*/}

            {bet.allowBonusFunds &&
              bet.hasFreebet === false &&
              balance &&
              balance.sportsbookBalance > 0 && (
                <BetSlipBonus
                  handleBonus={handleBonus}
                  bonus={bonus}
                  bet={bet}
                />
              )}

            {/**
 *handle use Booster stuff 
 @allowBetBoost this flag from template api depend Booster show or not 
 @bonus this flag for bonus if user already select bonus then use Booster not show
 @betCount betModifier content BetCount which is 1 
 @boostedReturns this flag to check boostedReturns key exist in betModifier object
*/}
            {isLogin &&
              bet.allowBetBoost &&
              bonus === false &&
              bet.hasFreebet === false &&
              betMf['boostedReturns'] && (
                <BetslipContentBooster
                  {...betMf}
                  key={betMf['boosterkey']}
                  handleBooster={handleBooster}
                  bbFlag={bbFlag}
                  oddsFormat={oddsFormat}
                  price={price}
                  betModifier={bet.betModifier}
                  betModifierFlag={bet.betModifierFlag}
                />
              )}
          </BSMD>
        )}
      </BMD>
    )
  );
};

BetslipContentBetBox.propTypes = {
  bet: PropTypes.object,
  handleDelete: PropTypes.func,
  actTab: PropTypes.string,
  priceFractional: PropTypes.string,
  setUserStake: PropTypes.func,
  userStakeBet: PropTypes.string,
  potReturn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencySymbol: PropTypes.string,
  handleBooster: PropTypes.func,
  setShowFB: PropTypes.func,
  bbFlag: PropTypes.bool,
  handleFreeBet: PropTypes.func,
  userfreeBet: PropTypes.object,
  newWallet: PropTypes.array,
  hideNextBetBonus: PropTypes.array,
  handleBonus: PropTypes.func,
  bonus: PropTypes.bool,
  balance: PropTypes.object,
  betMf: PropTypes.object,
  handleEwData: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  price: PropTypes.object,
  oddsFormat: PropTypes.string,
  isLogin: PropTypes.bool,
  isAccaBet: PropTypes.bool,
  betTemplateTeaser: PropTypes.object,
  countAccaBet: PropTypes.number,
  showArrow: PropTypes.bool,
  appConfig: PropTypes.object,
  handleFocusOut: PropTypes.func,
  errorObj: PropTypes.object,
  setErrorObj: PropTypes.func,
  errorData: PropTypes.object,
  postApiCall: PropTypes.bool,
};

BetslipContentBetBox.defaultProps = {
  isAccaBet: false,
  betTemplateTeaser: {},
  showArrow: true,
};
