import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

export const BS = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props?.theme?.dark ? GREYS.black : GREYS.white};
`;
