import React from 'react';
import PropTypes from 'prop-types';
import {
  BTL,
  BTSS,
  BTCB,
} from 'UI/apps/BetslipSAApp/BetslipContainer/GenerateTab';
import { Translations } from '../../../../../core__betslip-SA-app';
/**
 @param {array} tabs
 @param {object} props
 */
export const TabsGenrate = ({ tabs, props }) => {
  return (
    <BTSS key={tabs[0].actTab}>
      <BTL c2={true} active={props.actTab === tabs[0].actTab}>
        {tabs[0].display}
      </BTL>
      {tabs[0].delete && (
        <BTCB
          onClick={() => {
            props.handleDelete({ type: 'deleteall' });
          }}
        >
          {Translations.get('text.clear.betslip')}
        </BTCB>
      )}
    </BTSS>
  );
};
TabsGenrate.propTypes = {
  tabs: PropTypes.array,
  props: PropTypes.shape({
    handleActTab: PropTypes.func,
    handleDelete: PropTypes.func,
    actTab: PropTypes.string,
  }),
  handleActTab: PropTypes.func,
  handleDelete: PropTypes.func,
  actTab: PropTypes.string,
};
