import {
  BCBD,
  BCBC,
  BCBS,
  S,
  L,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__ew';
import styled from 'styled-components';
import { FONT } from 'UI/globals/colours';

const BCBS_EX = styled(BCBS)`
  color: ${FONT.black.label};
`;

export { BCBD, BCBC, BCBS_EX as BCBS, S, L };
