import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BSI,
  BSM,
  BAMY,
  BA,
  BHL,
  BHU,
} from 'UI/apps/BetslipSAApp/BetslipContainer/Head';
import { Translations } from '../../../../../core__betslip-SA-app';
import { Constants } from '../../../../../core__betslip-constants';
import {
  SUMMARY_PRICE,
  SUMMARY_TEXT,
  LI,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__head';
import { genraterName, detectMob } from '../../../../../core__betslip-utils';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';

/**
@param {string} actbet   
@param {number} count 
@param {bool} isLogin 
@param {bool} dowmIcon 
@param {func} expendandcollapse
@param {bool} showMyBetTab: used to show `My Bet` tab in Betslip Header [default is true]
@param {array} betTemplate
@param {string} oddsFormat
@param {string} actTab
*/

export const BetslipHeader = ({
  actbet,
  count,
  isLogin,
  expendandcollapse,
  dowmIcon,
  displayCountwhenBetZero,
  isMobile,
  showMyBetTab,
  betTemplate,
  oddsFormat,
}) => {
  let hideCount = false;
  if (displayCountwhenBetZero && count === 0) {
    hideCount = true;
  }

  useEffect(() => {
    if (displayCountwhenBetZero && count === 0 && dowmIcon) {
      expendandcollapse();
    }
  }, [displayCountwhenBetZero, count, dowmIcon, expendandcollapse]);

  const selections = betTemplate?.filter(b => b.selectionId) || [];

  let summary;
  if (selections.length === 1) {
    summary = (
      <LI>
        <SUMMARY_TEXT>
          {(selections[0].betTypeRef === 'BBB' &&
            Translations.get('text.createbet')) ||
            Translations.get('text.single')}
        </SUMMARY_TEXT>

        <SUMMARY_PRICE>
          {oddsFormat === Constants.DECIMAL
            ? parseDecimalPlaces(
                (selections[0].betTypeRef === 'BBB' && selections[0].price) ||
                  selections[0].priceDecimal
              )
            : (selections[0].betTypeRef === 'BBB' &&
                selections[0].fractional) ||
              selections[0].priceFractional}
        </SUMMARY_PRICE>
      </LI>
    );
  } else if (selections.length > 1) {
    const bet = betTemplate.find(b => {
      return (
        b.multiple && b.betModifier?.length && b.betModifier[0]?.betCount === 1
      );
    });
    const name =
      bet &&
      Translations.get(
        `bet.type.${genraterName(bet.name, bet.betModifier)
          .toLowerCase()
          .replace(/ /g, '.')}`
      );

    const price =
      bet &&
      bet.betModifier?.length &&
      (oddsFormat === Constants.DECIMAL
        ? parseDecimalPlaces(bet.betModifier[0]?.returns)
        : bet.betModifier[0]?.fractionalReturns);

    summary = name && price && (
      <LI>
        <SUMMARY_TEXT>{name}</SUMMARY_TEXT>
        <SUMMARY_PRICE>{price}</SUMMARY_PRICE>
      </LI>
    );
  }

  return (
    <BHU isMobile={isMobile} onClick={expendandcollapse}>
      <BHL>
        <BA active={actbet === Constants.BET}>
          <span>{Translations.get('text.betslip')}</span>
          {!hideCount && <BSM>{count && count}</BSM>}
        </BA>
      </BHL>

      {/* {actTab === Constants.SINGLE &&  */}
      {!dowmIcon && detectMob() && summary}

      <BSI downIcon={dowmIcon} />

      {showMyBetTab && (
        <BHL>
          <BAMY active={actbet === Constants.MYBET} disable={isLogin}>
            {Translations.get('text.mybet')}
          </BAMY>
        </BHL>
      )}
    </BHU>
  );
};

BetslipHeader.propTypes = {
  count: PropTypes.number,
  actbet: PropTypes.string,
  isLogin: PropTypes.bool,
  expendandcollapse: PropTypes.func,
  dowmIcon: PropTypes.bool,
  displayCountwhenBetZero: PropTypes.bool,
  isMobile: PropTypes.bool,
  showMyBetTab: PropTypes.bool,
  betTemplate: PropTypes.array,
  oddsFormat: PropTypes.string,
  actTab: PropTypes.string,
};

BetslipHeader.defaultProps = {
  displayCountwhenBetZero: false,
  showMyBetTab: true,
};
