import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SummaryTopSectionButton,
  M,
} from 'UI/apps/BetslipSAApp/BetslipContainer/TopSection';
import { Translations } from '../../../../../core__betslip-SA-app';
import { DeleteIcon } from '../../../../../core__betslip-utils';
import { GREYS } from 'UI/globals/colours';
import { PubSub, PubsubEvents } from 'Services/core__services';

/**
 @param {func} handleDelete
 @param {bool} suspended
 */

export const TopSection = ({
  handleDelete,
  suspended,
  checkChanges,
  appConfig,
}) => {
  // to delete all the unplaced bets in the betslip when a user logs out
  useEffect(() => {
    PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      handleDelete({ type: 'deleteall' });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clearBetslipIcon = (
    <SummaryTopSectionButton
      onClick={() => {
        handleDelete({ type: 'deleteall' });
      }}
      hideClearBetslip={appConfig?.topdeletebetslip}
    >
      <M iconShow={true}>
        <DeleteIcon color={GREYS.white} />
        <M ds={true}>{Translations.get('text.clear.betslip')}</M>
      </M>
    </SummaryTopSectionButton>
  );

  let renderTop = clearBetslipIcon;

  if (suspended) {
    renderTop = (
      <Fragment>
        {clearBetslipIcon}
        <M odds>
          {Translations.get('text.no.available')}
          <br />
          {Translations.get('text.review.bet')}
        </M>
      </Fragment>
    );
  }

  if (checkChanges) {
    renderTop = (
      <Fragment>
        {clearBetslipIcon}
        <M odds>{Translations.get('text.odd.change.review')}</M>
      </Fragment>
    );
  }

  return <React.Fragment>{renderTop}</React.Fragment>;
};
TopSection.propTypes = {
  handleDelete: PropTypes.func,
  suspended: PropTypes.bool,
  checkChanges: PropTypes.bool,
  appConfig: PropTypes.object,
};
