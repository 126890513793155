import React from 'react';
import PropTypes from 'prop-types';
import { setterGetterFromLocalstorage } from 'Services/localstorage/core__localstorage';
import { Constants } from './core__betslip-constants';
import moment from 'moment';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';
import {
  capitalizeFirstLetter,
  getInterpolatedParametersInTranslation,
} from 'Services/globalfunctions/core__global-functions';
import project from '../../../project';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import {
  parseDecimalPlaces,
  parseDecimalPlacesPotReturn,
} from 'Services/global/core__odds-format';
import { Translations } from './core__betslip-SA-app';
/** 
for geting correct selectionIDs
@param localstorage object
*/

export const getSelectionIds = localStorageData => {
  let bsid = '';
  let bbid = '';
  if (localStorageData && localStorageData.length > 0) {
    bsid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] !== Constants.BBB &&
          (isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);

    bbid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] === Constants.BBB &&
          (!isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);
  }
  return { bsid, bbid };
};
/**
for geting correct priceIds
@params localstorage object
*/

export const getPriceIds = localStorageData =>
  (localStorageData &&
    localStorageData
      .map(item => {
        if (
          item &&
          typeof item['price'] !== 'undefined' &&
          item.betTypeRef !== Constants.BBB
        ) {
          return item?.priceId || item['price'][0]['id'];
        }
      })
      .filter(item => item)) ||
  '';

/**
get single data of selection bet
@params : dynmic id
*/
export const localstorageSelectedIddata = (id, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });
  if (localData) {
    const data = localData.filter(itm => itm['id'] === id);
    if (data.length > 0) {
      return data[0];
    } else {
      return {};
    }
  } else return {};
};

/** 
this function update when user reload or reopen page old data is show 
if data in not Avalable then default data which is comming to api is set 
@params betTemplate
*/
export const mergeDataWithLocalStorage = (betTemplate, localStorageKey) => {
  const { wallet, raw } = FSBCustomerSession.getSession();
  const oldItem = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });
  const notificationParam = setterGetterFromLocalstorage({
    keyName: 'odd-moment',
    action: 'get',
  });
  let oldBetCount = 0;
  if (Array.isArray(oldItem)) {
    oldBetCount = oldItem.length;
  }
  let freebetsIds = [];
  if (Array.isArray(wallet)) {
    freebetsIds = wallet.map(w => w.id).filter(d => d);
  }

  for (let i = 0; i < Object.keys(betTemplate).length; i++) {
    const id = betTemplate[i]['id'];

    let { price, betModifier } = betTemplate[i];
    let getDataOfPrice = {};
    const OldDatafromLS = localstorageSelectedIddata(id, localStorageKey);
    if (price) {
      price = price[0];
      getDataOfPrice = {
        priceBookId: price?.bookId || '',
        priceBookType: price?.bookType || '',
        priceCurrent: price?.current || '',
        priceId: price?.id || '',
        priceChange: false,
        priceDecimal: price?.decimal || '',
        priceFractional: price?.fractional || '',
      };
    }
    let getDataofModifier = {};
    if (betModifier) {
      betModifier = OldDatafromLS.betModifierFlag
        ? betModifier[1]
        : betModifier[0];
      getDataofModifier = {
        betModifierBetCount: betModifier?.betCount || '',
        betModifierType: betModifier?.type || '',
        fractionalReturns: betModifier?.fractionalReturns || '',
        betModifierReturns: betModifier?.returns || '',
        boostedFractionalReturns: betModifier?.boostedFractionalReturns || '',
        boostedReturns: betModifier?.boostedReturns || '',
      };
    }
    const bet = betTemplate[i];
    bet['id'] = OldDatafromLS?.id || id;
    bet['status'] = true;
    bet['ordinal'] = (bet.ordinal && bet.ordinal) || OldDatafromLS?.ordinal;

    if (
      OldDatafromLS.multiple &&
      oldBetCount !== Object.keys(betTemplate).length // difference identify when we remove stake from multibets
    ) {
      bet['_returns'] = '';
      bet['_returnsFormatted'] = '';
      bet['userStake'] = '';
      // remove selected free bet when new selection added
      OldDatafromLS['hasFreebet'] = false;
      OldDatafromLS['freebetCredit'] = '';
      OldDatafromLS['freebetCreditFormatted'] = '';
      OldDatafromLS['selectedFreebetId'] = '';
    } else {
      bet['_returns'] = OldDatafromLS?._returns || '';
      bet['_returnsFormatted'] = OldDatafromLS?._returnsFormatted || '';
      bet['userStake'] = OldDatafromLS?.userStake || '';
    }

    bet['errorStakeNotification'] = OldDatafromLS?._returnsFormatted || false;
    bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
    bet['returnsText'] = OldDatafromLS?.returnsText || '';

    const { betCount } = bet.betModifier?.[0] || [];
    const { betCount: oldDataBetCount } = OldDatafromLS.betModifier?.[0] || [];

    if (bet?.multiple && betCount !== oldDataBetCount) {
      bet['totalStake'] = '';
      bet['userStake'] = '';
    } else {
      bet['totalStake'] = OldDatafromLS?.totalStake || '';
      bet['userStake'] = OldDatafromLS?.userStake || '';
    }

    if (OldDatafromLS?.bbFlag) {
      bet['priceDecimal'] =
        OldDatafromLS?.priceDecimal || getDataofModifier.boostedReturns;
      bet['priceFractional'] =
        OldDatafromLS?.priceFractional ||
        getDataofModifier.boostedFractionalReturns;
    } else {
      if (bet.multiple) {
        bet['priceDecimal'] = getDataofModifier.betModifierReturns;
        bet['priceFractional'] = getDataOfPrice.priceFractional;
      } else {
        bet['priceDecimal'] =
          OldDatafromLS?.priceDecimal || getDataOfPrice.priceDecimal;
        bet['priceFractional'] =
          OldDatafromLS?.priceFractional || getDataOfPrice.priceFractional;
      }
    }
    bet['bbFlag'] = OldDatafromLS?.bbFlag || false;
    bet['hasFreebet'] = OldDatafromLS?.hasFreebet || false;
    bet['selectedFreebetId'] = OldDatafromLS?.selectedFreebetId || '';
    bet['freebetCredit'] = OldDatafromLS?.freebetCredit || '';
    bet['freebetCreditFormatted'] = OldDatafromLS?.freebetCreditFormatted || '';
    bet['freebetRestrictions'] = OldDatafromLS?.freebetRestrictions || '';
    bet['bonusFunds'] = OldDatafromLS?.bonusFunds || '';
    bet['priceChange'] =
      OldDatafromLS?.priceChange || getDataOfPrice.priceChange;
    bet['betModifierBetCount'] =
      getDataofModifier?.betModifierBetCount ||
      OldDatafromLS.betModifierBetCount;
    // for horse and dog EW flag
    if (OldDatafromLS.betModifierFlag) {
      bet['betModifierBetCount'] = OldDatafromLS.betModifierBetCount;
    }
    let Oldprice = OldDatafromLS.price || {};
    Oldprice = Oldprice[0];
    if (Oldprice && Oldprice.id !== getDataOfPrice.priceId) {
      // update price handling on page reload
      bet['priceId'] = getDataOfPrice.priceId;
      bet['priceBookId'] = getDataOfPrice.priceBookId;
      bet['priceBookType'] = getDataOfPrice.priceBookType;
      bet['priceCurrent'] = getDataOfPrice.priceCurrent;
      if (notificationParam === 'default') {
        // condition 1st when price change incress or decress accepted automatic
        bet['priceChangeNotification'] = false;
        bet['updateNewPrice'] = true;
      } else if (notificationParam === 'lower') {
        // condition 2st when price change incress accepted automatic
        bet['priceChangeNotification'] = !(
          Oldprice.decimal < getDataOfPrice.priceDecimal
        );
        bet['updateNewPrice'] = Oldprice.decimal < getDataOfPrice.priceDecimal;
      } else {
        // default
        bet['priceChangeNotification'] = true;
        bet['updateNewPrice'] = false;
      }
      bet['previousPriceDecimal'] = previousPrice(
        Oldprice.decimal,
        Oldprice.bookType
      );
      bet['previousPriceFractional'] = previousPrice(
        Oldprice.fractional,
        Oldprice.bookType
      );
    } else {
      // handle price with WS and no price id change
      bet['priceId'] = OldDatafromLS?.priceId || getDataOfPrice.priceId;
      bet['priceBookId'] =
        OldDatafromLS?.priceBookId || getDataOfPrice.priceBookId;
      bet['priceBookType'] =
        OldDatafromLS?.priceBookType || getDataOfPrice.priceBookType;
      bet['priceCurrent'] =
        OldDatafromLS?.priceCurrent || getDataOfPrice.priceCurrent;
      bet['previousPriceDecimal'] =
        (OldDatafromLS?.updateNewPrice && bet?.previousPriceDecimal) ||
        OldDatafromLS?.previousPriceDecimal ||
        '';
      bet['previousPriceFractional'] =
        (OldDatafromLS?.updateNewPrice && bet?.previousPriceFractional) ||
        OldDatafromLS?.previousPriceFractional ||
        '';
      bet['priceChangeNotification'] =
        OldDatafromLS.priceChangeNotification || false;
      bet['updateNewPrice'] = OldDatafromLS?.updateNewPrice || false;
    }

    if (bet['betTypeRef'] === 'SGL') {
      bet['castPosition'] = OldDatafromLS?.castPosition || i + 1;
    }
    bet['betModifierType'] =
      OldDatafromLS?.betModifierType || getDataofModifier.betModifierType;
    bet['betModifierFlag'] = OldDatafromLS?.betModifierFlag || false;
    bet['spFlag'] = OldDatafromLS?.spFlag || false;
    bet['betModifierFractional'] =
      OldDatafromLS?.betModifierFractional ||
      getDataofModifier.fractionalReturns;
    bet['betModifierReturns'] =
      OldDatafromLS?.betModifierReturns || getDataofModifier.betModifierReturns;
    bet['notificationPriceChangeType'] = bet?.notificationPriceChangeType || '';
    // if ws data is true
    if (bet.updateNewPrice) {
      bet['priceDecimal'] = OldDatafromLS?.bbFlag
        ? parseDecimalPlaces(getDataofModifier.boostedReturns)
        : parseDecimalPlaces(getDataOfPrice.priceDecimal);
      bet['priceFractional'] = OldDatafromLS?.bbFlag
        ? getDataofModifier.boostedFractionalReturns
        : getDataOfPrice.priceFractional;
      bet['priceId'] = getDataOfPrice.priceId;
    }

    if (!bet['hasFreebet'] && bet.betModifierFlag && bet.userStake) {
      const { betModifier } = bet;
      if (bet.betModifierFlag) {
        let p1 = betModifier.filter(bmf => {
          return bmf.type === 'EACHWAY';
        });
        p1 = p1[0];
        const tempBet = parseFloat(bet.userStake);
        const retAmt = tempBet * p1.returns;
        bet['_returns'] = retAmt;
        bet['_returnsFormatted'] = retAmt;
      }
    }

    if (bet['multiple'] && bet['userStake']) {
      let retAmt = '';
      const tempBet = parseFloat(bet.userStake);
      retAmt = tempBet * getDataofModifier.betModifierReturns;
      bet['_returns'] = retAmt;
      bet['_returnsFormatted'] = retAmt;
    }
    // when we successfully post free bet and in wallet id not comes we need to remove applyed freebet amount bet
    if (bet['hasFreebet'] && !freebetsIds.includes(bet['selectedFreebetId'])) {
      bet['hasFreebet'] = false;
      bet['selectedFreebetId'] = '';
      bet['freebetCredit'] = '';
      bet['freebetCreditFormatted'] = '';
      bet['_returns'] = '';
      bet['_returnsFormatted'] = '';
    } else if (bet['hasFreebet']) {
      // when user update his price with ws
      const freebetRtn = bet['freebetCredit'] * bet['priceDecimal'];
      bet['_returns'] = freebetRtn - bet['freebetCredit'];
      bet['_returnsFormatted'] = freebetRtn - bet['freebetCredit'];
    }
    // if user logout and bbflag selected
    if (
      typeof raw === 'undefined' &&
      !raw?.availableBetBoostTokens &&
      bet['bbFlag']
    ) {
      bet['bbFlag'] = false;
      bet['priceDecimal'] = getDataOfPrice['priceDecimal'];
      bet['priceFractional'] = getDataOfPrice['priceFractional'];
    }

    if (!bet['userStake'] && !bet['freebetCredit']) {
      bet['_returns'] = '';
      bet['_returnsFormatted'] = '';
    }

    if (bet.betTypeRef === Constants.BBB) {
      let retAmt = '';
      const tempBet = parseFloat(bet.userStake);
      retAmt = tempBet * bet.price;
      bet['returnsText'] = retAmt;
      bet['_returns'] = retAmt;
      bet['_returnsFormatted'] = retAmt;
    }

    const builderLocalData = setterGetterFromLocalstorage({
      keyName: Constants.BUILDERUSEDATA,
      action: 'get',
    });
    if (Array.isArray(builderLocalData) && builderLocalData.length > 0) {
      for (const bd of builderLocalData) {
        if (bet.id === bd.id) {
          bet['totalStake'] = bd.totalStake;
          bet['userStake'] = bd.userStake;
          let retAmt = '';
          const tempBet = parseFloat(bet.userStake);
          retAmt = tempBet * bet.price;
          bet['returnsText'] = retAmt;
          bet['_returns'] = retAmt;
          bet['_returnsFormatted'] = retAmt;
        }
      }
    }
  }

  return betTemplate;
};

/**
genrate it merger localstorage data to Main api Data with active flag 
if bet is inactive flag become active = false
@params betTemplate
*/
export const checkSuspendedBet = (betTemplate, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });

  betTemplate.forEach(nb => {
    const existingSelection = localData.find(l => l.id === nb.id);
    if (!existingSelection) {
      nb.addedToBetslipAt = new Date().getTime();
      nb.addedToBetslipDate = moment(nb.addedToBetslipAt).format(
        'YYYY-DD-MM HH:mm:ss'
      );
    } else {
      nb.addedToBetslipAt = existingSelection.addedToBetslipAt;
      nb.addedToBetslipDate = existingSelection.addedToBetslipDate;
    }
  });

  let returnBets = betTemplate;
  let isSuspended = false;

  if (betTemplate.length === 0 && localData.length > 0) {
    const tempBet = [];
    for (const bet of localData) {
      bet['active'] = false;
      tempBet.push(bet);
    }
    returnBets = tempBet;
  } else if (betTemplate.length > 0 && localData.length > 0) {
    const suspendedBet = localData.filter(
      ({ id: id1 }) => !betTemplate.some(({ id: id2 }) => id2 === id1)
    );

    if (suspendedBet.length > 0) isSuspended = true;

    for (const s_bet of suspendedBet) {
      if (s_bet['betTypeRef'] === 'SGL') {
        s_bet['active'] = false;
        betTemplate.push(s_bet);
      }
    }
  }

  return isSuspended
    ? returnBets.sort(bet => (bet.multiple ? 1 : -1))
    : returnBets;
};

/**
 * filter out selections that are more than 3 hours in betslip and are suspended
 * @param {array} selections
 */
export const filterSuspendedSelections = selections => {
  return selections.filter(item => {
    let isSuspended = false;

    if (
      item.active === false &&
      item['addedToBetslipAt'] !== 'undefined' &&
      Number(item['addedToBetslipAt']) + 3 * 60 * 60 * 1000 <
        Number(new Date().getTime())
    )
      isSuspended = true;
    return !isSuspended;
  });
};

export const checkIsValidBet = betTemplate => {
  let stakeValidate = true;
  betTemplate.forEach(bet => {
    if (bet?.isValid !== undefined && !bet.isValid && bet.active) {
      stakeValidate = false;
    }
  });
  return stakeValidate;
};

/** 
Placing bet logic 
that make user
@params betTemplate
*/
export const placingBetUser = betTemplate => {
  const priceIds = getPriceIds(betTemplate);
  const priceIdsCombine = getPriceIdsbookType(betTemplate, 'SP');
  const priceIdsSCT = getPriceIdsbookType(betTemplate, null);

  const placeBetArr = [];

  for (const bet of betTemplate) {
    const { betModifier, price } = bet;
    const initalObj = {
      priceIds: '',
      betTypeRef: '',
      unitStake: '',
      totalStake: '',
      betModifier: '',
      betBoost: false,
      bonusFunds: false,
    };
    let type = betModifier[0] ? betModifier[0]['type'] : 'WIN';
    if (bet.betModifierFlag) {
      type = betModifier[1] ? betModifier[1]['type'] : 'WIN';
    }

    if (bet.betTypeRef === Constants.SGL) {
      const priceId =
        bet.priceBookType === 'SP' && price.length >= 2
          ? price[1]['id']
          : price[0]['id'];
      initalObj.priceIds = [priceId];
      initalObj.betTypeRef = bet.betTypeRef;
      initalObj.betModifier = bet?.betModifierType || type;
      initalObj.betBoost = bet.bbFlag ? bet.bbFlag : false;
      if (bet.userStake && bet.userStake > 0) {
        const { betModifierFlag } = bet;
        initalObj.unitStake = bet.userStake;
        if (betModifierFlag) {
          const { betCount } = betModifier[1];
          initalObj.totalStake = !isNaN(bet.totalStake)
            ? parseDecimalPlaces(parseFloat(bet.totalStake))
            : betCount * Number(bet.userStake);
        } else {
          initalObj.totalStake = !isNaN(bet.totalStake)
            ? parseDecimalPlaces(parseFloat(bet.totalStake))
            : bet.totalStake;
        }
        initalObj.bonusFunds = bet.bonusFunds ? bet.bonusFunds : false;
        placeBetArr.push(initalObj);
      } else if (bet.freebetCredit && bet.hasFreebet === true) {
        initalObj.unitStake = bet.freebetCredit;
        initalObj.totalStake = bet.freebetCredit;
        initalObj.walletId = bet.selectedFreebetId;
        initalObj.bonusFunds = false;
        placeBetArr.push(initalObj);
      }
    } else if (bet.multiple === true) {
      initalObj.priceIds =
        Constants.CAST && bet.betTypeRef === Constants.SCT
          ? priceIdsSCT
          : bet.category === Constants.CAST
          ? priceIdsCombine
          : priceIds;
      initalObj.betTypeRef = bet.betTypeRef;
      initalObj.betModifier = bet?.betModifierType || type;
      initalObj.betBoost = bet.bbFlag ? bet.bbFlag : false;
      if (bet.userStake && bet.userStake > 0) {
        const { betModifierFlag } = bet;
        initalObj.unitStake = bet.userStake;
        if (betModifierFlag) {
          const { betCount } = betModifier[1];
          initalObj.totalStake = isNaN(bet.totalStake)
            ? parseDecimalPlacesPotReturn(betCount * Number(bet.userStake))
            : parseDecimalPlacesPotReturn(bet.totalStake);
        } else {
          const { betCount } = betModifier[0];
          initalObj.totalStake = isNaN(bet.totalStake)
            ? parseDecimalPlacesPotReturn(betCount * Number(bet.userStake))
            : parseDecimalPlacesPotReturn(bet.totalStake);
        }
        initalObj.bonusFunds = bet.bonusFunds ? bet.bonusFunds : false;
        placeBetArr.push(initalObj);
      } else if (bet.freebetCredit && bet.hasFreebet === true) {
        initalObj.unitStake = bet.freebetCredit;
        initalObj.totalStake = bet.freebetCredit;
        initalObj.walletId = bet.selectedFreebetId;
        initalObj.bonusFunds = false;
        placeBetArr.push(initalObj);
      }
    } else if (bet.betTypeRef === Constants.BBB) {
      if (bet.hasFreebet) {
        initalObj.priceIds = bet.sentence.map(s => s.priceId).filter(p => p);
        initalObj.betTypeRef = bet.betTypeRef;
        initalObj.totalStake = bet.freebetCredit;
        initalObj.unitStake = bet.freebetCredit;
        initalObj.walletId = bet.selectedFreebetId;
        initalObj.betModifier = bet.betModifier;
        placeBetArr.push(initalObj);
      } else if (bet.totalStake && bet.userStake > 0) {
        initalObj.priceIds = bet.sentence.map(s => s.priceId).filter(p => p);
        initalObj.betTypeRef = bet.betTypeRef;
        initalObj.totalStake = bet.totalStake;
        initalObj.unitStake = bet.userStake;
        initalObj.betModifier = bet.betModifier;
        initalObj.bonusFunds = bet.bonusFunds ? bet.bonusFunds : false;
        placeBetArr.push(initalObj);
      }
    }
  }

  return placeBetArr;
};
/** 
this validation function is use for freebet 
if any free bet is already selected or if user
reslected same free bet in other one is that case first 
one is remove from selected element 
@param data content object
*/
export const validateFreeBet = (data, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });

  for (const itm of localData) {
    if (itm['selectedFreebetId'] === data['selectedFreebetId']) {
      itm['hasFreebet'] = false;
      itm['freebetCredit'] = '';
      itm['freebetCreditFormatted'] = '';
      itm['selectedFreebetId'] = '';
      itm['returnsText'] = 0;
      itm['_returnsFormatted'] = 0;
      itm['_returns'] = 0;
    }
  }

  setterGetterFromLocalstorage({
    keyName: localStorageKey,
    keyValue: localData,
    action: 'set',
  });
};

/**
calculate total count of single bets
*/
export const CalCount = betTemplate => {
  try {
    return betTemplate.map(itm => itm.selectionId).filter(itm => itm).length;
  } catch (error) {
    return betTemplate?.length || 0;
  }
};

/* that calculator useStake and  potentialReturns  */

export const genrateSlips = (bets, tax) => {
  let totalStake = Constants.DEFAULT_AMT;
  let potentialReturns = Constants.DEFAULT_AMT;
  let fee = Constants.DEFAULT_AMT;
  let netReturn = Constants.DEFAULT_AMT;
  let freeStake = Constants.DEFAULT_AMT;
  let hasFreebet = false;
  let bonusFund = false;
  let betBonusAmount = Constants.DEFAULT_AMT;
  let bonusPercentage = 0;
  let hasBonusBet = false;
  let multiplyOfPrices = 1;

  if (bets && bets.length > 0) {
    for (const bet of bets) {
      if (bet.betTypeRef === Constants.SGL) {
        multiplyOfPrices = multiplyOfPrices * bet.priceDecimal;
      }
    }

    for (const bet of bets) {
      const betCount = bet.betModifierBetCount
        ? bet.betModifierBetCount
        : Constants.DEFAULT_MODIFIERBETCOUNT;
      /* 
      total stake
      */
      if (bet['userStake']) {
        const ts = parseFloat(bet['userStake']) * betCount;
        totalStake += Math.round(1000 * ts) / 1000;
      }
      /* 
      total free bet
      */
      if (bet['freebetCredit']) {
        freeStake += isNaN(parseFloat(bet['freebetCredit']))
          ? 0
          : parseFloat(bet['freebetCredit']);
        if (bet['hasFreebet']) {
          hasFreebet = bet['hasFreebet'];
        }
      }

      /* 
      total return Gross 
      */
      if (
        bet['_returns'] !== Constants.N_A &&
        potentialReturns !== Constants.N_A &&
        !isAnySPbet()
      ) {
        if (bet['_returns'] && !isNaN(parseFloat(bet['_returns']))) {
          potentialReturns += parseFloat(bet['_returns']);
        }
      } else {
        potentialReturns = Constants.N_A;
      }

      // check if user select bonus fund

      if (bet.bonusFunds) {
        bonusFund = bet.bonusFunds;
      }
      // if bet has bet bonus
      if (bet?.betBonus && bet['userStake'] && multiplyOfPrices > 0) {
        const ts =
          parseFloat(bet['userStake']) * multiplyOfPrices -
          parseFloat(bet['userStake']);
        bonusPercentage = bet.betBonus?.percentage;
        hasBonusBet = true;
        if (ts) {
          betBonusAmount = parseFloat(ts * (bet.betBonus.percentage / 100));
        }
      }
    }
    // Fee
    if (potentialReturns > 0 && !isNaN(tax)) {
      const taxableAmount = potentialReturns - totalStake; // only apply tax to the profit, not the stake
      fee = Number((tax / 100) * taxableAmount);
    }

    // net pot. Return
    if (potentialReturns > 0) {
      netReturn = potentialReturns - fee;
    }
  }
  const a = {
    totalStake: totalStake,
    potentialReturns: potentialReturns,
    fee: fee,
    netReturn: netReturn,
    bonusFund: bonusFund,
    freeStakeData: {
      freeStake: freeStake,
      hasFreebet: hasFreebet,
    },
    betBonusData: {
      bonusPercentage: bonusPercentage,
      betBonusAmount: betBonusAmount,
      hasBonusBet: hasBonusBet,
    },
  };
  return a;
};

/* 
return data of Price 
*/
export const getDataOfPrice = (price, id) => {
  if (price) {
    if (id) {
      let pr = price.filter(p => {
        return p.id === parseInt(id);
      });
      pr = pr?.[0] || price[0];
      return pr;
    } else {
      return price[0];
    }
  } else return {};
};

/* 
return data of betModifier 
*/
export const getDataofModifier = (modifier, chkVal) => {
  if (modifier) {
    if (chkVal === true) {
      return modifier[1];
    } else {
      return modifier[0];
    }
  } else return {};
};

export const stakeLimit = (limitsObj, type) => {
  const stakeValue = limitsObj?.find(i => i.type === type);
  const val = stakeValue ? stakeValue.value : 0;
  return val;
};

/**
 * for Recipt
 * genrate Discription for single type bets
 * @param s1 {string}
 * @param s2 {string}
 * @param s3 {string}
 */
export const ReceiptDiscription = (s1, s2, s3, catRef) => {
  if ([Constants.HORSES, Constants.DOGS].includes(catRef)) {
    return s1 + ' ' + s2 + ' - ' + s3;
  } else {
    return s3 + ' - ' + s1;
  }
};

/**
 * for Recipt
 * genrate Discription for Forcast and tricast type bets
 * @param betPart {object}
 */

export const ReceiptDiscriptionFandT = betPart => {
  let selectionArr = betPart.map(bp => {
    return bp.selection;
  });
  selectionArr = selectionArr.toString();
  try {
    const name = betPart[0]['name'];
    const subcatName = betPart[0]['subcatName'];
    return selectionArr + ' - ' + name + ' ' + subcatName;
  } catch (error) {
    return selectionArr;
  }
};

/**
 * for Recipt
 * genrate Name for Forcast and tricast type bets
 * @param betModifier {object}
 * @param name {string}
 */
export const ReceiptGenraterName = (betModifier, name) => {
  if (name === 'Forecast') {
    return betModifier === 'WIN'
      ? 'Straight Forecast'
      : capitalizeFirstLetter(betModifier) + ` Forecast`;
  } else if (name === 'Tricast') {
    return betModifier === 'WIN'
      ? 'Straight Tricast'
      : capitalizeFirstLetter(betModifier) + ` Tricast`;
  } else {
    return betModifier + ' ' + name;
  }
};

/**
 * it genrate TotalStakes And TotalPotReturn and PlaceOn
 * @param {object} betTemplate
 * @returns {object}
 */

export const genTotalStakesAndTotalPotReturn = betTemplate => {
  let placeOn = '';
  let totalStake = 0;
  let potStake = 0;
  let freeBet = 0;
  for (const bet of betTemplate) {
    placeOn = moment(parseInt(bet.placedOn)).format(
      project.timeFormats.betslip.secondary || 'DD/MM/YYYY, hh:mm'
    );
    totalStake += !bet.creditWallet && parseFloat(bet.totalStake);
    freeBet += bet.creditWallet && parseFloat(bet.totalStake);
    if (bet.maxPayout > 0 && potStake !== Constants.N_A) {
      //when credit wallet become true remove userstake
      const maxpay = bet.maxPayout || 0;
      potStake = parseFloat(potStake) + parseFloat(maxpay);
    } else {
      potStake = Constants.N_A;
    }
  }
  return {
    placeOn: placeOn,
    totalStake: totalStake,
    potStake: potStake,
    freeBet: freeBet,
  };
};

/**
 * makeArrayOfRecept manipulate orignal response of betSlip which come to api
 * @params betSlip {object}
 */
export const makeArrayOfRecept = betSlip => {
  const single = [];
  const multiple = [];
  const forcast = [];
  const tricast = [];
  const allReceptBet = [];
  const forcasttricast = [];
  let ForcastTricast = false;

  const itemF = betSlip.find(item => item.betTypeName === Constants.FORCAST);
  const itemT = betSlip.find(item => item.betTypeName === Constants.TRICAST);

  if (itemF && itemT) {
    ForcastTricast = true;
  }
  for (const bet of betSlip) {
    if (
      bet['betTypeName'].toLowerCase() === Constants.SINGLE ||
      bet['betTypeName'].toLowerCase() === 'create a bet'
    ) {
      single.push(bet);
    } else if (bet['betTypeName'] === Constants.FORCAST && !ForcastTricast) {
      forcast.push(bet);
    } else if (bet['betTypeName'] === Constants.TRICAST && !ForcastTricast) {
      tricast.push(bet);
    } else if (
      (bet['betTypeName'] === Constants.FORCAST ||
        bet['betTypeName'] === Constants.TRICAST) &&
      ForcastTricast
    ) {
      forcasttricast.push(bet);
    } else {
      multiple.push(bet);
    }

    const { betPart } = bet;
    for (const bp of betPart) {
      const { ewPlaces, ewFraction } = bp;
      if (ewPlaces && ewFraction) {
        const places = { 1: '1', 2: '1-2', 3: '1-2-3' };
        const fractions = { 1: '1/1' };
        const getPlaces = ewPlaces > 3 ? '1 - ' + ewPlaces : places[ewPlaces];
        const getFractions =
          ewFraction > 1 ? '1/' + ewFraction : fractions[ewFraction];
        bet.ewLabel = getPlaces + ' @ ' + getFractions;
      }
    }

    if (
      bet.betCount === 1 &&
      bet['betTypeName'].toLowerCase() !== Constants.SINGLE &&
      bet['betTypeName'].toLowerCase() !== 'create a bet' &&
      bet['betTypeName'].toLowerCase() !== Constants.TEASER &&
      project.isParlay
    ) {
      bet['betTypeName'] =
        betPart.length + ' ' + Translations.get('text.leg.parlay');
    }

    if (
      bet.betCount === 1 &&
      bet['betTypeName'].toLowerCase() === Constants.TEASER
    ) {
      const { betModifier } = bet;
      const ptVal = betModifier.replace('TEASER_', '').replace('_', '.');
      bet['teaserPoint'] = ptVal;
      bet['betTypeName'] =
        betPart.length +
        ' ' +
        Translations.get('text.team') +
        ' ' +
        ptVal +
        ' ' +
        Translations.get('text.point.teaser');
    }
  }

  if (single.length > 0) {
    allReceptBet['single'] = single;
  }
  if (multiple.length > 0) {
    allReceptBet['multiple'] = multiple;
  }
  if (forcast.length > 0) {
    allReceptBet['forcast'] = forcast;
  }
  if (tricast.length > 0) {
    allReceptBet['tricast'] = tricast;
  }
  if (forcasttricast.length > 0) {
    allReceptBet['forcasttricast'] = forcasttricast;
  }
  return allReceptBet;
};

/**
 * handle odds change message for SP to oddsPrice
 * @param {*} price
 * @param {*} priceBookType
 * @returns
 */
export const previousPrice = (price, priceBookType) =>
  !price && priceBookType === Constants.SP ? Constants.SP : price;

/**
 * handle socket data for betslip
 * @param wsobject
 * */
export const wsSocketDataManipulation = (
  data,
  betTemplate,
  notificationParam
) => {
  const { selections } = data;
  const tempBetData = new Array();
  for (const bet of betTemplate) {
    let set = false;
    for (const sdata of selections) {
      if (bet.selectionId === sdata.id && !sdata.active) {
        bet.active = false;
        tempBetData.push(bet);
        set = true;
      } else if (
        bet.marketSequence !== data.sequence &&
        data.sequence !== false
      ) {
        bet.active = false;
        bet.newMarketSequence = data.sequence;
      } else if (bet.selectionId === sdata.id) {
        if (
          sdata.price.decimal !== undefined &&
          sdata.price.fractional !== undefined
        ) {
          const tempBet = Object.assign({}, bet);
          const x = { ...bet };
          x.active = true;
          if (
            notificationParam === Constants.LOWER &&
            tempBet.priceDecimal > sdata.price.decimal
          ) {
            //  when notification param is lower than mean when price is decress
            x.priceChangeNotification = true;
            x.updateNewPrice = false;
            x.previousPriceDecimal = previousPrice(
              tempBet.priceDecimal,
              tempBet.priceBookType
            );
            x.previousPriceFractional = previousPrice(
              tempBet.priceFractional,
              tempBet.priceBookType
            );
            x.notificationPriceChangeType = Constants.DEC;
          } else if (notificationParam === Constants.BLOCK) {
            //  when notification param is block than mean when price is decress | Increases
            x.priceChangeNotification = true;
            x.updateNewPrice = false;
            x.previousPriceDecimal = previousPrice(
              tempBet.priceDecimal,
              tempBet.priceBookType
            );
            x.previousPriceFractional = previousPrice(
              tempBet.priceFractional,
              tempBet.priceBookType
            );
            x.notificationPriceChangeType =
              tempBet.priceDecimal > sdata.price.decimal
                ? Constants.DEC
                : Constants.INC;
          } else {
            // when notification parma is default then no notification directly price decress | Increases
            // when notificationParam === 'lower' or Price going higher in that case it update in BG.
            x.updateNewPrice = true;
            x.priceChangeNotification = false;
          }
          x.price[0] = sdata.price;
          x.priceDecimal = sdata.price.decimal;
          x.priceFractional = sdata.price.fractional;
          x.priceId = sdata.price.id;
          tempBetData.push(x);
          set = true;
        } else if (sdata.active) {
          // when bet is deactive and some active or change price
          const tempBet = Object.assign({}, bet);
          const x = { ...bet };
          x.active = true;
          x.priceChangeNotification = true;
          x.updateNewPrice = false;
          x.previousPriceDecimal = previousPrice(
            tempBet.priceDecimal,
            tempBet.priceBookType
          );
          x.previousPriceFractional = previousPrice(
            tempBet.priceFractional,
            tempBet.priceBookType
          );
          x.notificationPriceChangeType =
            tempBet.priceDecimal > sdata.price.decimal
              ? Constants.DEC
              : Constants.INC;
          x.priceDecimal = sdata.price.decimal;
          x.priceFractional = sdata.price.fractional;
          x.priceId = sdata.price.id;
          x.price[0] = sdata.price;
          tempBetData.push(x);
          set = true;
        }
      }
    }
    if (!set) {
      tempBetData.push(bet);
    }
  }

  return tempBetData;
};
/**
 *  genrate Casting tabs
 * @param {Array} betTemplate
 */

export const genrateCastingForUS = (betTemplate, displaymultiplesfirst) => {
  if (displaymultiplesfirst) {
    betTemplate.sort(function (a, b) {
      return (
        (b.multiple && b.betModifierBetCount === 1) -
        (a.multiple && a.betModifierBetCount === 1)
      );
    });
  }
  let slpiceKey = null;
  let ukBetTemplate = new Array();
  ukBetTemplate = [...betTemplate];
  let onlyMultiple = {};
  const castOrder = {};
  let castName = '';
  for (const bet of ukBetTemplate) {
    if (bet['multiple'] && bet.name === 'Forecast') {
      castName = Constants.FORCAST;
    } else if (bet['multiple'] && bet.name === 'Tricast') {
      castName = Constants.FORCASTTricast;
    }
  }

  ukBetTemplate.forEach((bet, key) => {
    if (
      !slpiceKey &&
      bet['multiple'] &&
      ['Forecast', 'Tricast'].includes(bet['name'])
    ) {
      slpiceKey = displaymultiplesfirst ? key : key + 1;
      onlyMultiple = {
        tab: 'multiple',
        actTab: Constants.MULTIPLE,
        display: castName,
        delete: false,
        multiple: true,
        catRef: bet.catRef,
      };

      // cast-order
      castOrder['description'] = bet['description'];
      castOrder['key'] = slpiceKey + 1;
      castOrder['type'] = 'castorder';
      castOrder['catRef'] = bet.catRef;
      castOrder['multiple'] = true;
    }
  });

  if (slpiceKey) ukBetTemplate.splice(slpiceKey, 0, onlyMultiple);
  if (Object.keys(castOrder).length > 0) {
    ukBetTemplate.splice(castOrder['key'], 0, castOrder);
  }
  return ukBetTemplate;
};

/**
 * Get data from Hash Params
 * @param {Array} betTemplate array of bets
 * @param {string} localStorageKey storage key
 */
export const removeUserDependentItems = (betTemplate, localStorageKey) => {
  for (const bet of betTemplate) {
    if (bet['hasFreebet']) {
      bet['hasFreebet'] = false;
      bet['freebetCredit'] = '';
      bet['freebetCreditFormatted'] = '';
      bet['selectedFreebetId'] = '';
      bet['returnsText'] = 0;
      bet['_returnsFormatted'] = 0;
      bet['_returns'] = 0;
      bet['totalStake'] = '';
      bet['userStake'] = '';
    }
  }

  setterGetterFromLocalstorage({
    keyName: localStorageKey,
    keyValue: betTemplate,
    action: 'set',
  });
  return betTemplate;
};

/**
 * Get data from Hash Params
 * @param {object} window window Object
 */

export const dataFromHashParams = window => {
  if (window.location.hash.indexOf('sid') !== -1) {
    let hashData = window.location.hash;
    hashData = hashData.replace('#sid=', '');
    const amtObj = [];
    hashData = hashData.split(',').map(i => {
      // check | user stake
      if (i.indexOf('|') > -1) {
        const SidStk = i.split('|');
        i = SidStk[0];
        const a = {};
        a[i] = Number(SidStk[1]);
        amtObj.push(a);
      }
      return Number(i);
    });
    return { sid: hashData, amtObj: amtObj };
  } else {
    return null;
  }
};
/**
 * Get data from Query Params
 * @param {object} window window Object
 */

export const dataFromQueryParams = window => {
  if (window.location.search.indexOf('sid') !== -1) {
    let queryString = new URLSearchParams(window.location.search);
    queryString = queryString.get('sid');
    const amtObj = [];
    queryString = queryString.split(',').map(i => {
      // check | user stake
      if (i.indexOf('|') > -1) {
        const SidStk = i.split('|');
        i = SidStk[0];
        const a = {};
        a[i] = Number(SidStk[1]);
        amtObj.push(a);
      }
      return Number(i);
    });
    return { sid: queryString, amtObj: amtObj };
  } else {
    return null;
  }
};

// detect Mobile device
export const detectMob = () => {
  try {
    return window.innerWidth <= parseInt(BREAKPOINT_L);
  } catch (error) {
    return null;
  }
};

/**
 * @param {string} name string
 * @param {object} betMf betModifier Object
 */
export const genraterName = (name, betMf) => {
  if (name === 'Forecast' && betMf?.type) {
    return betMf.type === 'WIN'
      ? 'Straight Forecast'
      : capitalizeFirstLetter(betMf.type) + ` Forecast`;
  } else if (name === 'Tricast' && betMf?.type) {
    return betMf.type === 'WIN'
      ? 'Straight Tricast'
      : capitalizeFirstLetter(betMf.type) + ` Tricast`;
  } else {
    return name;
  }
};

/**
for geting correct priceIds
 @params localstorage object
*/

export const getPriceIdsbookType = (localStorageData, bookType) => {
  let arr = [];
  const singleBets = localStorageData
    .filter(itm => {
      return itm.betTypeRef === 'SGL' && itm;
    })
    .sort((a, b) => {
      return a.castPosition - b.castPosition;
    });

  try {
    for (const item of singleBets) {
      if (item.betTypeRef === 'SGL') {
        const { price } = item;
        for (const pr of price) {
          if (pr && pr['bookType'] === bookType) {
            arr.push(pr['id']);
          } else if (!bookType) {
            // for straight scorecast card bets
            arr.push(pr['id']);
          }
        }
      }
    }
  } catch (error) {
    arr = [];
  }

  return arr;
};
/**
 *  Find market id exist in bets
 * @param {Array} betTemplate
 * @param {object} wsData
 */

export const findMarket = (betTemplate, wsData) => {
  let mB = false;
  if (betTemplate) {
    for (const bet of betTemplate) {
      if (bet.marketId === wsData.id) {
        mB = true;
      }
    }
  }
  return mB;
};

/**
 *  when market suspended
 * @param {Array} betTemplate
 * @param {object} wsData
 */

export const suspendSelectedMarket = (wsData, betTemplate) => {
  if (betTemplate) {
    for (const bet of betTemplate) {
      if (bet.marketId === wsData.id) {
        bet.active = false;
      }
    }
  }
  return betTemplate;
};

/**
 * handle Builder Object
 *  @param {object} apiData data to api
 */

export const generateBetBuilderObject = apiData => {
  let sentenceStr = '';
  if (apiData.sentence.length > 0)
    sentenceStr =
      '&sentence=' + apiData.sentence.map(sen => sen.key).join('&sentence=');

  const builderObj = {
    multiple: false,
    active: true,
    eventId: apiData.eventId,
    eventName: apiData.eventName,
    fractional: apiData.fractional,
    price: apiData.price,
    sentence: apiData.sentence,
    text: apiData.text,
    betMode: 'betbuilder',
    betTypeRef: Constants.BBB,
    betModifier: 'WIN',
    catRef: 'SOCCER',
    category: 'SOCCER',
    returnsText: '',
    hasFreebet: false,
    selectedFreebetId: '',
    freebetCredit: null,
    selectedIndex: null,
    freebetCreditFormatted: null,
    id: apiData.eventId + sentenceStr,
    marketActive: true,
    allowCreditWallet: false,
    bbFlag: '',
    freebetId: false,
    userStake: '',
    betModifierReturns: '',
    betModifierBetCount: 1,
    selectionId: apiData.eventId + sentenceStr,
    fetchSentence: sentenceStr,
    _returns: 0,
    _returnsFormatted: '',
    castPosition: 1,
    allowBonusFunds: apiData.allowBonusFunds,
  };
  return builderObj;
};

/**
 * find builder data from LocalStorage
 *  @param {string} key localstorage key
 */

export const getBetBuilderData = key => {
  const localData = setterGetterFromLocalstorage({
    keyName: key,
    action: 'get',
  });
  return localData
    .map(item => {
      if (
        item &&
        typeof item['selectionId'] !== 'undefined' &&
        item['betTypeRef'] === Constants.BBB
      ) {
        return item;
      }
    })
    .filter(item => item);
};

/**
 * merge data in BetTemplate Array when data come to bet builder
 * @param {Array} betTemplate prev state array od BT
 * @param {Array} builderData next state of builder data
 */
export const mergeBuilderDataInTemplate = (betTemplate, builderData) => {
  if (betTemplate.length > 0) {
    return mergeByProperty(betTemplate, builderData, 'id');
  } else {
    return betTemplate.concat(builderData);
  }
};
/**
 *  helper function of data merge
 * @param {Array} target main array {bettemplate}
 * @param {Array} source new array {source}
 * @param {string} prop key name  {id}
 */
const mergeByProperty = (target, source, prop) => {
  source.forEach(sourceElement => {
    const targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop];
    });
    targetElement
      ? Object.assign(targetElement, sourceElement)
      : target.push(sourceElement);
  });

  return target;
};

/**
 * sort data for CreateBet
 * @param {Array} betTemplate
 */
export const handleSortCreateBet = betTemplate => {
  let k = 1;
  let BT = new Array();
  BT = betTemplate
    .map((item, key) => {
      if (item.tab === Constants.SINGLE) {
        k = key;
      }
      if (item.betTypeRef === Constants.SINGLE) {
        k = key + k;
      }
      if (item && item['betTypeRef'] !== Constants.BBB) {
        return item;
      }
    })
    .filter(item => item);

  const build = betTemplate
    .map(item => {
      if (
        item &&
        typeof item['selectionId'] !== 'undefined' &&
        item['betTypeRef'] === Constants.BBB
      ) {
        return item;
      }
    })
    .filter(item => item);

  BT.splice(k, 0, ...build);
  return BT;
};

/**
 * update Upcoming FavouritesAmt
 * @param {Array} item
 * @param {number} upcomingFavAmt
 */

export const upcomingFavouritesAmount = (item, upcomingFavAmt) => {
  item['userStake'] = Number(upcomingFavAmt);
  item['totalStake'] = Number(upcomingFavAmt);
  item['returnsText'] = (
    item.betModifierReturns * Number(upcomingFavAmt)
  ).toFixed(2);
  item['_returns'] = item.betModifierReturns * Number(upcomingFavAmt);
  item['_returnsFormatted'] = item.betModifierReturns * Number(upcomingFavAmt);

  return item;
};

/**
 * update amount comes form Url
 * @param {Array} item
 * @param {Array} element
 * @param {object} priceData
 */

export const updateAmountComeToUrl = (item, priceData, element) => {
  item['userStake'] = Number(element[item['id']]);
  item['totalStake'] = Number(element[item['id']]);
  item['returnsText'] = parseDecimalPlaces(
    priceData.decimal * Number(element[item['id']])
  );
  item['_returns'] = priceData.decimal * Number(element[item['id']]);
  item['_returnsFormatted'] = priceData.decimal * Number(element[item['id']]);
  return item;
};

/**
 * blank when retain bet
 */
export const retainCleanupBet = bet => {
  bet['_returns'] = 0;
  bet['_returnsFormatted'] = 0;
  bet['returnsText'] = 0;
  bet['userStake'] = '';
  bet['totalStake'] = 0;
  bet['freebetCredit'] = 0;
  bet['freebetCreditFormatted'] = 0;
  bet['hasFreebet'] = false;
  bet['bbFlag'] = false;
  bet['priceDecimal'] = '';
  bet['priceFractional'] = '';
  return bet;
};

/**
 * find SP from betTemplate array
 */
export const isAnySPbet = () => {
  const localData = setterGetterFromLocalstorage({
    keyName: Constants.LSKEY_US,
    action: 'get',
  });
  let SPit = false;
  for (const bet of localData) {
    if (
      bet.priceBookType === Constants.SP &&
      bet.betTypeRef === Constants.SGL
    ) {
      SPit = true;
    }
  }

  return SPit;
};

/**
 * free bet svg
 * color "#FEE30E"
 * height
 * width
 */
export const FreeBetSvg = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20s"
      viewBox="0 0 12 12"
    >
      <g>
        <g>
          <path
            fill={color}
            d="M8.902.846a.777.777 0 0 0-1.159 0c-.243.253-.478.687-.647 1.1L7 2.135c-.123.401-.19.749-.107.839l.01.026h.131c.53 0 1.538-.576 1.868-.925a.905.905 0 0 0 0-1.23zM3.678.6a.796.796 0 0 0-.58.253.901.901 0 0 0 0 1.225c.33.348 1.338.922 1.867.922h.133l.009-.026c.082-.09.016-.437-.107-.836l-.096-.188c-.17-.412-.404-.846-.647-1.097A.795.795 0 0 0 3.677.6zM7.096.458c.587-.61 1.611-.611 2.2 0a1.653 1.653 0 0 1 0 2.275c-.082.086-.192.18-.296.267l-.123.08h2.373c.391 0 .712.309.75.702V5a.387.387 0 0 1-.324.396l-.051.004H6.75V3.853h-1.5V5.4H.375A.385.385 0 0 1 0 5.065V3.853c0-.403.3-.735.682-.771l.068-.003h2.373c-.17-.12-.317-.24-.42-.346A1.655 1.655 0 0 1 2.706.458c.587-.61 1.611-.61 2.2 0C5.216.782 6.03 2.068 6 3l-.027.08h.054C5.907 2.163 6.77.793 7.096.457zM11.429 6.6v4.8c0 .238-.298.554-.524.6H6.857V6.6zm-6.286 0V12h-4c-.214 0-.526-.3-.567-.548L.57 11.4V6.6z"
          />
        </g>
      </g>
    </svg>
  );
};

FreeBetSvg.propTypes = {
  color: PropTypes.string,
};

// get teaserBet data

export const getTeaserBetsData = selectionIds => {
  if (selectionIds && selectionIds[0] && selectionIds[0].length > 0) {
    const a = selectionIds[0];
    return a
      .map((sid, key) => {
        return key === 0 ? '?selectionId=' + sid : '&selectionId=' + sid;
      })
      .filter(bet => bet)
      .join('');
  } else {
    const a = selectionIds;
    return a
      .map((sid, key) => {
        return key === 0 ? '?selectionId=' + sid : '&selectionId=' + sid;
      })
      .filter(bet => bet)
      .join('');
  }
};

export const catchTeaserData = data => {
  setterGetterFromLocalstorage({
    keyName: Constants.BETSLIPUSTEASER,
    keyValue: data,
    action: 'set',
  });
};

export const checkLocalStorageTeaser = (apiData, key) => {
  const localData = setterGetterFromLocalstorage({
    keyName: key,
    action: 'get',
  });
  if (localData) {
    apiData['wager'] = localData['wager'];
    apiData['label'] = localData['label'];
    apiData['potRtn'] = localData['potRtn'];
    apiData['value'] = localData['value'];
  }

  return apiData;
};
// if roundroben tab or teaser tab data not exist in storage then we sitch to single tab
export const checkActiveTab = (data, activeTab) => {
  if (activeTab === Constants.MULTIPLE) {
    const multipleBet = data.some(
      bet => bet.multiple && bet.betModifierBetCount === 1
    );
    return multipleBet ? activeTab : Constants.SINGLE;
  } else {
    if (activeTab === Constants.SYSTEM) {
      const systemTabEnabled = data.some(
        bet =>
          bet.multiple &&
          bet.betModifierBetCount !== 1 &&
          Constants.SYSTEM_BET.find(bets => bets !== bet.betTypeRef)
      );
      if (systemTabEnabled) {
        return Constants.SYSTEM || activeTab;
      }
    }
    return activeTab;
  }
};

// delete Icon

export const DeleteIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="14"
    viewBox="0 0 11 14"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M5.74 5.811v5.878c0 .172-.108.311-.24.311s-.24-.14-.24-.311V5.811c0-.172.108-.311.24-.311s.24.14.24.311zM0 2.795c.09-.623.603-1.09 1.212-1.09h1.624v-.41C2.834 1 3 .62 3.202.376 3.438.133 3.758 0 4.092 0h2.816c.334 0 .654.133.89.376.202.244.368.624.366.92v.409h1.624c.609 0 1.123.467 1.212 1.09a1.274 1.274 0 0 1-.9 1.373v8.078c0 .477-.17.926-.466 1.247A1.535 1.535 0 0 1 8.5 14H2.499c-.429 0-.838-.184-1.133-.507a1.843 1.843 0 0 1-.465-1.247V4.168A1.274 1.274 0 0 1 0 2.795zm9.465 9.45V4.198h-7.93v8.049c0 .617.465 1.098.964 1.098h6.002c.499 0 .964-.481.964-1.098zM3.47 1.706h4.06v-.41c.002-.17-.063-.295-.18-.455a.601.601 0 0 0-.442-.184H4.092A.6.6 0 0 0 3.65.84c-.117.16-.182.284-.18.455zM.64 3c0 .277.256.541.572.541h8.576c.316 0 .571-.264.571-.541 0-.375-.255-.64-.57-.64H1.211C.896 2.36.64 2.626.64 3zm3.186 2.811v5.878c0 .172-.107.311-.24.311-.131 0-.238-.14-.238-.311V5.811c0-.172.107-.311.239-.311s.239.14.239.311zm3.826 0v5.878c0 .172-.107.311-.239.311s-.24-.14-.24-.311V5.811c0-.172.108-.311.24-.311s.24.14.24.311z"
        />
      </g>
    </g>
  </svg>
);

DeleteIcon.propTypes = {
  color: PropTypes.string,
};

/**
 * Added event name in virtuals descrption
 * @param {string} catRef
 * @param {string} descTime
 */
export const virtualsDescription = (catRef, descTime) => {
  const regex = new RegExp(Constants.HHMM);
  let result = '';
  if (project.JpTimeFormat && catRef === Constants.VIRTUAL) {
    const isTimePresent = descTime?.split(' ')?.slice(-1);
    if (regex.test(isTimePresent)) {
      result = `${descTime?.split(' ')?.slice(0, -1).join(' ')} `;
    } else {
      result = `${descTime} `;
    }
  }
  return result;
};

/**
 * for updated time in betslip
 * @param {string} timeStamp
 * @param {string} descTime
 */
export const formatTimeStamp = timeStamp => {
  return moment(timeStamp).format(
    project.timeFormats.racing.quaternary || 'HH:mm'
  );
};
/**
 * get only which selection id which is active or single
 * @param {object} localStorageData
 */
export const getSelectionIdsWithActiveStatus = localStorageData => {
  let bsid = '';
  let bbid = '';
  if (localStorageData && localStorageData.length > 0) {
    bsid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] !== Constants.BBB &&
          (isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);

    bbid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] === Constants.BBB &&
          (!isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);
  }
  return { bsid, bbid };
};

export const notificationValues = param => {
  let value = '';
  switch (param) {
    case Constants.BLOCK: {
      value = Constants.REQUESTED;
      break;
    }
    case Constants.DEFAULT: {
      value = Constants.CURRENT;
      break;
    }
    case Constants.LOWER: {
      value = Constants.HIGHER_C;
      break;
    }
    case Constants.HIGHER: {
      value = Constants.LOWER_C;
      break;
    }
    // reverse
    case Constants.REQUESTED: {
      value = Constants.BLOCK;
      break;
    }
    case Constants.CURRENT: {
      value = Constants.DEFAULT;
      break;
    }
    case Constants.HIGHER_C: {
      value = Constants.LOWER;
      break;
    }
    case Constants.LOWER_C: {
      value = Constants.HIGHER;
      break;
    }
  }
  return value;
};

// add dynamic string to the text
export const getTranslation = (bet, string, countAccaBet) => {
  let stringTranslated = string.split('{0}');
  let bonusPercentage = 0;
  if (stringTranslated.length > 0) {
    stringTranslated[0] =
      stringTranslated[0] + ` ${bet?.nextBetBonus?.winCount - countAccaBet}`;
    string = stringTranslated.join('');
  }
  stringTranslated = string.split('{1}');
  if (stringTranslated.length > 1) {
    stringTranslated[1] =
      ` ${bet?.nextBetBonus?.percentage}` + stringTranslated[1];
    bonusPercentage = stringTranslated[1];
    string = stringTranslated.join('');
  }
  return { string, bonusPercentage };
};

/**
 * for validation of booster bets
 * @param {object} localStorageData
 * @param {object} data
 */
export const validateBetBooster = (data, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });

  for (const itm of localData) {
    let { price } = itm;
    price = price?.[0] || {};

    if (itm['bbFlag'] && itm['eventId'] === data['eventId']) {
      itm['bbFlag'] = false;
      itm['priceDecimal'] = price['decimal'];
      itm['priceFractional'] = price['fractional'];
    }
  }

  setterGetterFromLocalstorage({
    keyName: localStorageKey,
    keyValue: localData,
    action: 'set',
  });
};

export const getBetNameForPickRoundRobinByTypeRef = (
  bet,
  pickRoundRobinText
) => {
  let count;
  switch (bet.betTypeRef) {
    case 'DBL':
      // Double
      count = 2;
      break;
    case 'TBL':
      // Treble
      count = 3;
      break;
    case '4FD':
      // Fourfold
      count = 4;
      break;
    case '5FD':
      // Fivefold
      count = 5;
      break;
    case '6FD':
      // Sixfold
      count = 6;
      break;
    case '7FD':
      // Sevenfold
      count = 7;
      break;
    case '8FD':
      // Eightfold
      count = 8;
      break;
    case '9FD':
      // Ninefold
      count = 9;
      break;
    case '10F':
      // 10-fold
      count = 10;
      break;
    case '11F':
      // 11-fold
      count = 11;
      break;
    case '12F':
      // 12-fold
      count = 12;
      break;
    case '13F':
      // 13-fold
      count = 13;
      break;
    case '14F':
      // 14-fold
      count = 14;
      break;
    case '15F':
      // 15-fold
      count = 15;
      break;
  }
  if (count) {
    return getInterpolatedParametersInTranslation(pickRoundRobinText, {
      count,
    });
  }
  return bet.name;
};

export const getBetNameForPickRoundRobinByTypeName = (
  bet,
  pickRoundRobinText
) => {
  let count;
  switch (
    bet.betUntranslatedTypeName?.toUpperCase() ||
    bet.betTypeName?.toUpperCase()
  ) {
    case 'DOUBLE':
      // Double DBL
      count = 2;
      break;
    case 'TREBLE':
      // Treble TBL
      count = 3;
      break;
    case 'FOURFOLD':
      // Fourfold 4FD
      count = 4;
      break;
    case 'FIVEFOLD':
      // Fivefold 5FD
      count = 5;
      break;
    case 'SIXFOLD':
      // Sixfold 6FD
      count = 6;
      break;
    case 'SEVENFOLD':
      // Sevenfold 7FD
      count = 7;
      break;
    case 'EIGHTFOLD':
      // Eightfold 8FD
      count = 8;
      break;
    case 'NINEFOLD':
      // Ninefold 9FD
      count = 9;
      break;
    case '10-FOLD':
      // 10-fold 10F
      count = 10;
      break;
    case '11-FOLD':
      // 11-fold 11F
      count = 11;
      break;
    case '12-FOLD':
      // 12-fold 12F
      count = 12;
      break;
    case '13-FOLD':
      // 13-fold 13F
      count = 13;
      break;
    case '14-FOLD':
      // 14-fold 14F
      count = 14;
      break;
    case '15-FOLD':
      // 15-fold 15F
      count = 15;
      break;
  }
  if (count) {
    return getInterpolatedParametersInTranslation(pickRoundRobinText, {
      count,
    });
  }
  return null;
};

export const getBetCountByTypeRef = bet => {
  const betTypeRefCount = {
    SGL: 1,
    DBL: 2,
    TBL: 3,
    '4FD': 4,
    '5FD': 5,
    '6FD': 6,
    '7FD': 7,
    '8FD': 8,
    '9FD': 9,
    '10F': 10,
    '11F': 11,
    '12F': 12,
    '13F': 13,
    '14F': 14,
    '15F': 15,
  };
  return betTypeRefCount[bet.betTypeRef];
};

export const marketRefCount = (betTemplate, marketTypeRef) => {
  return betTemplate
    .filter(itm => itm.betTypeRef === 'SGL')
    .reduce(
      (count, bet) =>
        marketTypeRef?.includes(bet.marketTypeRef) ? count + 1 : count,
      0
    );
};
