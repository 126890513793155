import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../core__betslip-constants';
import { Translations } from '../../../../core__betslip-SA-app';
import { OddMovementsDropDown } from 'UI/lines/dropdowns/OddMovementsSelect';
import {
  OddsChangeWrapper as OddsChangeWrapper_CORE,
  OddsChangeContainer as OddsChangeContainer_CORE,
  OddsChangeTitle as OddsChangeTitle_CORE,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__oddsChanges';
import {
  OddsChangeWrapper as OddsChangeWrapper_LOCAL,
  OddsChangeContainer as OddsChangeContainer_LOCAL,
  OddsChangeTitle as OddsChangeTitle_LOCAL,
} from 'UI/apps/BetslipSAApp/BetslipContainer/OddChanges';
import { getComponent } from 'Services/core__imports';

const OddsChangeWrapper = getComponent(
  OddsChangeWrapper_LOCAL,
  OddsChangeWrapper_CORE
);
const OddsChangeContainer = getComponent(
  OddsChangeContainer_LOCAL,
  OddsChangeContainer_CORE
);
const OddsChangeTitle = getComponent(
  OddsChangeTitle_LOCAL,
  OddsChangeTitle_CORE
);

const labelsText = {
  higher: 'Only Higher',
  block: 'Off',
  default: 'All odds',
};

export const OddChanges = ({
  handleActTabNotificationPopup,
  notificationParam,
  betTemplate,
  changeDDIndicator,
}) => {
  return (
    <OddsChangeWrapper>
      <OddsChangeContainer>
        <OddsChangeTitle>
          {Translations.get('text.accept.odds.changes')}
        </OddsChangeTitle>
        <OddMovementsDropDown
          data-test="odds-oddmovement-change"
          defaultLabel={labelsText[notificationParam]}
          defaultValue={notificationParam}
          onChange={e => handleActTabNotificationPopup(e?.value)}
          options={[
            {
              label: Translations.get('text.all.odds'),
              value: Constants.DEFAULT,
            },
            {
              label: Translations.get('text.only.higher'),
              value: Constants.HIGHER,
            },
            {
              label: Translations.get('text.control.off'),
              value: Constants.BLOCK,
            },
          ]}
          isSelected={notificationParam}
          menuPlacement={
            (betTemplate.length >= 2 && Constants.TOP) || Constants.BOTTOM
          }
          changeDDIndicator={changeDDIndicator}
        />
      </OddsChangeContainer>
    </OddsChangeWrapper>
  );
};

OddChanges.propTypes = {
  handleActTabNotificationPopup: PropTypes.func,
  notificationParam: PropTypes.string,
  betTemplate: PropTypes.array,
  changeDDIndicator: PropTypes.bool,
};
