import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { DH5 } from 'UI/apps/BetslipSAApp/BetslipContainer/Description';
import { Translations } from '../../../../../core__betslip-SA-app';
import project from '../../../../../../../../project';
import { formatTimeStamp } from '../../../../../core__betslip-utils';

/** 
genrateDescription:
split by (|) and print the description

@param {*} desc (this is coming via api bets.description and data is | seprated)

@param {*} desc (this is coming via api bets.description and data is | seprated)
@param {bool} active 
@param {string} catRef 
@param {string} schedule
*/

export const Description = ({
  desc,
  active,
  catRef,
  schedule,
  extraText,
  actTab,
}) => {
  const descArr = desc && desc.split('|');
  return (
    <DH5 active={active} actTab={actTab}>
      {project?.isParlay &&
        (actTab === Constants.MULTIPLE || actTab === Constants.TEASER) && (
          <DH5 br={true}>{Translations.get('text.spread.betting')}</DH5>
        )}

      {extraText &&
        project?.isParlay &&
        actTab === Constants.SINGLE &&
        extraText + ' '}

      {([Constants.HORSES, Constants.DOGS].includes(catRef) &&
        formatTimeStamp(schedule, descArr[descArr.length - 2]) +
          ' ' +
          descArr[descArr.length - 3] +
          ' ' +
          descArr[descArr.length - 1]) ||
        descArr[descArr.length - 1] + '- ' + descArr[descArr.length - 2]}
    </DH5>
  );
};

Description.propTypes = {
  desc: PropTypes.string,
  active: PropTypes.bool,
  catRef: PropTypes.string,
  extraText: PropTypes.string,
  extraTextSingle: PropTypes.string,
  extraTextMultOrTeaser: PropTypes.string,
  actTab: PropTypes.string,
  schedule: PropTypes.number,
};

Description.defaultProps = {
  extraText: '',
  actTab: 'single',
};
