import React from 'react';
import PropTypes from 'prop-types';
import { MSSD, M2, MSB } from 'UI/apps/BetslipSAApp/BetslipContainer/Error';
import Constants from '../../../../../../../../project';
import { CurrencyFormat } from 'Services/global/core__currency-format';

/**
 @param {string} message
 @param {func} setErrorObj
 */
export const ErrorComponent = ({ message, setErrorObj, isMaxBetExceed }) => {
  if (message.indexOf(Constants.currency) > -1) {
    // handle currency code from API error message
    message = message.replace(
      Constants.currency,
      CurrencyFormat(Constants.currency, true)
    );
  }
  if (isMaxBetExceed) {
    message = message.replace(':', ':-').replace(';', ',');
  }

  return (
    message && (
      <MSSD isMaxBetExceed={isMaxBetExceed}>
        <M2 isMaxBetExceed={isMaxBetExceed}>{message}</M2>
        <MSB
          isMaxBetExceed={isMaxBetExceed}
          onClick={() =>
            setErrorObj({
              isError: false,
              message: '',
            })
          }
        >
          {' '}
          {!isMaxBetExceed && <React.Fragment>&times;</React.Fragment>}
        </MSB>
      </MSSD>
    )
  );
};
ErrorComponent.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
  setErrorObj: PropTypes.func,
  isMaxBetExceed: PropTypes.bool,
};
