import React from 'react';
import PropTypes from 'prop-types';
import { genraterName } from '../../../../../core__betslip-utils';
import { BL, BH } from 'UI/apps/BetslipSAApp/BetslipContainer/Name';
import { Constants } from '../../../../../core__betslip-constants';
import { Translations } from '../../../../../core__betslip-SA-app';

/**
 * @param {object} bet
 * @param {object} betMf
 */

export const OddName = ({ bet, betMf }) => {
  return (
    <BL>
      <BH active={bet.active} multiple={bet.multiple}>
        {bet?.ordinal && '(' + bet.ordinal + ') '}
        {(bet.name && genraterName(bet.name, betMf)) ||
          (bet.betTypeRef === Constants.BBB &&
            Translations.get('text.create.a.bet'))}
      </BH>
      {bet?.betBonus && !bet?.hideBetBonus && (
        <BH bonus>
          +{bet.betBonus?.percentage}
          {Translations.get('text.bonus.percentage')}
        </BH>
      )}
    </BL>
  );
};
OddName.propTypes = {
  bet: PropTypes.object,
  betMf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
