import styled, { css } from 'styled-components';
import { FUNCTIONAL, GREYS } from 'UI/globals/colours';
export const STAKECONTAINER = styled.div`
  display: ${({ active }) => (active ? 'none' : 'block')};
  font-size: 14px;
  top: -12px;
  position: relative;
  margin-left: 4px;
  color: ${FUNCTIONAL.error ? FUNCTIONAL.error : GREYS.black};
  text-align: left;
  ${({ hasStake }) =>
    !hasStake &&
    css`
      display: none;
    `}
  ${({ textMsg }) =>
    textMsg &&
    css`
      display: block;
    `}
`;

export const MAXBETUPDATE = styled.span`
  text-decoration: underline;
`;
