import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  C,
  CL,
  Ch5,
  D,
  DBTN,
  DS,
  DChekBx,
  SBSPB,
  SBSD,
} from 'UI/apps/BetslipSAApp/ReceiptContainer/BottomSection';
import { Translations } from '../../../../../core__betslip-SA-app';
import { BetslipCurrency } from '../../../BetslipContainer/BetslipContent/elements';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';

/**
 * BottomSection genrate total amount and retain option
 * @param betSlipRecipt {object}
 * @param handleReceptCloseButton {function}
 */

export const BottomSection = ({
  betSlipRecipt,
  handleReceptCloseButton,
  isMaxBetError,
  isLogin,
  isError,
  bottomNavData,
}) => {
  const [retainOption, setRetainOption] = useState(false);
  const handleRetain = check => {
    setRetainOption(check);
  };

  const totalBalance =
    betSlipRecipt.balance + (betSlipRecipt.withdrawableBalance || 0);

  if (isLogin && isError && isMaxBetError) {
    return (
      <SBSD>
        <SBSPB active={true} disabled={false}>
          {Translations.get('text.chat.with.broker')}
        </SBSPB>
      </SBSD>
    );
  }

  return (
    <D bottomNavEnabled={bottomNavData?.loginLinks}>
      <label>
        <DChekBx
          type="checkbox"
          onChange={e => {
            handleRetain(e.target.checked);
          }}
        />
        <DS>{Translations.get('text.retain.selections.on.the.betslip')}</DS>
      </label>
      <C>
        <CL>
          <Ch5>{Translations.get('text.remaining.balance')}</Ch5>
        </CL>
        <CL bold={true}>
          <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
          {parseDecimalPlaces(totalBalance)}
        </CL>
      </C>

      <DBTN type="button" onClick={() => handleReceptCloseButton(retainOption)}>
        {Translations.get('text.close.recipt')}
      </DBTN>
    </D>
  );
};
BottomSection.propTypes = {
  betSlipRecipt: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  isMaxBetError: PropTypes.bool,
  isLogin: PropTypes.bool,
  isError: PropTypes.bool,
  bottomNavData: PropTypes.object,
};
