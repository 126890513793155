import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BetslipHeader } from './elements';
import { CalCount, detectMob } from '../../../../core__betslip-utils';
import addCustomGTMEventToDataLayer, {
  gtmEventNames,
} from 'Services/gtm/core__addCustomGTMEventToDataLayer';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

/** 
betslip header
that show header of betslip
@param {Array}  betTemplate (for count )
*/
const Header = ({
  betTemplate,
  isLogin,
  handleOpenBS,
  openBS,
  oddsFormat,
  actTab,
}) => {
  const count = CalCount(betTemplate);
  const [dowmIcon, handledownIcon] = useState(openBS);
  const [isMobile, setIsMobile] = useState(false);
  // hide empty betslip on mobile
  const hideBetSlipOnMobile = isMobile && count === 0 && !openBS;

  useEffect(() => {
    const openBetslip = PubSub.listen(PubsubEvents.OPEN_BETSLIP, wsData => {
      if (wsData) expendandcollapse();
    });
    return () => {
      openBetslip.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isMobileValue = detectMob();
    setIsMobile(isMobileValue);

    window.addEventListener('resize', () => {
      const isMobileValue = detectMob();
      setIsMobile(isMobileValue);
    });

    return window.removeEventListener('resize', () => {
      const isMobileValue = detectMob();
      setIsMobile(isMobileValue);
    });
  }, []);

  const expendandcollapse = useCallback(() => {
    if (detectMob()) {
      if (!dowmIcon) {
        handleOpenBS(true);
        setTimeout(() => {
          handledownIcon(true);
        }, 100);

        // if the betslip opens (on mobile), send the bet-open GTM event
        addCustomGTMEventToDataLayer({
          event: gtmEventNames.BET_OPEN,
          data: {
            location: window.location.pathname,
            product: window.location.pathname.split('/').filter(v => v)[0],
          },
        });
      } else if (dowmIcon) {
        handleOpenBS(false);
        handledownIcon(false);
      }
    }
  }, [dowmIcon, handleOpenBS]);

  // when clearing the betslip, hide the betslip (on mobile)
  useEffect(() => {
    if (betTemplate.length === 0 && openBS) handleOpenBS(false);
  }, [betTemplate, openBS]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      {!hideBetSlipOnMobile && (
        <BetslipHeader
          count={count}
          actbet="bet"
          isLogin={isLogin}
          expendandcollapse={expendandcollapse}
          dowmIcon={dowmIcon}
          isMobile={isMobile}
          betTemplate={betTemplate}
          oddsFormat={oddsFormat}
          actTab={actTab}
        />
      )}
    </React.Fragment>
  );
};

export { Header };

Header.propTypes = {
  betTemplate: PropTypes.array,
  isLogin: PropTypes.bool,
  openBS: PropTypes.bool,
  handleOpenBS: PropTypes.func,
  oddsFormat: PropTypes.string,
  actTab: PropTypes.string,
};
Header.defaultProps = {
  openBS: true,
};
