const featureNameToDetailsDictionary = {
  livechat: {
    iconName: 'icon-chat',
    title: 'text.live.chat',
    description: 'text.live.chat.description',
    buttonLabel: 'text.chat',
    href: '/contact-us/',
  },
  safergambling: {
    iconName: 'icon-safegambling',
    title: 'text.safer.gambling',
    description: 'text.safer.gambling.description',
    href: '/responsible-gambling/',
  },
  betbuilder: {
    iconName: 'icon-Sports',
    title: 'text.football.bet.builder',
    description: 'text.football.bet.builder.description',
    href: '/football-bet-builder/',
  },
  streaminghighlights: {
    iconName: 'icon-live-streaming',
    title: 'text.live.streaming.highlights',
    description: 'text.live.streaming.highlights.description',
    href: '/live-streaming-highlights/',
  },
};

export const defaultFeatures = Object.keys(featureNameToDetailsDictionary);

// convert feature names appConfig string into mappable array
export const adaptFeatures = ({ Translations, features = '' }) =>
  features.split(',').reduce((acc, featureName) => {
    // if featureName not in the dictionary, then skip that featureName
    if (!featureNameToDetailsDictionary[featureName]) return acc;

    const {
      iconName,
      title,
      description,
      href,
      buttonLabel,
    } = featureNameToDetailsDictionary[featureName];

    const adpatedFeature = {
      iconName,
      title: Translations.get(title),
      description: Translations.get(description),
      href,
      ...(buttonLabel && { buttonLabel: Translations.get(buttonLabel) }), // optional
    };

    return [...acc, adpatedFeature];
  }, []);
