import React from 'react';
import PropTypes from 'prop-types';
import MB from 'UI/buttons/ModalButton';
import { Constants } from '../../../../../core__betslip-constants';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import { BBS } from 'UI/apps/BetslipSAApp/BetslipContainer/Booster';
import { Translations } from '../../../../../core__betslip-SA-app';
import { HighlightText } from 'UI/apps/BetslipSAApp/booster';

/**
 * @param {*} boosterkey string unique key
 * @param {*} fractionalReturns string return getting in betModifier
 * @param {*} returns number getting in betModifier
 * @param {*} boostedFractionalReturns string getting in boostedFractionalReturns
 * @param {*} boostedReturns number getting from booster return
 * @param {*} bbFlag boolen
 *
 */
export const BetslipContentBooster = ({
  boosterkey,
  fractionalReturns,
  boostedFractionalReturns,
  boostedReturns,
  handleBooster,
  bbFlag,
  oddsFormat,
  price,
  betModifier,
  betModifierFlag,
}) => {
  const unboostPrice =
    (oddsFormat === Constants.DECIMAL && parseDecimalPlaces(price.decimal)) ||
    (oddsFormat === Constants.AMERICAN && getOdds(price.decimal, true)) ||
    price.fractional;

  let boostPrice = '';
  if (betModifierFlag && betModifier[0]) {
    boostPrice =
      (oddsFormat === Constants.DECIMAL &&
        parseDecimalPlaces(betModifier[0]['boostedReturns'])) ||
      (oddsFormat === Constants.AMERICAN &&
        getOdds(betModifier[0]['boostedReturns'], true)) ||
      betModifier[0]['boostedFractionalReturns'];
  } else {
    boostPrice =
      (oddsFormat === Constants.DECIMAL &&
        parseDecimalPlaces(boostedReturns)) ||
      (oddsFormat === Constants.AMERICAN && getOdds(boostedReturns, true)) ||
      boostedFractionalReturns;
  }
  return (
    <BBS key={boosterkey} bbFlag={bbFlag}>
      {bbFlag ? (
        <MB
          data-booster-button="unboost"
          bbFlag={bbFlag}
          onClick={() => {
            handleBooster({
              fretn: fractionalReturns,
              retn: price.decimal,
              bbFlag: false,
            });
          }}
          label={`
              ${unboostPrice} <<< ${Translations.get('text.unboost')}
            `}
          boosted={true}
          data-button="booster"
          rank={`secondary`}
        ></MB>
      ) : (
        <MB
          data-booster-button="boost"
          bbFlag={bbFlag}
          onClick={() => {
            handleBooster({
              fretn: boostedFractionalReturns,
              retn: boostedReturns,
              bbFlag: true,
            });
          }}
          label={
            <React.Fragment>
              <p>{Translations.get('text.boost')} &gt;&gt;&gt;&nbsp;</p>
              <HighlightText>{boostPrice}</HighlightText>
            </React.Fragment>
          }
          rank={`secondary`}
        ></MB>
      )}
    </BBS>
  );
};

BetslipContentBooster.propTypes = {
  boosterkey: PropTypes.string,
  fractionalReturns: PropTypes.string,
  returns: PropTypes.number,
  boostedFractionalReturns: PropTypes.string,
  boostedReturns: PropTypes.number,
  handleBooster: PropTypes.func,
  bbFlag: PropTypes.bool,
  oddsFormat: PropTypes.string,
  price: PropTypes.object,
  betModifier: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  betModifierFlag: PropTypes.bool,
};
