export const SOCCER = {
  markets: ['1x2,ET1x2,PSOW', 'OU,TGOU', 'BTTS', 'DC'],
};

export const ICE_HOCKEY = {
  markets: ['1x2WOOT,WMON', 'TGOU', 'SHC,SHC_LS,PL', '1x2OT', 'PERE', 'PGL'],
  american_market: {
    '1X2OT,WMON': 'Money Line',
    '1x2WOOT': 'Money Line',
    WMON: 'Money Line',
    'SHC,SHC_LS,PL': 'Spread',
    SHC_LS: 'Spread',
    PL: 'Spread',
    SHC: 'Spread',
    TGOU: 'Total',
  },
};

export const TENNIS = {
  markets: ['MWIN', 'MTGOU', 'TSW', 'SSWIN', 'SHC_S'],
};

export const BASKETBALL = {
  markets: ['MWIN', 'HRML,SHC_LS', 'RMLOU', 'FH_H_RML_2W'],
  american_market: {
    'HRML,SHC_LS': 'Spread',
    HRML: 'Spread',
    SHC_LS: 'Spread',
    RMLOU: 'Total',
    'MWIN,WMON': 'Money Line',
    MWIN: 'Money Line',
    WMON: 'Money Line',
  },
};

export const BEACH_VOLLEYBALL = { markets: ['1x2', 'MWIN'] };

export const HANDBALL = {
  markets: [
    '1x2',
    'TGOU',
    'SHC_NT',
    'HHCPNT',
    'HG_OU',
    '1x2T',
    'HTEAMGOALS',
    'ATEAMGOALS',
  ],
};

export const VOLLEYBALL = { markets: ['MWIN', 'SSWIN', 'SHC_NT', 'TPOU'] };

export const TABLE_TENNIS = { markets: ['MWIN', 'TPM', 'XGTP', 'XGHC', 'PH'] };

export const BASEBALL = {
  markets: [
    'WMON',
    'GTP_OU_RPML',
    'HC_RML',
    'QW',
    'QHC_2W_RML',
    'Q_OU_RML',
    'FH_HC_RML_2W',
    'FH1X2_NT',
    'FHGTP_OU_RML',
  ],
  american_market: {
    'MWIN,WMON': 'Money Line',
    MWIN: 'Money Line',
    WMON: 'Money Line',
    MR_OU: 'Total',
    SHC_NT: 'Run Line',
  },
};

export const AMERICAN_FOOTBALL = {
  markets: ['FH1X2_NT', 'NTHT', 'QFG', 'QT', 'QW', 'WMON'],
  american_market: {
    HC_RML: 'Spread', //events.american.spread
    GTP_OU_RPML: 'Total', //events.american.total
    WMON: 'Money Line', //events.american.money_line
  },
};

export const ESPORTS = { markets: ['MWIN', 'EAH', 'MPW'] };
export const BADMINTON = { markets: ['MWIN', 'TPOU', 'XGW', 'XGTP'] };

export const RUGBY_UNION = {
  markets: ['1x2', 'HC_RML', 'TP_RML'],
};

export const RUGBY_LEAGUE = {
  markets: ['1x2', 'HC_RML', 'TP_RML'],
};

export const SNOOKER = {
  markets: ['1x2,MWIN', 'SHC_NT', 'TF_OEU'],
};

export const DARTS = {
  markets: ['1x2,MWIN', 'SHC_NT', 'MTL_OU', 'M180_OU'],
};
export const ATHLETICS = {
  markets: ['OUT'],
};

export const BOXING = { markets: ['1x2', 'BOXDNB'] };

export const CRICKET = {
  markets: ['MWIN,1x2', 'OPAR,DNB'],
};

export const CYCLING = { markets: ['OUT'] };

export const GAELIC_FOOTBALL = {
  markets: ['1x2', 'HC'],
};

export const GAELIC_HURLING = {
  markets: ['1x2', 'HC'],
};

export const GOLF = { markets: ['3BALL'] };

export const MMA = { markets: ['MMADNB', 'MMAFGD'] };

export const BANDY = { markets: ['MMADNB'] };

export const MOTORSPORT = { markets: ['OUT'] };

export const OLYMPICS = { markets: ['OUT'] };

export const POLITICS = { markets: ['OUT'] };

export const SKIING = { markets: ['OUT'] };

export const SPECIALS = { markets: ['OUT'] };

export const FLOORBALL = { markets: ['1x2', 'DC'] };

export const FUTSAL = { markets: ['1x2', 'HC'] };

export const POOL = { markets: ['OUT'] };

export const AUSTRALIAN_RULES = { markets: ['MWIN'] };

export default {
  markets: ['1x2', 'MWIN', 'WMON', '1X2OT', 'ET1x2', 'PSOW', '3BALL', 'MMADNB'],
};
