import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { getComponent } from 'Services/core__imports';
import {
  OD,
  ODUL,
  TL as TL_Local,
  Button as Button_Local,
  MO as MO_Local,
  TS as TS_Local,
} from 'UI/apps/BetslipSAApp/BetslipContainer/OddMovements';
import {
  TL as TL_Core,
  Button as Button_Core,
  MO as MO_Core,
  TS as TS_Corel,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__oddMovements';
import { Translations } from '../../../../core__betslip-SA-app';
import { Constants } from '../../../../core__betslip-constants';

const TL = getComponent(TL_Local, TL_Core);
const Button = getComponent(Button_Local, Button_Core);
const MO = getComponent(MO_Local, MO_Core);
const TS = getComponent(TS_Local, TS_Corel);

/**
 * Odd movement
 * @param {func} handleActTabNotificationPopup
 */
export const OddMovements = ({
  handleActTabNotificationPopup,
  notificationParam,
}) => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef();
  const options = [
    { label: 'text.aaoc', value: Constants.DEFAULT },
    { label: 'text.awopi', value: Constants.LOWER },
    { label: 'text.off', value: Constants.BLOCK },
  ];
  const onClose = () => {
    setOpen(!open);
  };
  const onClickHandler = value => {
    handleActTabNotificationPopup(value);
    setOpen(!open);
  };
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        open && setOpen(!open);
      }
      modalRef.current &&
        modalRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [modalRef, open]);
  return (
    <OD>
      <ODUL>
        <li>
          <h5>
            {Translations.get('text.aaom')}{' '}
            <TS>
              {notificationParam === Constants.LOWER
                ? Translations.get('text.awopi')
                : notificationParam === Constants.DEFAULT
                ? Translations.get('text.aaoc')
                : Translations.get('text.off')}
            </TS>
          </h5>
        </li>
        <li>
          <Button onClick={() => setOpen(!open)}>&#8230;</Button>
          {open && (
            <MO onClick={onClose} ref={modalRef}>
              {options.map((node, ind) => (
                <TL
                  key={ind}
                  onClick={() => onClickHandler(node.value)}
                  isSelected={node.value === notificationParam}
                >
                  <div>{Translations.get(node.label)}</div>
                </TL>
              ))}
            </MO>
          )}
        </li>
      </ODUL>
    </OD>
  );
};
OddMovements.propTypes = {
  handleActTabNotificationPopup: PropTypes.func,
  notificationParam: PropTypes.string,
  betTemplate: PropTypes.array,
};
