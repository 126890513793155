import {
  BRB,
  BRBT,
  BRBBC,
  BRS,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__remainingboosts';
import styled from 'styled-components';
import { BK_COLORS, GREYS } from 'UI/globals/colours';

const BRB_EX = styled(BRB)`
  background: ${BK_COLORS.blue};
  border-color: ${BK_COLORS.blue};
  order: 2;
  z-index: 8;
`;

const BRBT_EX = styled(BRBT)`
  line-height: 18px;
  color: ${GREYS.white};
  span {
    margin-right: 8px;
  }
`;

const BRS_EX = styled(BRS)`
  color: ${GREYS.white};
  i {
    margin-right: 0;
    color: ${BK_COLORS.yellow};
  }
`;

export { BRB_EX as BRB, BRBT_EX as BRBT, BRBBC, BRS_EX as BRS };
