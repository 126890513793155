import {
  BCBD,
  BCBC,
  BCBS,
  S,
  T,
  L,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__bonus';
import styled from 'styled-components';
import { BRAND, FONT, BK_COLORS } from '../../../globals/colours';

const BCBS_EX = styled(BCBS)`
  color: ${FONT.dark};
`;

const BCBD_EX = styled(BCBD)`
  display: block;
  margin: 0 0 15px;
  position: relative;
  text-align: left;

  input[type=checkbox] {
    position: relative;
    border-radius: 4px;
    background: ${BK_COLORS.aliceBlue08};
    cursor: pointer;
    line-height: 0;
    margin: 0 .4em 0 0;
    outline: 0;
    vertical-align: middle;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    transition: all 0.3s linear;
  }

  input[type=checkbox]:checked {
    background-color: none
    opacity: 1;
    border:1px solid ${BRAND.secondary};
  }

  input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid ${BK_COLORS.aliceBlue08};
    border-width: 0 2px 2px 0;
    border: none;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }

  input[type=checkbox]:checked:before {
    background-color: none;
    border: solid ${BRAND.secondary};
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    opacity: 1;
    
  }
`;

export { BCBD_EX as BCBD, BCBC, BCBS_EX as BCBS, L, S, T };
