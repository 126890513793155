import {
  SB,
  BIT,
  BA,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__tooltip';
import { BK_COLORS } from 'UI/globals/colours';
import styled from 'styled-components';

const BIT_EX = styled(BIT)`
  background: ${BK_COLORS.aliceBlue};
  border: 1px solid ${BK_COLORS.aliceBlue10};
  border-radius: 4px;
`;

const BA_EX = styled(BA)`
  &:after {
    background: ${BK_COLORS.aliceBlue};
  }
`;

export { SB, BIT_EX as BIT, BA_EX as BA };
