import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const M = styled.div`
  font-family: ${theme.fontFamily};
  background-color: ${BRAND.tertiaryL};
  color: ${GREYS.black};
  font-size: 12px;
  padding: 8px 16px;
  line-height: 20px;
  user-select: none;
  letter-spacing: 0.25px;
  font-weight: 400;
`;

export const M2 = styled(M)`
  background-color: ${BRAND.tertiaryL};
  word-break: break-word;
`;

export const MSSD = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${BRAND.tertiaryL};
`;

export const MSB = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  user-select: none;
  padding-right: 12px;
  font-size: 20px;
  font-weight: 200;
`;
