import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CurrencyFormat } from 'Services/global/core__currency-format';
import { Constants } from '../../../../../core__betslip-constants';
import Spinner from 'UI/spinner/Spinner';
import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import {
  SBSD,
  SBSU,
  SBSL,
  SBSLH6,
  SBSLH6B,
  SBSNPB,
  SBSPB,
  SpinnerObj,
  BSB as BSB_LOCAL,
} from 'UI/apps/BetslipSAApp/BetslipContainer/BottomSection';
import { BSB as BSB_CORE } from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__bottomsection';
import { Translations } from '../../../../../core__betslip-SA-app';
import { BetslipCurrency } from '../../BetslipContent/elements';
import project from '../../../../../../../../project';
import { getIcon, GLOBAL_CONSTANTS } from 'Services/core__services';
import I from 'UI/globals/Icons';
import { getComponent } from 'Services/core__imports';
import {
  filterSuspendedSelections,
  checkIsValidBet,
} from '../../../../../core__betslip-utils';

const BSB = getComponent(BSB_LOCAL, BSB_CORE);

/**
 @param {func} handleDelete
 @param {bool} suspended
 @param {func} handlePlaceBet
 @param {bool} isLogin
 @param {object} UserAuthData
 @param {string} region
 @param {bool} isError
 @param {number} tax
 @param {func} handleWsAcceptDecline
 @param {func} postApiCall
 @param {string} taxbycountry
 @param {number} potentialReturns
 @param {number} totalStake
 @param {string} currencySymbol
 @param {object} freebetStake
 @param {number} netReturn
 @param {boolean} tapCard
 @param {number} balance
 @param {array} betTemplate
 @param {customTaxComponent} prop to use taxBottomSection component to show gross / net returns
 @param {number} betBoostsAvailable the amount of odds boosts the user has
 */

export const BottomSection = ({
  handleDelete,
  potentialReturns,
  totalStake,
  currencySymbol,
  suspended,
  handlePlaceBet,
  isLogin,
  freebetStake,
  isError,
  fee,
  netReturn,
  tax,
  checkChanges,
  handleWsAcceptDecline,
  postApiCall,
  tapCard,
  balance,
  userbalance,
  card,
  SB,
  bonusStake,
  customTaxComponent,
  openTaxSection,
  showTaxComponentIcon,
  betTemplate,
  isOddsAcceptance,
  withdrawBalance,
  betBoostsAvailable,
  handleBetBoostTracking,
  enableQuckDeposit, // handling Quick deposit not require for SA
}) => {
  const [lowBalance, setLowBalance] = useState(false);
  const [oddsBoostExceeded, setOddsBoostExceeded] = useState(false);

  let showDeposit = false;
  let showQuickDeposit = false;
  let active = false;
  let disable = true;
  let type = null;
  if (!checkIsValidBet(betTemplate)) {
    active = false;
    disable = true;
  } else {
    if (isError === false && totalStake > 0) {
      active = true;
      disable = false;
    } else if (
      isError === false &&
      freebetStake &&
      freebetStake['freeStake'] > 0
    ) {
      active = true;
      disable = false;
    }
  }

  // check user balance
  const checkBeforePlaceingBet = () => {
    if (totalStake > balance) {
      // balance low
      setLowBalance(true);
      window.setTimeout(() => {
        setLowBalance(false);
      }, 2000);
    } else {
      // place bet
      handlePlaceBet();
    }
  };
  if (checkChanges) {
    type = GLOBAL_CONSTANTS.ACCEPT;
  }
  // handle when amount is less then user stake
  if (!SB && userbalance && isLogin && Object.keys(userbalance).length > 0) {
    if (
      (!enableQuckDeposit || !card) &&
      userbalance.balance + (withdrawBalance || 0) < totalStake
    ) {
      showDeposit = true;
    } else if (
      (enableQuckDeposit || card) &&
      userbalance.balance + (withdrawBalance || 0) < totalStake
    ) {
      showQuickDeposit = true;
    }
  }

  // Check boosted bets do not exceed the amount of boosted bets on the account
  useEffect(() => {
    const boostedBets = betTemplate.filter(bet => bet.bbFlag === true);

    if (boostedBets.length <= betBoostsAvailable) {
      setOddsBoostExceeded(false);
    } else {
      setOddsBoostExceeded(true);
    }
  }, [betTemplate, betBoostsAvailable]);

  // Send error to warning tab if not needed
  useEffect(() => {
    handleBetBoostTracking(oddsBoostExceeded);
  }, [oddsBoostExceeded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (suspended) {
      const timeout = setTimeout(() => {
        const selections = betTemplate.filter(b => b.id !== undefined);
        const activeSelections = filterSuspendedSelections(selections);

        const equal =
          JSON.stringify(selections.map(s => s.id)) ===
          JSON.stringify(activeSelections.map(s => s.id));
        !equal && handleDelete({ type: 'deletesuspended' });
      }, 1000);

      return () => {
        timeout && clearTimeout(timeout);
      };
    }
  }, [suspended, betTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOddsChangesAndPlacebet = type => {
    if (type !== null) handleWsAcceptDecline(type);
    handlePlaceBet();
  };
  return (
    <SBSD>
      <SBSU>
        <SBSL>
          <SBSLH6>{Translations.get(Constants.TS)}</SBSLH6>
        </SBSL>
        <SBSL>
          <SBSLH6>
            <BetslipCurrency symbol={currencySymbol} />
            {parseDecimalPlacesPotReturn(totalStake)}
          </SBSLH6>
        </SBSL>
      </SBSU>
      {freebetStake['hasFreebet'] && (
        <SBSU>
          <SBSL>
            <SBSLH6>{Translations.get(Constants.TFS)}</SBSLH6>
          </SBSL>
          <SBSL>
            <SBSLH6>
              {CurrencyFormat(currencySymbol)}
              {parseDecimalPlacesPotReturn(freebetStake['freeStake'])}
            </SBSLH6>
          </SBSL>
        </SBSU>
      )}

      {!isLogin && (
        <SBSU>
          <SBSL>
            <SBSLH6B>
              {Translations.get('text.potential.returns')}
              {showTaxComponentIcon && (
                <BSB onClick={openTaxSection}>
                  <I iconName={getIcon('INFORMATION')} />
                </BSB>
              )}
            </SBSLH6B>
          </SBSL>
          <SBSL>
            {suspended ? (
              <SBSLH6B>{Translations.get('text.na')}</SBSLH6B>
            ) : (
              <SBSLH6B>
                {potentialReturns === Constants.N_A || checkChanges ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={currencySymbol} />
                    {showTaxComponentIcon
                      ? parseDecimalPlacesPotReturn(netReturn)
                      : parseDecimalPlacesPotReturn(potentialReturns)}
                  </React.Fragment>
                )}
              </SBSLH6B>
            )}
          </SBSL>
        </SBSU>
      )}

      {isLogin && (
        <SBSU>
          <SBSL>
            <SBSLH6B>
              {Translations.get(
                !customTaxComponent ? Constants.TRG : 'text.potential.return'
              )}
              {showTaxComponentIcon && (
                <BSB onClick={openTaxSection}>
                  <I iconName={getIcon('INFORMATION')} />
                </BSB>
              )}
            </SBSLH6B>
          </SBSL>
          <SBSL>
            <SBSLH6>
              {potentialReturns === Constants.N_A || checkChanges ? (
                Translations.get('text.na')
              ) : (
                <React.Fragment>
                  {CurrencyFormat(currencySymbol)}
                  {showTaxComponentIcon
                    ? parseDecimalPlacesPotReturn(netReturn)
                    : parseDecimalPlacesPotReturn(potentialReturns)}
                </React.Fragment>
              )}
            </SBSLH6>
          </SBSL>
        </SBSU>
      )}
      {bonusStake?.hasBonusBet && project.betBooster && (
        <SBSU>
          <SBSL>
            <SBSLH6B>{Translations.get('text.total.est')}</SBSLH6B>
          </SBSL>
          <SBSL>
            <SBSLH6B>
              <BetslipCurrency symbol={currencySymbol} />
              {parseDecimalPlacesPotReturn(
                potentialReturns + bonusStake.betBonusAmount
              )}
            </SBSLH6B>
          </SBSL>
        </SBSU>
      )}
      {tax && isLogin && !customTaxComponent && (
        <SBSU>
          <SBSL>
            <SBSLH6>{tax + Translations.get(Constants.FEE)}</SBSLH6>
          </SBSL>
          <SBSL>
            <SBSLH6>
              {'-'}
              {CurrencyFormat(currencySymbol)}
              {parseDecimalPlacesPotReturn(fee)}
            </SBSLH6>
          </SBSL>
        </SBSU>
      )}
      {tax && isLogin && !customTaxComponent && (
        <SBSU>
          <SBSL>
            <SBSLH6>{Translations.get(Constants.TRN)}</SBSLH6>
          </SBSL>
          <SBSL>
            <SBSLH6>
              {CurrencyFormat(currencySymbol)}
              {parseDecimalPlacesPotReturn(netReturn)}
            </SBSLH6>
          </SBSL>
        </SBSU>
      )}

      {suspended === true ? (
        <SBSNPB
          suspended
          onClick={() => handleDelete({ type: 'deletesuspended' })}
        >
          {Translations.get(Constants.REMOVESUSPENDED)}
        </SBSNPB>
      ) : postApiCall === true ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet();
          }}
          active={true}
          disabled={true}
        >
          <Spinner display={true} width={24} style={SpinnerObj} />
          {isLogin
            ? Translations.get(Constants.PLACEBET)
            : Translations.get('text.login') +
              ' & ' +
              Translations.get('text.place.bet')}
        </SBSPB>
      ) : tapCard && lowBalance ? (
        <SBSPB insufficient={true}>
          {Translations.get('text.insufficient.credit')}
        </SBSPB>
      ) : tapCard ? (
        <SBSPB
          onClick={() => {
            checkBeforePlaceingBet();
          }}
          active={active}
          disabled={disable}
        >
          {isLogin
            ? Translations.get(Constants.PLACEBET)
            : Translations.get('text.tap.card')}
        </SBSPB>
      ) : oddsBoostExceeded ? (
        <SBSPB active={false} disabled={disable}>
          {Translations.get('text.place.bet')}
        </SBSPB>
      ) : showDeposit ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet('deposit');
          }}
          active={active}
          disabled={disable}
        >
          {Translations.get('text.deposit')}
        </SBSPB>
      ) : showQuickDeposit ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet('low-balance');
          }}
          active={active}
          disabled={disable}
        >
          {Translations.get('text.deposit')}
          {' & '}
          {Translations.get('text.place.bet')}
        </SBSPB>
      ) : !isLogin ? (
        <SBSPB
          onClick={() => {
            handlePlaceBet('login');
          }}
          active={true}
        >
          {Translations.get('text.login.place.bet')}
        </SBSPB>
      ) : !(isOddsAcceptance && active) && checkChanges ? (
        <SBSNPB onClick={() => handleWsAcceptDecline('accept')}>
          {Translations.get(Constants.ACCEPTSODDS)}
        </SBSNPB>
      ) : (
        <SBSPB
          onClick={() => {
            handleOddsChangesAndPlacebet(type);
          }}
          active={active}
          disabled={disable}
        >
          {isLogin && checkChanges
            ? `${Translations.get(Constants.ACCEPTSODDS)} & ${Translations.get(
                Constants.PLACEBET
              )}`
            : checkChanges
            ? Translations.get(Constants.ACCEPTSODDS)
            : Translations.get(Constants.PLACEBET)}
        </SBSPB>
      )}
    </SBSD>
  );
};

BottomSection.propTypes = {
  handleDelete: PropTypes.func,
  handlePlaceBet: PropTypes.func,
  potentialReturns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalStake: PropTypes.number,
  suspended: PropTypes.bool,
  currencySymbol: PropTypes.string,
  isLogin: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  freebetStake: PropTypes.shape({
    freeStake: PropTypes.number,
    hasFreebet: PropTypes.bool,
  }),
  freeStake: PropTypes.number,
  hasFreebet: PropTypes.bool,
  isError: PropTypes.bool,
  netReturn: PropTypes.number,
  fee: PropTypes.number,
  tax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkChanges: PropTypes.bool,
  handleWsAcceptDecline: PropTypes.func,
  postApiCall: PropTypes.bool,
  tapCard: PropTypes.bool,
  balance: PropTypes.number,
  card: PropTypes.bool,
  SB: PropTypes.bool,
  userbalance: PropTypes.object,
  hideGrossValsInJN: PropTypes.bool,
  bonusStake: PropTypes.object,
  customTaxComponent: PropTypes.bool,
  openTaxSection: PropTypes.func,
  showTaxComponentIcon: PropTypes.bool,
  betTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isOddsAcceptance: PropTypes.bool,
  withdrawBalance: PropTypes.number,
  betBoostsAvailable: PropTypes.number,
  handleBetBoostTracking: PropTypes.func,
  enableQuckDeposit: PropTypes.bool,
};

BottomSection.defaultProps = {
  customTaxComponent: false,
  betBoostsAvailable: 0,
  enableQuckDeposit: true,
};
