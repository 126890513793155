import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import {
  BCD,
  BetslipContentBetBoxPot,
  RTNS,
} from 'UI/apps/BetslipSAApp/BetslipContainer/PotReturn';
import { BetslipCurrency } from './core__currency';
import { Translations } from '../../../../../core__betslip-SA-app';
import { isAnySPbet } from '../../../../../core__betslip-utils';

/**
 * @param {number} potReturn
 * @param {string} currencySymbol
 * @param {Array} bet
 */
export const PotReturn = ({ potReturn, currencySymbol, bet }) => {
  const isSpOdd = isAnySPbet();
  if (bet.betTypeRef !== Constants.BBB) {
    return (
      <BCD
        inActive={potReturn === Constants.N_A ? false : !!isNaN(potReturn)}
        isblank={!bet.returnsText}
      >
        {(bet.returnsText === Constants.N_A || bet.returnsText > 0) && (
          <React.Fragment>
            <BetslipContentBetBoxPot active={true}>
              {Translations.get(Constants.PRS)}
            </BetslipContentBetBoxPot>
            <RTNS>
              {bet.priceChangeNotification ? (
                Translations.get('text.na')
              ) : potReturn === Constants.N_A ? (
                Translations.get('text.na')
              ) : isSpOdd && bet.multiple ? (
                Translations.get('text.na')
              ) : (
                <React.Fragment>
                  <BetslipCurrency symbol={currencySymbol} />
                  {potReturn.toFixed(2)}
                </React.Fragment>
              )}
            </RTNS>
          </React.Fragment>
        )}
      </BCD>
    );
  } else {
    return (
      <BCD>
        {bet.returnsText !== Constants.N_A && bet.returnsText > 0 && (
          <React.Fragment>
            <BetslipContentBetBoxPot active={true}>
              {Translations.get(Constants.PRS)}
            </BetslipContentBetBoxPot>
            <RTNS>
              <React.Fragment>
                <BetslipCurrency symbol={currencySymbol} />
                {bet.returnsText && bet.returnsText.toFixed(2)}
              </React.Fragment>
            </RTNS>
          </React.Fragment>
        )}
      </BCD>
    );
  }
};
PotReturn.propTypes = {
  potReturn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bet: PropTypes.object,
  currencySymbol: PropTypes.string,
};
