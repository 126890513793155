import defaultConstants from '../../../project';

export const Constants = {
  MULTIPLE: 'multiple',
  SINGLE: 'single',
  SYSTEM: 'system',
  FORCAST: 'Forecast',
  FORCASTTricast: 'Forecast/Tricast',
  MULTIPLES: 'Multiples',
  HORSES: 'HORSES',
  DOGS: 'DOGS',
  VIRTUAL: 'VIRTUAL',
  DEFAULT_AMT: 0.0,
  N_A: 'N/A',
  DEFAULT_MODIFIERBETCOUNT: 1,
  LSKEY_UK: 'betslip_uk',
  LSKEY_US: 'betslip_us',
  TRICAST: 'Tricast',
  SP: 'SP',
  Deletesuspended: 'deletesuspended',
  DECIMAL: 'decimal',
  FRACTIONAL: 'fractional',
  REACTICONPATH: `${defaultConstants.staticPath.basePath}${defaultConstants.staticPath.bucketReact}`,
  STATICICONPATH: `${defaultConstants.staticPath.basePath}${defaultConstants.staticPath.bucketReact}${defaultConstants.staticPath.folderIcon}`,
  STATICMISCPATH: `${defaultConstants.staticPath.basePath}${defaultConstants.staticPath.bucketImages}${defaultConstants.staticPath.folderMisc}`,
  PLACEBET: 'text.place.bet',
  LOGINANDPLACEBET: 'text.login.place.bet',
  ACCEPTSODDS: 'text.accept.odd.changes',
  REMOVESUSPENDED: 'text.remove.suspended',
  TRN: 'text.trn',
  FEE: 'text.fee',
  TRG: 'text.trg',
  TS: 'text.ts',
  TFS: 'text.tfs',
  PR: 'Potential Returns',
  INC: 'increase',
  DEC: 'decrease',
  AMERICAN: 'american',
  ACCA: 'acca',
  PRS: 'text.pt.rtn',
  TOTALRETURN: 'text.total.return',
  BET: 'bet',
  MYBET: 'mybet',
  SGL: 'SGL',
  BBB: 'BBB',
  ODDMOMENT: 'odd-moment',
  PARLAY: 'parlay',
  CASTORDER: 'castorder',
  TEASER: 'teaser',
  DELETEALL: 'deleteall',
  BUILDERUSEDATA: 'buideruseData',
  BETSLIPUSTEASER: 'betslip_US_teaser',
  CAST: 'CAST',
  SCT: 'SCT',
  HHMM: '\\d+\\:\\d+',
  DEFAULT: 'default',
  BLOCK: 'block',
  LOWER: 'lower',
  HIGHER: 'higher',
  REQUESTED: 'REQUESTED',
  CURRENT: 'CURRENT',
  HIGHER_C: 'HIGHER',
  LOWER_C: 'LOWER',
  TOP: 'top',
  BOTTOM: 'bottom',
  EACHWAY: 'EACHWAY',
  TAXED_CATEGORIES: ['HORSES'],
  BASKETBALL_REF: 'BASKETBALL',
  SOCCER_REF: 'SOCCER',
  ICE_HOCKEY_REF: 'ICE_HOCKEY',
  TENNIS_REF: 'TENNIS',
  MAX: 'max',
  MIN: 'min',
  MAXSTAKE: 'stake exceed',
  MINSTAKE: 'stake below',
  UPDATE: 'Update',
  SINGLEMINSTAKE: 'SINGLE_MIN_STAKE',
  MULTIPLEMINSTAKE: 'MULTIPLE_MIN_STAKE',
  SINGLEMAXSTAKE: 'SINGLE_MAX_STAKE',
  SINGLEMAXPAYOUT: 'SINGLE_MAX_PAYOUT',
  SYSTEM_BET: ['SGL', 'FCS', 'TRI'],
  PRICE_NOT_CURRENT_ERROR_CODE: 'api.error.bet.price.not.current',
  PRICE_CHANGE_ERROR_CODE: 'api.error.bet.price.change',
};
