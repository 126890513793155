import React from 'react';
import { BetSlipBonus } from './core__bonus';
import { BetslipContentBooster } from './core__booster';
import {
  CastOrderComponent,
  ordinalSuffixOf,
  dataSetOnupdate,
} from './core__castorder';
import { CrossButton } from './core__crossbutton';
import { BetslipCurrency } from './core__currency';
import { Description } from './core__description';
import { BetslipEW } from './core__ew';
import { BetslipFreeBet } from './core__freebet';
import { OddName } from './core__name';
import { Price } from './core__price';
import { SocketMessage } from './core__socketmessage';
import { BetslipUserStake } from './core__stake';
import { PotReturn } from './core__potReturn';
import { UserFreeBetStake } from './core__freebetstake';
import { Text } from './core__text';

const Price_Copy = props => <Price {...props} boosterPreviousReturn={true} />;

export {
  BetSlipBonus,
  BetslipContentBooster,
  CastOrderComponent,
  ordinalSuffixOf,
  dataSetOnupdate,
  CrossButton,
  BetslipCurrency,
  Description,
  BetslipEW,
  BetslipFreeBet,
  OddName,
  Price_Copy as Price,
  SocketMessage,
  BetslipUserStake,
  PotReturn,
  UserFreeBetStake,
  Text,
};
