import React from 'react';
import PropTypes from 'prop-types';
import { BTU, BTL, BTA } from 'UI/apps/BetslipSAApp/BetslipContainer/Tab';

/**
 * @param {array} tabs
 * @param {object} props
 */
export const BetslipTabs = ({ tabs, props }) => {
  let filteredTabs = tabs;
  if (props.hiddenTabs) {
    filteredTabs = tabs.filter(function (tab) {
      return this.indexOf(tab.actTab) < 0;
    }, props?.hiddenTabs);
  }
  return (
    <BTU topSection={props?.topdeletebetslip}>
      {filteredTabs.map(tab => {
        return (
          <BTL active={props.actTab === tab.actTab} key={tab.actTab}>
            <BTA
              onClick={() => {
                props.handleActTab(tab.actTab, tab.shouldDisplay);
              }}
              shouldDisplay={tab.shouldDisplay}
            >
              {tab.display}
            </BTA>
          </BTL>
        );
      })}
    </BTU>
  );
};

BetslipTabs.propTypes = {
  tabs: PropTypes.array,
  props: PropTypes.shape({
    handleActTab: PropTypes.func,
    actTab: PropTypes.string,
  }),
  handleActTab: PropTypes.func,
  actTab: PropTypes.string,
  shouldDisplay: PropTypes.bool,
  hiddenTabs: PropTypes.array,
  topdeletebetslip: PropTypes.bool,
};
BetslipTabs.defaultProps = {
  shouldDisplay: true,
};
