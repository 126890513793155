import {
  BCFR,
  BCFWC,
  BCWCH,
  BCFSS,
  CONF,
  CAN,
  BCFBTH,
  MStyles,
  IRDO,
  FBI,
  BC,
  X,
  BonusText,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__freebet';
import styled from 'styled-components';
import { EX_COLORS } from 'UI/globals/colours';

const BCFR_UI = styled(BCFR)`
  background-color: ${EX_COLORS.brandYellow};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FBI_UI = styled(FBI)`
  width: initial;
`;
export {
  BCFR_UI as BCFR,
  BCFWC,
  BCWCH,
  BCFSS,
  CONF,
  CAN,
  BCFBTH,
  MStyles,
  IRDO,
  FBI_UI as FBI,
  BC,
  X,
  BonusText,
};
