import {
  OD,
  ODUL,
  TL,
  TS,
  TI,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__oddMovements';
import { BRAND, GREYS } from 'UI/globals/colours';
import styled from 'styled-components';

const TL_EX = styled(TL)`
  font-size: 12px;
  color: ${({ isSelected }) => (isSelected ? BRAND.primary : GREYS.black)};
  text-align: right;
`;

export { OD, ODUL, TL_EX as TL, TS, TI };
