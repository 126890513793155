import styled, { css } from 'styled-components';
import { BRAND, FONT, GREYS, FUNCTIONAL } from 'UI/globals/colours';
import { BREAKPOINT_L, BREAKPOINT_M } from 'UI/globals/breakpoints';

export const BHU = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  padding: 20px 16px 0 16px;
  background-color: ${GREYS.white};
  pointer-events: none;
  border-bottom: 1px solid ${BRAND.tertiary};
  @media (max-width: ${BREAKPOINT_L}) {
    background-color: ${BRAND.primary};
    padding: 20px 16px 4px 16px;
    pointer-events: all;
    cursor: pointer;
  }
  justify-content: space-between;
`;

export const BHL = styled.li`
  margin: 0;
  display: flex;
  padding-right: 8px;
  &:first-child {
    margin-right: 32px;
  }
  @media (max-width: ${BREAKPOINT_L}) {
    &:last-child {
      display: none;
    }
    &:first-child {
      margin-right: 0px;
    }
  }
`;

export const BHLF = styled(BHL)`
  @media (max-width: ${BREAKPOINT_L}) {
    flex-grow: 1;
    cursor: pointer;
  }
`;

export const BS = styled.span`
  position: absolute;
  top: -3px;
  right: -16px;
  font-size: 10px;
  font-weight: normal;
  color: ${GREYS.black};
  background-color: ${BRAND.tertiary};
  border-radius: 8px;
  user-select: none;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  @media (max-width: ${BREAKPOINT_L}) {
    display: none;
  }
`;

export const BA = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  color: ${FONT.dark};
  font-size: 14px;
  padding-bottom: 12px;
  cursor: pointer;
  user-select: none;
  position: relative;
  pointer-events: all;

  ${({ active }) =>
    active &&
    css`
      color: ${FONT.secondary};
      font-weight: bold;
      border-bottom: 4px solid ${FONT.secondary};
      @media (max-width: ${BREAKPOINT_L}) {
        color: ${FONT.light};
        border-bottom: 0px;
      }
    `};
`;

export const BAMY = styled(BA)`
  ${({ disable }) =>
    !disable &&
    css`
      cursor: not-allowed;
      color: ${FONT.lightMute};
    `}
`;
export const BSM = styled.a`
  display: none;
  background-color: ${BRAND.tertiary};
  border-radius: 12px;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-left: 4px;
  @media (max-width: ${BREAKPOINT_L}) {
    display: inline-block;
  }
`;

export const BSI = styled.span`
  display: none;
  @media (max-width: ${BREAKPOINT_M}) {
    display: block;
    width: 16px;
    height: 16px;
    border-bottom: ${({ theme }) =>
      theme.dark ? `2px solid ${GREYS.white}` : `2px solid ${GREYS.black}`};
    border-right: ${({ theme }) =>
      theme.dark ? `2px solid ${GREYS.white}` : `2px solid ${GREYS.black}`};
    ${({ downIcon }) =>
      (downIcon &&
        css`
          transform: rotate(45deg);
          margin-top: -4px;
        `) ||
      css`
        transform: rotate(225deg);
        margin-top: 4px;
      `}
  }
`;

export const SUMMARY_TEXT = styled.span`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
`;

export const SUMMARY_PRICE = styled(SUMMARY_TEXT)`
  color: ${FUNCTIONAL.positive};
`;

export const LI = styled.li``;
