import {
  TLI,
  TDIV,
  WSLI2BTN,
  WSLI1BTN,
  WSUL,
  WSP,
  WSPOPUP,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__socketMessage';
import styled, { css } from 'styled-components';
import { BK_COLORS, FONT } from 'UI/globals/colours';

const WSPOPUP_EX = styled(WSPOPUP)`
  background: ${BK_COLORS.blue};
  height: auto;
  min-height: 55px;
  padding: 8px;
  color: ${FONT.white.standard};
`;

const ButtonStylesForOddsChanges = css`
  background: ${FONT.white.disabled};
  color: ${FONT.white.standard};
  border-radius: 99px;
`;

const WSLI1BTN_EX = styled(WSLI1BTN)`
  ${ButtonStylesForOddsChanges}
`;

const WSLI2BTN_EX = styled(WSLI2BTN)`
  ${ButtonStylesForOddsChanges};
`;

const TDIV_EX = styled(TDIV)`
  border-top-color: ${BK_COLORS.blue};
  border-left-width: 8px;
  border-right-width: 8px;
  border-top-width: 8px;
`;

export {
  TLI,
  TDIV_EX as TDIV,
  WSLI2BTN_EX as WSLI2BTN,
  WSLI1BTN_EX as WSLI1BTN,
  WSUL,
  WSP,
  WSPOPUP_EX as WSPOPUP,
};
