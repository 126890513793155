import styled, { css } from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

export const BCD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: inherit;
  ${({ inActive }) =>
    inActive &&
    css`
      display: none;
    `};
  ${({ isblank }) =>
    isblank &&
    css`
      flex: 1;
      display: block;
    `}
`;

export const BetslipContentBetBoxPot = styled.span`
  color: ${GREYS.silver};
  font-size: 12px;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      color: ${FONT.dark};
    `};
`;

export const RTNS = styled.span`
  color: ${FONT.dark};
`;
