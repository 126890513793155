import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { PriceDropDown } from 'UI/lines/dropdowns/PriceSelect';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import { BCSW as BCSW_CORE } from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__price';
import {
  BL,
  BCS,
  BSSEL,
  OI,
  BLS,
  BH,
  BCSW as BCSW_LOCAL,
} from 'UI/apps/BetslipSAApp/BetslipContainer/Price';
import { getIcon } from 'Services/icons/core__icons';
import I from 'UI/globals/Icons';
import { Translations } from '../../../../../core__betslip-SA-app';
import { getComponent } from 'Services/core__imports';

const BCSW = getComponent(BCSW_LOCAL, BCSW_CORE);

/** 
display prices of odds 
@pF {String} "contain price fractional value",
@priceBookType {String} "contain booking type of price",
@price {Array} "actual array of Price",
@handleDropDownChange {Function} "handle Dropdown in Horses and dogs",
@catRef {String} "check for bet category",
@priceId {String | Number} "Default Priceing",
@oddsFormat {String} "format of odd (fractional & decimal)",
@priceDecimal {Number} "value of decimal Price",
@bbFlage {Bool} "check of booster",
@boosterPreviousReturn {bool} "show previous return after boosted"
*/
export const Price = ({
  pF,
  priceBookType,
  oddsFormat,
  price,
  handleDropDownChange,
  catRef,
  priceId,
  priceDecimal,
  bbFlag,
  notificationPriceChangeType,
  active,
  betTypeRef,
  fractional,
  betModifier,
  boosterPreviousReturn,
  actTab,
}) => {
  betModifier = betModifier[0];
  const iconName = getIcon('PRICE_BOOST');
  const ISSINGLE = actTab === Constants.SINGLE;
  if (priceDecimal && !isNaN(priceDecimal) && priceDecimal !== Constants.N_A) {
    priceDecimal = parseDecimalPlaces(priceDecimal);
  }

  // for horse and dog bets when price more then 2
  let mapData = {};
  let selectedPriceValue = '';
  let selectedPriceLabel = '';
  if (price && price.length > 1) {
    mapData = price.map(data => {
      // for select default value in horse and dog bets
      if (priceId === data.id) {
        selectedPriceValue = data.id;

        if (oddsFormat === Constants.DECIMAL)
          selectedPriceLabel =
            (data.decimal && parseDecimalPlaces(data.decimal)) || data.bookType;
        else if (oddsFormat === Constants.AMERICAN)
          selectedPriceLabel =
            (data.decimal && getOdds(parseDecimalPlaces(data.decimal), true)) ||
            data.bookType;
        else
          selectedPriceLabel =
            (data.fractional && data.fractional) || data.bookType;
      }

      // manipulate array according to react-select
      if (oddsFormat === Constants.DECIMAL) {
        return {
          label:
            (data.decimal && parseDecimalPlaces(data.decimal)) || data.bookType,
          value: data.id,
        };
      } else if (oddsFormat === Constants.AMERICAN) {
        return {
          label:
            (data.decimal && getOdds(parseDecimalPlaces(data.decimal), true)) ||
            data.bookType,
          value: data.id,
        };
      } else {
        return {
          label: (data.fractional && data.fractional) || data.bookType,
          value: data.id,
        };
      }
    });
  }
  // dropdown c end
  return (
    <React.Fragment>
      {active ? (
        (betTypeRef === Constants.BBB && (
          /* for Bet Builder */
          <BL>
            {(oddsFormat === Constants.DECIMAL && (
              <BCS>{parseDecimalPlaces(price)}</BCS>
            )) ||
              (oddsFormat === Constants.AMERICAN && (
                <BCS>{getOdds(price, true)}</BCS>
              )) || <BCS>{fractional}</BCS>}
          </BL>
        )) ||
        (!ISSINGLE && betModifier['betCount'] === 1 && (
          <BL>
            {(oddsFormat === Constants.DECIMAL && (
              <BCS>{parseDecimalPlaces(betModifier['returns'])}</BCS>
            )) ||
              (oddsFormat === Constants.AMERICAN && (
                <BCS>{getOdds(betModifier['returns'], true)}</BCS>
              )) || <BCS>{betModifier['fractionalReturns']}</BCS>}
          </BL>
        )) ||
        (!ISSINGLE && betModifier['betCount'] > 1 && (
          <BL systemBet={true}>
            {(oddsFormat === Constants.DECIMAL && (
              <BCS>{parseDecimalPlaces(betModifier['returns'])}</BCS>
            )) ||
              (oddsFormat === Constants.AMERICAN && (
                <BCS>{getOdds(betModifier['returns'], true)}</BCS>
              )) || <BCS>{betModifier['fractionalReturns']}</BCS>}
          </BL>
        )) ||
        /* for normal bets */
        (ISSINGLE && (
          <BL bbFlag={bbFlag}>
            {/* when Price change for Increases|decress odds value */}
            {notificationPriceChangeType === Constants.DEC && (
              <OI src={Constants.STATICMISCPATH + 'odds_decreased.svg'}></OI>
            )}
            {notificationPriceChangeType === Constants.INC && (
              <OI src={Constants.STATICMISCPATH + 'odds_increased.svg'}></OI>
            )}

            {/* when bbFlag is true */}
            {bbFlag ? (
              (oddsFormat === Constants.DECIMAL && (
                <BCSW>
                  {boosterPreviousReturn && (
                    <React.Fragment>
                      {parseDecimalPlaces(betModifier.returns)}
                      <I iconName={getIcon('OPEN')} size={8} />
                    </React.Fragment>
                  )}
                  <BCS bbFlag={bbFlag}>
                    <I iconName={iconName} marginR={8} />
                    {priceDecimal
                      ? parseDecimalPlaces(betModifier.boostedReturns)
                      : priceBookType}
                  </BCS>
                </BCSW>
              )) ||
              (oddsFormat === Constants.AMERICAN && (
                <BCS>
                  <I iconName={iconName} marginR={8} />
                  {priceDecimal
                    ? getOdds(betModifier.boostedReturns, true)
                    : priceBookType}
                </BCS>
              )) || (
                <BCS>
                  <I iconName={iconName} marginR={8} />
                  {pF ? betModifier.boostedFractionalReturns : priceBookType}
                </BCS>
              )
            ) : [Constants.HORSES, Constants.DOGS].includes(catRef) &&
              (priceBookType === 'PRICE' || priceBookType === Constants.SP) &&
              price &&
              price.length > 1 ? (
              // when category is HORSES | DOGS type and price length > 1
              <BSSEL>
                <PriceDropDown
                  data-test="odds-price-change"
                  defaultLabel={selectedPriceLabel}
                  defaultValue={selectedPriceValue}
                  onChange={e => handleDropDownChange(e?.value)}
                  options={mapData}
                  key={Math.random()}
                  menuPlacement={'bottom'}
                />
              </BSSEL>
            ) : (
              // default case show data for remain all category .
              (oddsFormat === Constants.DECIMAL && (
                <BCS>
                  {priceDecimal && priceDecimal !== Constants.N_A
                    ? priceDecimal
                    : priceBookType}
                </BCS>
              )) ||
              (oddsFormat === Constants.AMERICAN && (
                <BCS>
                  {priceDecimal && priceDecimal !== Constants.N_A
                    ? getOdds(price?.[0]?.decimal || priceDecimal, true)
                    : priceBookType}
                </BCS>
              )) || <BCS>{pF && pF !== Constants.N_A ? pF : priceBookType}</BCS>
            )}
          </BL>
        ))
      ) : (
        <BLS>
          <BH>{Translations.get('text.suspended')}</BH>
        </BLS>
      )}
    </React.Fragment>
  );
};

Price.propTypes = {
  pF: PropTypes.string,
  priceBookType: PropTypes.string,
  price: PropTypes.array,
  handleDropDownChange: PropTypes.func,
  catRef: PropTypes.string,
  priceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  oddsFormat: PropTypes.string,
  priceDecimal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bbFlag: PropTypes.bool,
  notificationPriceChangeType: PropTypes.string,
  active: PropTypes.bool,
  betTypeRef: PropTypes.string,
  fractional: PropTypes.string,
  betCount: PropTypes.number,
  returns: PropTypes.number,
  fractionalReturns: PropTypes.string,
  betModifier: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  boosterPreviousReturn: PropTypes.bool,
  actTab: PropTypes.string,
};

Price.defaultProps = {
  boosterPreviousReturn: false,
};
