import React from 'react';
import { BottomSection } from './core__bottomsection';
import { TopSection } from './core__topsection';

const BottomSection_Copy = props => (
  <BottomSection
    {...props}
    customTaxComponent={true}
    enableQuckDeposit={false}
  />
);

export { BottomSection_Copy as BottomSection, TopSection };
