import styled, { css } from 'styled-components';
import { GREYS, FONT, FUNCTIONAL } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const SBSD = styled.div`
  padding: 16px;
  background-color: ${GREYS.doveL};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const SBSU = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

export const SBSL = styled.li`
  margin: 0;
  padding: 0;
`;
export const SBSLH6 = styled.h6`
  font-size: 14px;
  color: ${FONT.dark};
  margin: 4px 0px;
`;
export const SBSLH6B = styled(SBSLH6)`
  font-weight: bold;
`;

export const SBSNPB = styled.button`
  font-family: ${theme.fontFamily};
  background-color: ${FUNCTIONAL.negativeL};
  color: ${FUNCTIONAL.negativeD};
  border: 1px solid ${FUNCTIONAL.negativeL};
  outline: none;
  font-size: 14px;
  border-radius: 4px;
  padding: 12px 0px;
  width: 100%;
  margin: 8px 0px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SBSPB = styled(SBSNPB)`
  background-color: ${FONT.secondary};
  border: 1px solid ${FONT.secondary};
  color: ${FONT.dark};
  ${({ active }) =>
    !active &&
    css`
      background-color: ${GREYS.dove};
      color: ${FONT.darkMute};
      cursor: not-allowed;
    `};
`;

export const SpinnerObj = {
  position: 'absolute',
  'margin-left': '80px',
  'margin-top': '-2px',
};

export const BSB = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  display: inline-flex;
  padding: 0;
  cursor: pointer;
  top: 2px;
  margin-left: 4px;
`;
