import {
  AS,
  AD,
  Ah3,
  Apdate,
  C3,
  C31,
  CL,
  Ch6,
  Ch5,
  ADIV,
} from 'CORE__UI/apps/BetslipSAApp/ReceiptContainer/core__topsection';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';

const Ah3_UI = styled(Ah3)`
  color: ${GREYS.black};
  @media (max-width: ${BREAKPOINT_L}) {
    color: ${GREYS.white};
  }
`;

const AS_UI = styled(AS)`
  color: ${GREYS.black};
  @media (max-width: ${BREAKPOINT_L}) {
    color: ${GREYS.white};
  }
`;

export { AS_UI as AS, AD, Ah3_UI as Ah3, Apdate, C3, C31, CL, Ch6, Ch5, ADIV };
