import styled, { css } from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

export const BTU = styled.ul`
  list-style: none;
  margin: 0;
  padding: 12px 0px;
  display: flex;
  justify-content: space-around;
`;

export const BTL = styled.li`
  cursor: pointer;
  user-select: none;
  color: ${GREYS.silver};
  ${({ active }) =>
    active &&
    css`
      font-weight: 800;
      color: ${FONT.primary};
    `};
  ${({ c2 }) =>
    c2 &&
    css`
      cursor: auto;
    `}
`;
export const BTA = styled.a`
  cursor: pointer;
  user-select: none;
`;
export const BTSS = styled(BTU)`
  padding: 16px;
  justify-content: space-between;
`;
export const BTCB = styled.li`
  font-weight: 500;
  user-select: none;
  cursor: pointer;
`;
