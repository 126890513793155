import styled from 'styled-components';
import ModalButton from 'UI/buttons/ModalButton';
import { FONT } from 'UI/globals/colours';

// previously known as NB
export const Container = styled.div`
  padding: 16px;
`;

// previously known as NoBet
export const Heading = styled.h3`
  text-align: center;
  vertical-align: middle;
  line-height: initial;
  user-select: none;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25px;
  text-align: center;
  margin: 80px 0px;
  i {
    display: block;
    margin: 8px auto;
  }
`;

export const SiteFeaturesWrapper = styled.div`
  border: 1px solid rgba(0, 87, 163, 0.1);
  border-radius: 4px;
  flex: 1;
  margin-top: auto;
`;

export const SectionHeading = styled.h4`
  color: ${FONT.primary};
  font-weight: bold;
  padding: 12px;
`;

// previously known as LinkWrapper
export const Link = styled.a`
  padding: 24px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

export const LinkHeader = styled.h3`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

export const LinkDescription = styled.span`
  display: flex;
  margin-top: 8px;
  color: ${FONT.black.mid};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const Button = styled(ModalButton)`
  flex-grow: 0;
  min-width: 125px;
`;

export const LinkDivider = styled.div`
  height: 1px;
  background: rgba(0, 87, 163, 0.1);
  width: calc(100% - 32px);
  margin: 0 auto;
`;
