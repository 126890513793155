import {
  StyledSelect,
  styles1,
} from 'CORE__UI/lines/dropdowns/PriceSelect/core__priceSelectStyles';

import styled from 'styled-components';
import { GREYS, BRAND, BK_COLORS } from '../../globals/colours';

const StyledSelectJN = styled(StyledSelect)`
  //background-color: ${BK_COLORS?.aliceBlue08};
  border: none;
  div[class*='singleValue'] {
    color: ${GREYS.black};
    font-weight: 700;
  }
  div[class*='indicatorContainer'] {
    color: ${GREYS.black};
  }
  div[class*='control'] {
    background-color: ${BK_COLORS?.aliceBlue08 || GREYS.white};
    border: none;
  }
  div[class*='menu'] {
    color: ${GREYS.black};
    background-color: ${GREYS.white};
  }
  div[class*='option'] {
    color: ${GREYS.black};
    background-color: transparent;
  }
  div[class*='option']:hover {
    color: ${BRAND.primary};
    background-color: ${GREYS.doveL};
  }
`;

let styles1JN = styles1;

styles1JN = {
  control: provided => ({
    ...provided,
    boxShadow: 'none',
    justifyContent: 'left',
    minHeight: 'auto',
    backgroundColor: GREYS.silver,
    border: 'none',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: GREYS.silver,
    color: GREYS.white,
  }),
};

export { StyledSelectJN as StyledSelect, styles1JN as styles1 };
