import styled from 'styled-components';
import { Select } from '../../../globals/Select/core__select';
import theme from 'UI/globals/theme';
import { GREYS, FONT } from 'UI/globals/colours';

export const StyledSelect = styled(Select)`
  width: 320px;
  border-radius: 4px;
  font-family: ${theme.fontFamily};
  font-size: 14px;
  flex: 1;
  text-transform: capitalize;
  div[class*='singleValue'] {
    cursor: pointer;
    padding-right: 8px;
    overflow: initial;
    &:after {
      content: '';
      border: 1px solid ${FONT.dark};
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: absolute;
      top: 3px;
      left: 288px;
    }
  }
`;

export const styles1 = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    justifyContent: 'left',
    minHeight: 'auto',
    backgroundColor: state.selectProps.theme?.dark ? GREYS.silver : GREYS.white,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: `${GREYS.black}`,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    textAlign: 'center',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '320px',
    backgroundColor: state.selectProps.theme?.dark ? GREYS.silver : GREYS.white,
    color: state.selectProps.theme?.dark ? GREYS.white : GREYS.black,
  }),
  valueContainer: provided => ({
    ...provided,
    justifyContent: 'left',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    justifyContent: 'left',
    color: state.selectProps.theme?.dark ? GREYS.white : GREYS.action,
  }),
};
