import React from 'react';
import PropTypes from 'prop-types';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import {
  Container,
  Heading,
  SiteFeaturesWrapper,
  SectionHeading,
  LinkDivider,
} from 'UI/globals/EmptyBetslip.styles';
import Feature from './core__empty-betslip-feature';
import { defaultFeatures, adaptFeatures } from './core__empty-betslip.adapter';
import project from '../../../../project';

const EmptyBetslip = ({ Translations, features }) => {
  const featuresArray = adaptFeatures({ Translations, features });

  return (
    <Container>
      <Heading>
        {<I iconName={getIcon('NOBET')} size={100} />}
        {Translations.get('text.empty')}
      </Heading>

      {!!featuresArray.length && (
        <React.Fragment>
          <SectionHeading>
            {Translations.get('text.site.features')}
          </SectionHeading>
          <SiteFeaturesWrapper>
            {featuresArray.map((details, index) => (
              <React.Fragment key={index}>
                <Feature {...details} />
                {index !== featuresArray.length - 1 && <LinkDivider />}
              </React.Fragment>
            ))}
          </SiteFeaturesWrapper>
        </React.Fragment>
      )}
    </Container>
  );
};

EmptyBetslip.defaultProps = {
  features: project.emptybetslip?.features || defaultFeatures,
};

EmptyBetslip.propTypes = {
  Translations: PropTypes.object,
  /** comma separated list of keys of feature names */
  features: PropTypes.string,
};

export default EmptyBetslip;
