import styled, { css } from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const BCFR = styled.button`
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0 4px;
  background-color: ${BRAND.secondaryD};
  margin-left: 8px;
  cursor: pointer;
  color: ${GREYS.white};
  border: none;
  border-radius: 4px;
`;
export const BCFWC = styled.div`
  position: 'absolute';
`;
export const BCWCH = styled.div`
  height: 38px;
  position: relative;
  color: ${BRAND.primary};
  font-family: ${theme.fontFamily};
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 28px;
`;

export const BCFSS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CAN = styled.button`
  width: 100%;
  margin-right: 8px;
  cursor: pointer;
  background-color: ${GREYS.white};
  border: 1px solid ${GREYS.doveD};
  height: 40px;
  font-size: 14px;
  font-weight: 700;
`;
export const CONF = styled.button`
  width: 100%;
  cursor: pointer;
  background-color: ${BRAND.secondary};
  border: none;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
`;
export const BCFBTH = styled.div`
  display: flex;
`;
export const MStyles = {
  modal: {
    padding: '24px 24px 24px 24px',
    width: (() => {
      try {
        return screen.width < 900 ? '100%' : '345px';
      } catch (error) {
        return '345px';
      }
    })(),
    borderRadius: '4px',
    margin: 0,
  },
  root: {
    position: 'initial',
  },
};

export const IRDO = styled.input`
  cursor: pointer;
`;
export const FBI = styled.img`
  width: 19px;
  margin-left: 2px;
`;
export const BC = styled.div`
  margin: 0 0 16px 0;
`;
export const BCDS = styled.div`
  ${props =>
    props.walletLength > 10 &&
    css`
      height: 220px;
      overflow-y: scroll;
    `}
`;
export const X = styled.span`
  right: 0;
  color: black;
  font-size: 24px;
  font-weight: 200;
  position: absolute;
  cursor: pointer;
`;

export const BonusText = styled.div`
  font-size: 12px;
  color: ${GREYS.black};
`;

export const JPStyles = {
  modal: {
    position: 'fixed',
    top: '80px',
    left: '10px',
    right: '10px',
    padding: '24px',
    width: '90%',
    margin: '0 auto',
    borderRadius: '4px',
  },
  root: {
    position: 'initial',
  },
};
