import React from 'react';
import { BetslipCurrency } from './core__currency';
import PropTypes from 'prop-types';
import { PPD } from 'UI/apps/BetslipSAApp/BetslipContainer/FreeBetStake';

import { Translations } from '../../../../../core__betslip-SA-app';

export const UserFreeBetStake = ({ currencySymbol, freebetCredit }) => {
  return (
    <PPD>
      <BetslipCurrency symbol={currencySymbol} />
      {freebetCredit} {Translations.get('text.free.bet')}
    </PPD>
  );
};
UserFreeBetStake.propTypes = {
  currencySymbol: PropTypes.string,
  freebetCredit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
