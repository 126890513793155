import React from 'react';
import { BetslipContentBetBox } from './core__betbox';
import { ContentTab } from './core__content';
import { BetBoxLayout } from './core__contentlayout';
import { BetBoxLayoutHorseDog } from './core__contentlayouthorsedog';

const BetslipContentBetBox_Copy = props => {
  return <BetslipContentBetBox {...props} showArrow={false} />;
};

export {
  BetslipContentBetBox_Copy as BetslipContentBetBox,
  BetBoxLayout,
  ContentTab,
  BetBoxLayoutHorseDog,
};
