import styled, { css } from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';

export const BL = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  &:first-child {
    flex-grow: 1;
  }
`;

export const BCSW = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

export const BCS = styled.span`
  font-weight: bold;
  font-size: 14px;
  user-select: none;
`;

export const BSSEL = styled.div`
  width: 8em;
`;
export const OI = styled.img`
  width: 12px;
  margin: 0px 4px;
`;
export const BLS = styled(BL)`
  color: ${GREYS.silver};
`;
export const BH = styled.h3`
  font-weight: bold;
  font-size: 14px;
  user-select: none;
  color: ${FONT.dark};
  ${({ active }) =>
    !active &&
    css`
      color: ${GREYS.silver};
    `};
`;
