import {
  BTSS,
  BTCB,
  BTL,
} from 'CORE__UI/apps/BetslipSAApp/BetslipContainer/core__generateTab';
import styled from 'styled-components';
import { FONT, BK_COLORS } from 'UI/globals/colours';

const BTL_EX = styled(BTL)`
  color: ${FONT.black.standard};
  margin: 12px 16px;
`;

const BTSS_EX = styled(BTSS)`
  border-bottom: 1px solid ${BK_COLORS.aliceBlue10};
  padding: 0px;
`;
export { BTSS_EX as BTSS, BTCB, BTL_EX as BTL };
