import styled from 'styled-components';
import { BK_COLORS } from 'UI/globals/colours';
import {
  Container,
  Heading,
  SiteFeaturesWrapper,
  SectionHeading,
  Link,
  LinkHeader,
  LinkDescription,
  Button,
  LinkDivider,
} from 'CORE__UI/globals/EmptyBetslip/core__empty-betslip.styles';

const SectionHeading_EX = styled(SectionHeading)`
  color: ${BK_COLORS.blueOther};
`;

export {
  Container,
  Heading,
  SiteFeaturesWrapper,
  SectionHeading_EX as SectionHeading,
  Link,
  LinkHeader,
  LinkDescription,
  Button,
  LinkDivider,
};
